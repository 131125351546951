import React, {useState, useEffect} from "react";
import Layout from "../../component/layout";
import {Link, useHistory} from "react-router-dom";
import {getCookie, removeCookie} from "../../action/authAction";
import {API} from "../../config/config";
import axios from "axios";

const Profile = () => {
    const token = getCookie("token");

    const history = useHistory();

    // State for user profile data
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        role: ""
    });

    // State for password reset
    const [passwordData, setPasswordData] = useState({
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    });

    useEffect(() => {
        fetchUserProfile();
    }, []);

    const fetchUserProfile = async () => {
        try {
            const response = await axios.get(`${API}/profile`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setUser(response.data.data);
        } catch (error) {
            console.error("Error fetching user profile:", error);
        }
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setUser({...user, [name]: value});
    };

    const handlePasswordChange = (e) => {
        const {name, value} = e.target;
        setPasswordData({...passwordData, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`${API}/profile/update`, user, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setUser(response.data.data);
            alert("Profile updated successfully");
        } catch (error) {
            console.error("Error updating user profile:", error);
            alert("Failed to update profile");
        }
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        if (passwordData.newPassword !== passwordData.confirmNewPassword) {
            alert("New password and confirmation do not match");
            return;
        }
        try {
            await axios.put(`${API}/profile/change-password`, passwordData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            alert("Password updated successfully. You will be logged out.");

            handleLogout();

        } catch (error) {
            console.error("Error updating password:", error);
            alert("Failed to update password");
        }
    };

    const handleLogout = () => {
        removeCookie('token'); // Remove token or handle other cleanup
        history.push('/'); // Redirect to login or any other page
    };

    return (
        <Layout>
            <main
                className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl bg-white transition-all duration-200">
                <div className="w-full px-6 py-3 mx-auto">
                    <h3 className="text-black font-bold">Profile</h3>
                    <nav
                        className="text-black relative flex flex-wrap items-center justify-between py-2 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
                        navbar-main="" navbar-scroll="false">
                        <div className="flex items-center justify-between w-full py-1 mx-auto flex-wrap-inherit">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div className="">
                                            <Link to="/dashboard"
                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700">
                                                <span className="">Dashboard</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="">
                                            <a className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700">
                                                <span className="">Profile</span>
                                            </a>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </nav>

                    <div className="w-full p-6 mx-auto">
                        <div className="flex flex-wrap -mx-3">
                            <div className="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0">
                                <div
                                    className="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                                    <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                                        <div className="flex items-center">
                                            <p className="mb-0 dark:text-white/80">Edit Profile</p>
                                        </div>
                                    </div>
                                    <div className="flex-auto p-6">
                                        <form onSubmit={handleSubmit}>
                                            <div className="flex flex-wrap -mx-3">
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="firstName"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            First name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="firstName"
                                                            value={user.firstName}
                                                            onChange={handleInputChange}
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="lastName"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            Last name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="lastName"
                                                            value={user.lastName}
                                                            onChange={handleInputChange}
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="email"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            Email address (Login ID)
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            value={user.email}
                                                            onChange={handleInputChange}
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="phoneNumber"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            Phone number
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="phoneNumber"
                                                            value={user.phoneNumber}
                                                            onChange={handleInputChange}
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="role"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            Role
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="role"
                                                            value={user.role}
                                                            onChange={handleInputChange}
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex justify-end">
                                                <button type="submit"
                                                        className="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85">
                                                    Update Profile
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mt-6">
                            <div className="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0">
                                <div
                                    className="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                                    <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                                        <div className="flex items-center">
                                            <p className="mb-0 dark:text-white/80">Reset Current Password</p>
                                        </div>
                                    </div>
                                    <div className="flex-auto p-6">
                                        <form onSubmit={handlePasswordSubmit}>
                                            <div className="flex flex-wrap -mx-3">
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="currentPassword"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            Current Password
                                                        </label>
                                                        <input
                                                            type="password"
                                                            name="currentPassword"
                                                            value={passwordData.currentPassword}
                                                            onChange={handlePasswordChange}
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="newPassword"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            New Password
                                                        </label>
                                                        <input
                                                            type="password"
                                                            name="newPassword"
                                                            value={passwordData.newPassword}
                                                            onChange={handlePasswordChange}
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="confirmNewPassword"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            New Password Confirmation
                                                        </label>
                                                        <input
                                                            type="password"
                                                            name="confirmNewPassword"
                                                            value={passwordData.confirmNewPassword}
                                                            onChange={handlePasswordChange}
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex justify-end">
                                                <button type="submit"
                                                        className="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85">
                                                    Update Current Password
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default Profile;
