import React, {useState, useEffect} from "react";
import {useHistory, Link} from "react-router-dom";

import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
import './assets/css/soft-ui-dashboard-tailwind.css';

import Layout from "../component/layout";
import {getCookie} from "../action/authAction";
import axios from "axios";
import {API} from "../config/config";

import {Bar} from 'react-chartjs-2';

const RevenuePage = () => {
    const token = getCookie('token');

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

    // State for storing revenue data
    const [totalRevenueThisYear, setTotalRevenueThisYear] = useState(0);
    const [totalRevenueLastYear, setTotalRevenueLastYear] = useState(0);
    const [percentageDifference, setPercentageDifference] = useState(0);

    const [hatchback, setHatchbackTotal] = useState(0);
    const [sedan, setSedanTotal] = useState(0);
    const [suv, setSuvTotal] = useState(0);
    const [muv, setMuvTotal] = useState(0);
    const [coupe, setCoupeTotal] = useState(0);
    const [convertable, setConvertableTotal] = useState(0);
    const [pickup, setPickupTotal] = useState(0);
    const [sports, setSportsTotal] = useState(0);
    const [motorhome, setMotorhomeTotal] = useState(0);
    const [roadster, setRoadsterTotal] = useState(0);
    const [wagon, setWagonTotal] = useState(0);
    const [mpv, setMpvTotal] = useState(0);

    useEffect(() => {
        const fetchChartData = async () => {
            try {
                const response = await axios.get(`${API}/sales/chart/revenue`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
    
                // This line will be logged in white color
                const data = response.data.data;
                const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
                // Initialize sums for each type
                let typeSums = {
                    Hatchback: 0,
                    Sedan: 0,
                    SUV: 0,
                    MUV: 0,
                    Coupe: 0,
                    Convertable: 0,
                    Pickup: 0,
                    Sports: 0,
                    Motorhome: 0,
                    Roadster: 0,
                    Wagon: 0,
                    MPV: 0,
                };
    
                // Aggregate this year's sales by type
                data.thisYear.forEach(item => {
                    // Assuming item._id.type contains the type name (e.g., "SUV", "Sedan")
                    if (typeSums.hasOwnProperty(item._id.type)) {
                        typeSums[item._id.type] += item.count;
                    }
                });
    
                // Update state for each vehicle type total
                setHatchbackTotal(typeSums.Hatchback);
                setSedanTotal(typeSums.Sedan);
                setSuvTotal(typeSums.SUV);
                setMuvTotal(typeSums.MUV);
                setCoupeTotal(typeSums.Coupe);
                setConvertableTotal(typeSums.Convertable);
                setPickupTotal(typeSums.Pickup);
                setSportsTotal(typeSums.Sports);
                setMotorhomeTotal(typeSums.Motorhome);
                setRoadsterTotal(typeSums.Roadster);
                setWagonTotal(typeSums.Wagon);
                setMpvTotal(typeSums.MPV);
    
    
                // Preparing the datasets
                let thisYearSales = Array(12).fill(0);
                data.thisYear.forEach(item => {
                    thisYearSales[item._id.month - 1] = item.totalValue;
                    console.log(thisYearSales);
                });
    
                let lastYearSales = Array(12).fill(0);
                data.lastYear.forEach(item => {
                    lastYearSales[item._id.month - 1] = item.totalValue;
                });
    
                setChartData({
                    labels: months,
                    datasets: [
                        {
                            label: 'Sales This Year',
                            data: thisYearSales,
                            borderColor: 'rgb(54, 162, 235)',
                            backgroundColor: 'rgba(54, 162, 235, 0.5)',
                        },
                        {
                            label: 'Sales Last Year',
                            data: lastYearSales,
                            borderColor: 'rgb(255, 99, 132)',
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                    ],
                });
            } catch (error) {
                console.error("Failed to fetch chart data", error);
            }
        };
    
        fetchChartData();
    }, [token]);
    

    useEffect(() => {
        // This useEffect depends directly on `chartData`, so it will run after `chartData` is updated.
        const totalRevenueThisYear = chartData.datasets?.find(dataset => dataset.label === 'Sales This Year')?.data.reduce((acc, val) => acc + val, 0) || 0;
        const totalRevenueLastYear = chartData.datasets?.find(dataset => dataset.label === 'Sales Last Year')?.data.reduce((acc, val) => acc + val, 0) || 0;
        const percentageDifference = totalRevenueLastYear > 0 ? ((totalRevenueThisYear - totalRevenueLastYear) / totalRevenueLastYear) * 100 : 100;

        setTotalRevenueThisYear(totalRevenueThisYear);
        setTotalRevenueLastYear(totalRevenueLastYear);
        setPercentageDifference(percentageDifference);
    }, [chartData]); // Dependency array ensures this runs only when chartData updates.


    return (
        <Layout>
            <main
                className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200">
                {/* Navbar */}
                <nav
                    className="relative flex flex-wrap items-center justify-between px-0 py-2 mx-6 transition-all shadow-none duration-250 ease-soft-in rounded-2xl lg:flex-nowrap lg:justify-start"
                    navbar-main=""
                    navbar-scroll="true"
                >
                    <div className="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
                        <nav>
                            {/* breadcrumb */}
                            <ol className="flex flex-wrap pt-1 mt-6 mr-12 bg-transparent rounded-lg sm:mr-16">
                                <li className="leading-normal text-sm">
                                    <div className="">
                                        <Link to="/dashboard"

                                              className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                        >
                                            <span className="">Dashboard</span>
                                        </Link>
                                    </div>
                                </li>
                                <li
                                    className="text-sm pl-2 capitalize leading-normal text-slate-700 before:float-left before:pr-2 before:text-gray-600 before:content-['/']"
                                    aria-current="page"
                                >
                                    Revenue to date
                                </li>
                            </ol>
                            <h6 className="mb-0 font-bold capitalize pt-4">Revenue to date</h6>
                        </nav>
                        <div
                            className="flex items-center mt-2 grow sm:mt-0 sm:mr-6 md:mr-0 lg:flex lg:basis-auto"></div>
                    </div>
                </nav>
                {/* end Navbar */}
                {/* cards */}
                <div className="w-full px-6 py-0 mx-auto">
                    {/* row 1 */}
                    <div className="flex flex-wrap -mx-3">
                        {/* cards row 3 */}
                        <div className="flex flex-wrap mt-3 -mx-7">
                            <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-full lg:flex-none">
                                <div
                                    className="border-black/12.5 shadow-soft-lg relative z-20 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                                    <div className="flex-auto p-4">
                                        <div
                                            className="py-4 pr-1 mb-4 bg-gradient-to-tl from-gray-900 to-slate-800 rounded-xl">
                                            <Bar data={chartData}/>
                                        </div>
                                        <h6 className="mt-6 text-xl mb-0 ml-2">
                                            RM
                                            <span
                                                className="leading-normal text-xl ml-1 font-weight-bolder text-lime-500">
                  {totalRevenueThisYear.toLocaleString()}
                </span>
                                        </h6>
                                        <p className="ml-2 leading-normal text-sm">
                                            (<span className="font-bold">{percentageDifference.toFixed(2)}%</span>) than
                                            last year
                                        </p>
                                        <div className="w-full px-6 mx-auto max-w-screen-2xl rounded-xl">
                                            <div className="flex flex-wrap mt-0 -mx-3">
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-16 h-16 mr-2 text-center bg-center rounded fill-current shadow-soft-2xl bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://imgd.aeplcdn.com/600x337/n/cw/ec/148919/amg-sl55-roadster-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80"
                                                                className="h-auto max-w-full "
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-3 mb-0 font-semibold leading-tight text-sm">
                                                            Convertable
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{convertable}</h4>
                                                    {/*<div
                className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={30}
                    aria-valuemin={0}
                    aria-valuemax={100}
                />
            </div>*/}
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-16 h-16 mr-2 text-center bg-center rounded fill-current shadow-soft-2xl bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=242B&client=byo&paint=P0300&fabric=FKHSW&sa=S01T3,S0255,S02TB,S0302,S0430,S0431,S0459,S0493,S04P6,S0508,S0534,S0544,S05AS,S05DC,S0676,S06AC,S06AK,S06C4,S06U2,S06WC,S0760,S0775&quality=70&bkgnd=transparent&w=9800&h=8000&x=180&y=800&resp=png&angle=60"
                                                                className="h-auto max-w-full "
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-3 mb-0 font-semibold leading-tight text-sm">
                                                            Coupe
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{coupe}</h4>
                                                    {/*<div
                className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={30}
                    aria-valuemin={0}
                    aria-valuemax={100}
                />
            </div>*/}
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-16 h-16 mr-2 text-center bg-center rounded-md fill-current shadow-soft-2xl bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://honda-kl.com/images/car-models/2022/city-hatchback/colors/city-hatchback-ignite-red-metallic.png"
                                                                className="h-auto max-w-full "
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-3 mb-0 font-semibold leading-tight text-sm">
                                                            Hatchback
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{hatchback}</h4>
                                                    {/*<div
                className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                <div
                    className="duration-600 ease-soft -mt-0.38 -ml-px flex h-1.5 w-3/5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                />
            </div>*/}
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-16 h-16 mr-2 text-center bg-center rounded fill-current shadow-soft-2xl bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://lunamotorhomehire.co.uk/wp-content/uploads/2023/05/Chausson-Flash-Motorhome-Hire-Luna-Motorhome-Hire-1.png.webp"
                                                                className="h-auto max-w-full "
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-3 mb-0 font-semibold leading-tight text-sm">
                                                            Motorhome
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{motorhome}</h4>
                                                    {/*<div
                className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={30}
                    aria-valuemin={0}
                    aria-valuemax={100}
                />
            </div>*/}
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-16 h-16 mr-2 text-center bg-center rounded fill-current shadow-soft-2xl bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://qph.cf2.quoracdn.net/main-qimg-a1f91ec195910927c5901c5320732170-lq"
                                                                className="h-auto max-w-full "
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-3 mb-0 font-semibold leading-tight text-sm">
                                                            MUV
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{muv}</h4>
                                                    {/*<div
                className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={30}
                    aria-valuemin={0}
                    aria-valuemax={100}
                />
            </div>*/}
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-16 h-16 mr-2 text-center bg-center rounded fill-current shadow-soft-2xl bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://carnetwork.s3.ap-southeast-1.amazonaws.com/file/c26f8b81b77c450d8865db3e6b926b30.jpg"
                                                                className="h-auto max-w-full "
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-3 mb-0 font-semibold leading-tight text-sm">
                                                            MPV
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{mpv}</h4>
                                                    {/*<div
                className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                />
            </div>*/}
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-16 h-16 mr-2 text-center bg-center rounded fill-current shadow-soft-2xl bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://carnetwork.s3.ap-southeast-1.amazonaws.com/file/9a1cb50ac9ca422bb3b44e2511d2deb4.jpg"
                                                                className="h-auto max-w-full "
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-3 mb-0 font-semibold leading-tight text-sm">
                                                            Pickup truck
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{pickup}</h4>
                                                    {/*<div
                className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={30}
                    aria-valuemin={0}
                    aria-valuemax={100}
                />
            </div>*/}
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-16 h-16 mr-2 text-center bg-center rounded fill-current shadow-soft-2xl bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://static-assets.tesla.com/configurator/compositor?context=design_studio_2?&bkba_opt=1&view=STUD_3QTR&size=1400&model=m3&options=$APBS,$IPB2,$PPSW,$SC04,$MDL3,$W38A,$MT351,$CPF0,$DRRH&crop=1400,850,300,130&"
                                                                className="h-auto max-w-full "
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-3 mb-0 font-semibold leading-tight text-sm">
                                                            Roadster
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{roadster}</h4>
                                                    {/*<div
                className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={30}
                    aria-valuemin={0}
                    aria-valuemax={100}
                />
            </div>*/}
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-16 h-16 mr-2 text-center bg-center rounded fill-current shadow-soft-2xl bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://automobiles.honda.com/-/media/Honda-Automobiles/Vehicles/All-Vehicles/VehicleCards/accord-hybrid.png"
                                                                className="h-auto max-w-full "
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-3 mb-0 font-semibold leading-tight text-sm">
                                                            Sedan
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{sedan}</h4>
                                                    {/*<div
                className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                <div
                    className="duration-600 ease-soft -mt-0.38 w-9/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={90}
                    aria-valuemin={0}
                    aria-valuemax={100}
                />
            </div>*/}
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-16 h-16 mr-2 text-center bg-center rounded fill-current shadow-soft-2xl bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://img.kyodonews.net/english/public/images/posts/5079dd756314ff0e58c85e2801624762/photo_l.jpg"
                                                                className="h-auto max-w-full "
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-3 mb-0 font-semibold leading-tight text-sm">
                                                            Sports car
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{sports}</h4>
                                                    {/*<div
                className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                />
            </div>*/}
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-16 h-16 mr-2 text-center bg-center rounded fill-current shadow-soft-2xl bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://www.honda.co.uk/content/dam/central/cars/range/hybrid/hydrid-updates-Aug22/24YM_CRV_ADVANCE_EU_f34_stu_B_640M_001.png/_jcr_content/renditions/c3.png"
                                                                className="h-auto max-w-full "
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-3 mb-0 font-semibold leading-tight text-sm">
                                                            SUV
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{suv}</h4>
                                                    {/*<div
                className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={90}
                    aria-valuemin={0}
                    aria-valuemax={100}
                />
            </div>*/}
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-16 h-16 mr-2 text-center bg-center rounded fill-current shadow-soft-2xl bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://imgd.aeplcdn.com/664x374/n/cw/ec/112947/wagon-r-2022-exterior-right-front-three-quarter-3.jpeg?isig=0&q=80"
                                                                className="h-auto max-w-full "
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-3 mb-0 font-semibold leading-tight text-sm">
                                                            Wagon
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{wagon}</h4>
                                                    {/*<div
                className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                />
            </div>*/}
                                                </div>
                                              
                                                <div className="flex-none w-full max-w-full py-4 pl-0 pr-3 mt-0"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* cards row 4 */}
                    </div>
                </div>
            </main>

        </Layout>
    );
}

export default RevenuePage;
