import React, {useState, useEffect} from "react";
import Layout from "../../component/layout";
import {Link, useParams} from "react-router-dom";
import {getCookie} from "../../action/authAction";
import {API} from "../../config/config";
import axios from "axios";

const LeadsListPage = () => {
    const token = getCookie('token');

    const [leads, setLeads] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        fetchLeads();
    }, [token, page, searchQuery]);

    const fetchLeads = async () => {
        try {
            const response = await axios.get(`${API}/leads/all`, {
                params: {
                    page,
                    limit: 10,
                    search: searchQuery
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setLeads(response.data.data.leads);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error("Error fetching leads:", error);
        }
    };

    const deleteLead = async (leadId) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this lead?');
        if (isConfirmed) {
            try {
                await axios.patch(`${API}/leads/delete/${leadId}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                fetchLeads();
            } catch (error) {
                console.error("Error deleting lead:", error);
            }
        }
    };

    const renderLeads = () => {
        const activeLeads = leads.filter(lead => !lead.isDeleted);

        if (!activeLeads || activeLeads.length === 0) {
            return (
                <tr>
                    <td colSpan="6">No data found</td>
                </tr>
            );
        } else {
            return activeLeads.map((lead, index) => (
                <tr key={index}>
                    <td className="p-2 py-4 align-middle bg-transparent border-b dark:border-white/40 shadow-transparent">
                        <p className="mb-0 text-sm font-semibold pl-4">{index + 1}</p>
                    </td>
                    <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                            <span className="text-xs font-semibold leading-tight text-slate-400">
                            <h6 className="mb-0 text-sm leading-normal">{lead.firstName} {lead.lastName}</h6>  <p
                                className="mb-0 text-xs leading-tight text-slate-400">{lead.phoneNumber}</p></span>
                    </td>
                    <td className="pl-14 py-4 align-middle bg-transparent border-b dark:border-white/40 shadow-transparent">
                        <p className="mb-0 text-xs pl-6 font-semibold leading-tight">
                            {new Date(lead.createdAt).toLocaleDateString()}
                        </p>
                    </td>
                    <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                        {lead.salesperson && lead.salesperson.length > 0 ? (lead.salesperson.map((salePerson, index) => (
                            <div key={index}>
                                <Link to={`/salesperson-detail/${salePerson._id}`}>
                                    <h6
                                        className="mb-0 text-sm leading-normal">{salePerson.firstName} {salePerson.lastName}</h6>
                                </Link>
                            </div>))) : (<span className="text-xs font-semibold leading-tight text-slate-400">-</span>)}
                    </td>
                    <td className="p-2 py-4 align-middle bg-transparent text-black border-b dark:border-white/40 shadow-transparent">
                        <p>
                            <a className="mb-3 pl-9 text-xs font-semibold leading-tight">
                                <Link to={`/lead/form/${lead._id}`}>
                                    <button>
                                        <svg className="h-6 w-6 text-orange-500" fill="none" viewBox="0 0 24 24"
                                             stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                                        </svg>
                                        Edit
                                    </button>
                                </Link>
                            </a>
                        </p>
                        <p>
                            <a className="mb-0 pl-9 text-xs font-semibold leading-tight">
                                <button onClick={() => deleteLead(lead._id)}>
                                    <svg className="h-6 w-6 text-red-500" width="24" height="24" viewBox="0 0 24 24"
                                         strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                                         strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z"/>
                                        <line x1="4" y1="7" x2="20" y2="7"/>
                                        <line x1="10" y1="11" x2="10" y2="17"/>
                                        <line x1="14" y1="11" x2="14" y2="17"/>
                                        <path d="M5 7l1 12a2 2 0 002 2h8a2 2 0 002-2l1-12"/>
                                        <path d="M9 7v-3a1 1 0 011-1h4a1 1 0 011 1v3"/>
                                    </svg>
                                    Delete
                                </button>
                            </a>
                        </p>
                    </td>
                </tr>
            ));
        }
    };

    const renderPagination = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <a
                    key={i}
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setPage(i);
                    }}
                    className={`mx-1 px-4 py-2 border rounded-md ${page === i ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
                >
                    {i}
                </a>
            );
        }
        return pages;
    };

    return (
        <Layout>
            <main
                className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl bg-white transition-all duration-200">
                {/* cards */}
                <div className="w-full px-6 py-6 mx-auto">
                    <h3 className="text-black font-bold">Leads</h3>
                    {/* Navbar */}
                    <nav
                        className="text-black relative flex flex-wrap items-center justify-between py-2 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
                        navbar-main=""
                        navbar-scroll="false"
                    >
                        <div className="flex items-center justify-between w-full py-1 mx-auto flex-wrap-inherit">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div className="">
                                            <a
                                                className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Leads</span>
                                            </a>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </nav>
                    {/* end Navbar */}
                    {/* row 1 */}
                    <div className="flex py-4">
                        <Link to="/lead/form">
                            <button
                                type="button"
                                className="mr-4 inline-flex items-center rounded-lg border border-transparent bg-green-500 px-3 py-1 text-xs font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2.5"
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 6v12m6-6H6"
                                    />
                                </svg>
                                <div className="">
                                    <a
                                        className="ml-4 text-sm font-bold text-white hover:text-white"
                                    >
                                        <span className="">Add Lead</span>
                                    </a>
                                </div>
                            </button>
                        </Link>
                    </div>
                    {/* table 1 */}
                    <div className="flex flex-wrap -mx-3">
                        <div className="flex-none w-full max-w-full px-3">
                            <div
                                className="relative flex flex-col min-w-0 mb-6 break-words border border-1 border-gray-50 bg-white shadow-xl rounded-2xl bg-clip-border">
                                <div
                                    className="p-6 pb-4 mb-0 border-black/12.5 rounded-t-2xl border-b-transparent">
                                    <div className="flex items-center justify-between">
                                        <p className="font-semibold text-black pt-2">List of Leads</p>
                                        <div className="flex items-center justify-end">
                                            <div
                                                className="relative flex flex-wrap items-stretch w-2/4 transition-all rounded-lg ease mr-4">
                <span
                    className="text-sm ease leading-5.6 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none border border-r-0 border-transparent bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                  <i className="fas fa-search"/>
                </span>
                                                <input
                                                    type="text"
                                                    className="pl-10 text-xs focus:shadow-primary-outline ease w-1/100 leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 dark:bg-slate-850 dark:text-white bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none focus:transition-shadow"
                                                    placeholder="Search By Name"
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-auto px-0 pt-0 pb-2">
                                    <div className="p-0 overflow-x-auto">
                                        <table
                                            className="items-center w-full mb-0 align-top border-collapse dark:border-white/40 text-slate-500 border divide-y divide-gray-300">
                                            <thead>
                                            <tr className="border">
                                                <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                    No
                                                </th>
                                                <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                    Name
                                                </th>
                                                <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                    Date Added
                                                </th>
                                                <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                    SalesPerson
                                                </th>
                                                <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                    Action
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {renderLeads()}
                                            </tbody>
                                        </table>
                                        <div
                                            className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                                            <div>
                                                <p className="text-sm text-gray-700">
                                                    Page
                                                    <span className="font-medium px-2">{page}</span>
                                                    Of
                                                    <span className="font-medium px-2">{totalPages}</span>
                                                </p>
                                            </div>
                                            <div>
                                                {renderPagination()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end cards */}
            </main>
        </Layout>
    );
}

export default LeadsListPage;
