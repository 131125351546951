import React, {useState, useEffect} from "react";
import Layout from "../../component/layout";
import {Link, useParams} from "react-router-dom";
import {getCookie} from "../../action/authAction";
import {API} from "../../config/config";
import axios from "axios";

const CarGallery = () => {
    let {id} = useParams();
    console.log(id);
    const token = getCookie('token');

    // Initialize state for gallery images
    const [gallery, setGallery] = useState({
        overall: '',
        leftSide: '',
        rightSide: '',
        front: '',
        back: '',
        top: '',
        dashboard: '',
        overview: '',
        frontSeat: '',
        backSeat: '',
        engine: '',
        boot: ''
    });

    const [galleryId, setGalleryId] = useState(null); // Store the gallery ID

    // Fetch stock details (including gallery data) from the API
    const fetchStockDetails = async () => {
        try {
            const response = await axios.get(`${API}/stock/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            // Check if the stock data includes gallery information and update state
            const stockData = response.data.data;
            if (stockData && stockData.gallery) {
                // Assuming the gallery data is directly under the gallery key
                setGallery(stockData.gallery);
                setGalleryId(stockData.gallery._id); // Assume the gallery object has an _id field
                console.log(stockData.gallery);
            } else {
                // Handle cases where gallery data might not be available
                console.log("Gallery data not available for this stock.");
            }
        } catch (error) {
            console.error("Error fetching stock details:", error);
            // Optionally, handle error or set default gallery state
        }
    };

    const handleFileUpload = async (event, imageType) => {
        const file = event.target.files[0];
        if (!file) {
            console.log('No file selected for upload');
            return;
        }

        const formData = new FormData();
        formData.append('image', file);
        formData.append('fieldName', imageType); // Pass the image type to the server

        console.log(imageType);

        try {
            const response = await axios.patch(`${API}/gallery/upload/${galleryId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            });

            fetchStockDetails();

            console.log(`${imageType} image updated successfully.`);
        } catch (error) {
            console.error(`Error updating ${imageType} image:`, error);
        }
    };

    useEffect(() => {
        fetchStockDetails();
    }, [id, token]); // Depend on ID and token so it re-runs when they change

    const renderImageOrPlaceholder = (imageUrl, imageType) => {
        return (
            <div className="image-container">
                {imageUrl ? (
                    <>
                        <img src={imageUrl} alt={imageType} className="max-w-full shadow-soft-2xl rounded-2xl"/>
                        <button onClick={() => document.getElementById(`file-upload-${imageType}`).click()}>Replace
                            Image
                        </button>
                    </>
                ) : (
                    <>
                        <div className="empty-container" style={{
                            width: '100%',
                            height: '200px',
                            backgroundColor: '#f0f0f0',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <p>No Image Available</p>
                        </div>
                        <button onClick={() => document.getElementById(`file-upload-${imageType}`).click()}>Upload
                            Image
                        </button>
                    </>
                )}
                <input type="file" id={`file-upload-${imageType}`} style={{display: 'none'}}
                       onChange={(e) => handleFileUpload(e, imageType)} accept="image/png, image/jpeg"/>
            </div>
        );
    };

    return (
        <div className="flex flex-wrap -mx-3">
            <div
                className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                <div
                    className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                    <div className="flex-auto px-1 pt-6">
                        <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                            Overall shoot
                        </p>
                    </div>
                    <div className="relative">
                        <a className="block shadow-xl rounded-2xl">
                            {renderImageOrPlaceholder(gallery.overall, 'overall')}
                        </a>
                    </div>

                </div>
            </div>
            <div
                className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                <div
                    className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                    <div className="flex-auto px-1 pt-6">
                        <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                            Left side
                        </p>
                    </div>
                    <div className="relative">
                        <a className="block shadow-xl rounded-2xl">
                            {renderImageOrPlaceholder(gallery.leftSide, 'leftSide')}
                        </a>
                    </div>

                </div>
            </div>
            <div
                className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                <div
                    className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                    <div className="flex-auto px-1 pt-6">
                        <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                            Right side
                        </p>
                    </div>
                    <div className="relative">
                        <a className="block shadow-xl rounded-2xl">
                            {renderImageOrPlaceholder(gallery.rightSide, 'rightSide')}
                        </a>
                    </div>
                </div>
            </div>
            <div
                className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                <div
                    className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                    <div className="flex-auto px-1 pt-6">
                        <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                            Front
                        </p>
                    </div>
                    <div className="relative">
                        <a className="block shadow-xl rounded-2xl">
                            {renderImageOrPlaceholder(gallery.front, 'front')}
                        </a>
                    </div>
                </div>
            </div>
            <div
                className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                <div
                    className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                    <div className="flex-auto px-1 pt-6">
                        <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                            Back
                        </p>
                    </div>
                    <div className="relative">
                        <a className="block shadow-xl rounded-2xl">
                            {renderImageOrPlaceholder(gallery.back, 'back')}
                        </a>
                    </div>
                </div>
            </div>
            <div
                className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                <div
                    className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                    <div className="flex-auto px-1 pt-6">
                        <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                            Top-view
                        </p>
                    </div>
                    <div className="relative">
                        <a className="block shadow-xl rounded-2xl">
                            {renderImageOrPlaceholder(gallery.top, 'top')}
                        </a>
                    </div>
                </div>
            </div>
            <div
                className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                <div
                    className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                    <div className="flex-auto px-1 pt-6">
                        <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                            Dashboard
                        </p>
                    </div>
                    <div className="relative">
                        <a className="block shadow-xl rounded-2xl">
                            {renderImageOrPlaceholder(gallery.dashboard, 'dashboard')}
                        </a>
                    </div>
                </div>
            </div>
            <div
                className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                <div
                    className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                    <div className="flex-auto px-1 pt-6">
                        <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                            Overview
                        </p>
                    </div>
                    <div className="relative">
                        <a className="block shadow-xl rounded-2xl">
                            {renderImageOrPlaceholder(gallery.overview, 'overview')}
                        </a>
                    </div>
                </div>
            </div>
            <div
                className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                <div
                    className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                    <div className="flex-auto px-1 pt-6">
                        <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                            Frontseat
                        </p>
                    </div>
                    <div className="relative">
                        <a className="block shadow-xl rounded-2xl">
                            {renderImageOrPlaceholder(gallery.frontSeat, 'frontSeat')}
                        </a>
                    </div>
                </div>
            </div>
            <div
                className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                <div
                    className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                    <div className="flex-auto px-1 pt-6">
                        <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                            Backseat
                        </p>
                    </div>
                    <div className="relative">
                        <a className="block shadow-xl rounded-2xl">
                            {renderImageOrPlaceholder(gallery.backSeat, 'backSeat')}
                        </a>
                    </div>
                </div>
            </div>
            <div
                className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                <div
                    className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                    <div className="flex-auto px-1 pt-6">
                        <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                            Engine
                        </p>
                    </div>
                    <div className="relative">
                        <a className="block shadow-xl rounded-2xl">
                            {renderImageOrPlaceholder(gallery.engine, 'engine')}
                        </a>
                    </div>
                </div>
            </div>
            <div
                className="w-full max-w-full px-3 mb-6 md:w-6/12 md:flex-none xl:mb-0 xl:w-3/12">
                <div
                    className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                    <div className="flex-auto px-1 pt-6">
                        <p className="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-gray-900 to-slate-800 text-sm bg-clip-text">
                            Boot
                        </p>
                    </div>
                    <div className="relative">
                        <a className="block shadow-xl rounded-2xl">
                            {renderImageOrPlaceholder(gallery.boot, 'boot')}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CarGallery;
