import React, {useState, useEffect} from "react";
import Layout from "../../component/layout";
import {Link, useHistory, useParams} from "react-router-dom";
import {getCookie} from "../../action/authAction";
import {API} from "../../config/config";
import axios from "axios";

const ClientFormPage = () => {
    const token = getCookie('token');
    const history = useHistory();
    const {id} = useParams();

    const [clientData, setClientData] = useState({
        firstName: "",
        lastName: "",
        nric: "",
        email: "",
        phoneNumber: "+60",
        profileImage: "",
        gender: "",
        dateOfBirth: "",
        anniversaryDate: "",
        address: {
            street: "",
            city: "",
            state: "",
            zipCode: "",
            country: "Malaysia"
        },
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        if (name.includes("address.")) {
            const addressKey = name.split(".")[1];
            setClientData({
                ...clientData,
                address: {...clientData.address, [addressKey]: value}
            });
        } else {
            setClientData({...clientData, [name]: value});
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (id) {
            } else {
                await axios.post(`${API}/client/new`, clientData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                alert("Client added successfully");
            }
            history.replace("/client-list");
        } catch (error) {
            console.error("Error adding client:", error);
            alert("Failed to add client");
        }
    };

    return (
        <Layout>
            <main
                className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl bg-white transition-all duration-200">
                {/* cards */}
                <div className="w-full px-6 py-6 mx-auto">
                    <h3 className="text-black font-bold">Client Form</h3>
                    {/* Navbar */}
                    <nav
                        className="text-black relative flex flex-wrap items-center justify-between py-2 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
                        navbar-main=""
                        navbar-scroll="false"
                    >
                        <div className="flex items-center justify-between w-full py-1 mx-auto flex-wrap-inherit">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div className="">
                                            <Link to="/client-list"

                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Client</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center px-4">
                                            <svg
                                                className="h-5 w-5 flex-shrink-0 text-gray-400 pt-1"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            <Link to="/client-form"

                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Client Form</span>
                                            </Link>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </nav>
                    {/* Navbar */}
                    {/* end Navbar */}
                    <div className="flex flex-wrap -mx-3">
                        <div className="flex-none w-full max-w-full px-3">
                            <div className="w-full max-w-full px-3 shrink-0 md:flex-0">
                                <div
                                    className="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                                    <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                                        <div
                                            className="w-full max-w-full px-3 mx-auto mt-4 sm:my-auto md:flex-none lg:w-7/12">
                                            <div className="flex justify-center items-center">
                                                <ul
                                                    className="flex flex-wrap p-1 list-none bg-gray-50 rounded-xl"
                                                    nav-pills=""
                                                    role="tablist"
                                                >
                                                    <li className="z-30 flex-auto text-center">
                                                        <a
                                                            className="z-30 flex items-center justify-center w-full px-0 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg bg-inherit text-slate-700"
                                                            nav-link=""
                                                            active=""
                                                            role="tab"
                                                            aria-selected="true"
                                                        >
                                                            <span className="font-semibold ml-2">
                                                                {(id) ? 'Edit Client' : 'Add Client'}
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <form onSubmit={handleSubmit}>
                                        <div className="flex-auto p-6">
                                            <div className="flex flex-wrap -mx-3">
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-1/2 md:flex-0">

                                                    {/* Personal Details */}
                                                    <div className="mb-4">
                                                        <label htmlFor="firstName"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">First
                                                            Name*</label>
                                                        <input type="text" id="firstName" name="firstName"
                                                               value={clientData.firstName}
                                                               onChange={handleChange} required
                                                               className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-1/2 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="lastName"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">Last
                                                            Name*</label>
                                                        <input type="text" id="lastName" name="lastName"
                                                               value={clientData.lastName}
                                                               onChange={handleChange} required
                                                               className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0"></div>

                                            <div className="flex flex-wrap -mx-3">
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="nric"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">IC/Passport*</label>
                                                        <input type="text" id="nric" name="nric" maxLength="12"
                                                               value={clientData.nric}
                                                               onChange={handleChange} required
                                                               className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="phoneNumber"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">Phone
                                                            Number*</label>
                                                        <input type="tel" id="phoneNumber" name="phoneNumber"
                                                               maxLength="14"
                                                               value={clientData.phoneNumber}
                                                               onChange={handleChange} required
                                                               className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="email"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">Email</label>
                                                        <input type="email" id="email" name="email"
                                                               value={clientData.email}
                                                               onChange={handleChange}
                                                               className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0"></div>

                                            <div className="flex flex-wrap -mx-3">
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <label htmlFor="gender"
                                                           className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">Gender*</label>
                                                    <select
                                                        className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                        name="gender"
                                                        value={clientData.gender}
                                                        onChange={handleChange} required
                                                    >
                                                        <option value="">Select Gender</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="dateOfBirth"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            Date Of Birth*
                                                        </label>
                                                        <input
                                                            type="date"
                                                            name="dateOfBirth"
                                                            value={clientData.dateOfBirth}
                                                            onChange={handleChange} required
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 mr-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="anniversaryDate"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            Anniversary Date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            name="anniversaryDate"
                                                            value={clientData.anniversaryDate}
                                                            onChange={handleChange}
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 mr-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0"></div>

                                            <div className="flex flex-wrap -mx-3">
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <label htmlFor="address.street"
                                                           className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">Street*</label>
                                                    <input type="text" id="address.street" name="address.street"
                                                           value={clientData.address.street}
                                                           onChange={handleChange} required
                                                           className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <label htmlFor="address.city"
                                                           className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">City*</label>
                                                    <input type="text" id="address.city" name="address.city"
                                                           value={clientData.address.city} onChange={handleChange}
                                                           required
                                                           className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <label htmlFor="address.state"
                                                           className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">State*</label>
                                                    <select
                                                        className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                        name="address.state"
                                                        value={clientData.address.state}
                                                        onChange={handleChange} required
                                                    >
                                                        <option value=""></option>
                                                        <option value="Johor">Johor</option>
                                                        <option value="Kedah">Kedah</option>
                                                        <option value="Kelantan">Kelantan</option>
                                                        <option value="Melaka">Melaka</option>
                                                        <option value="Negeri Sembilan">Negeri Sembilan</option>
                                                        <option value="Pahang">Pahang</option>
                                                        <option value="Penang">Penang</option>
                                                        <option value="Perak">Perak</option>
                                                        <option value="Perlis">Perlis</option>
                                                        <option value="Sabah">Sabah</option>
                                                        <option value="Sarawak">Sarawak</option>
                                                        <option value="Selangor">Selangor</option>
                                                        <option value="Terengganu">Terengganu</option>
                                                        <option value="Kuala Lumpur">Kuala Lumpur</option>
                                                        <option value="Labuan">Labuan</option>
                                                        <option value="Putrajaya">Putrajaya</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0"></div>

                                            <div className="flex flex-wrap -mx-3">
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <label htmlFor="address.zipCode"
                                                           className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">Zip
                                                        Code*</label>
                                                    <input type="text" id="address.zipCode" name="address.zipCode"
                                                           value={clientData.address.zipCode}
                                                           onChange={handleChange} required
                                                           className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <label htmlFor="address.country"
                                                           className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">Country</label>
                                                    <input type="text" id="address.country" name="address.country"
                                                           value={clientData.address.country}
                                                           onChange={handleChange} required readOnly
                                                           className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
                                                </div>
                                            </div>

                                            <div className="flex flex-row items-end justify-end w-full">
                                                <button
                                                    type="submit"
                                                    className="px-8 mt-4 py-2 mb-4 mr-4 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-green-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                >
                                                    <i className="fa fa-check" aria-hidden="true"/> Save
                                                </button>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );

}

export default ClientFormPage;
