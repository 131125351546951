import React, {useState, useEffect} from "react";
import Layout from "../../component/layout";
import {Link, useHistory, useParams} from "react-router-dom";
import {getCookie} from "../../action/authAction";
import {API} from "../../config/config";
import axios from "axios";

const IntroducerFormPage = () => {
    const token = getCookie('token');
    const history = useHistory();
    const {id} = useParams();

    const [introducerData, setIntroducerData] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "+60",
        clients: [], // Now an array to store multiple clients
    });

    const [clientSuggestions, setClientSuggestions] = useState([]);
    const [searchText, setSearchText] = useState(""); // To keep track of the search input

    const handleChange = (e) => {
        const {name, value} = e.target;

        if (name === "clientSearch") {
            setSearchText(value); // Update the search text state
            searchClients(value);
        } else {
            setIntroducerData({...introducerData, [name]: value});
        }
    };

    const handleSelectClient = (client) => {
        // Check if the client is already selected
        const isClientSelected = introducerData.clients.some(selectedClient => selectedClient.nric === client.nric);

        if (!isClientSelected) {
            setIntroducerData({
                ...introducerData,
                clients: [...introducerData.clients, client] // Store an object with client id only
            });
        }

        setSearchText(""); // Clear search text
        setClientSuggestions([]); // Clear suggestions
    };

    // Function to remove a client from the selected list
    const handleRemoveClient = (nricToRemove) => {
        setIntroducerData({
            ...introducerData,
            clients: introducerData.clients.filter(client => client.nric !== nricToRemove),
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (introducerData.clients.length !== 0) {
                if (id) {
                    // If editing an existing introducer
                } else {
                    await axios.post(`${API}/introducer/new`, introducerData, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    alert("Introducer added successfully");
                }
                history.replace("/introducer-list");
            } else {
                alert("Please select client");
            }
        } catch (error) {
            console.error("Error adding introducer:", error);
            alert("Failed to add introducer");
        }
    };

    const searchClients = async (searchText) => {
        if (!searchText) {
            setClientSuggestions([]);
            return;
        }
        try {
            const response = await axios.get(`${API}/client/nric/search`, {
                // Make sure the query parameter name matches the server's expectation
                params: {nricPassport: searchText},
                headers: {Authorization: `Bearer ${token}`},
            });

            console.log(response.data);

            // Assuming your server responds with an array of clients
            const clients = Array.isArray(response.data.clients) ? response.data.clients : [];

            console.log(clients);

            setClientSuggestions(clients);
        } catch (error) {
            console.error("Error searching clients:", error);
        }
    };


    return (
        <Layout>

            <main
                className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl bg-white transition-all duration-200">
                {/* cards */}
                <div className="w-full px-6 py-6 mx-auto">
                    <h3 className="text-black font-bold">Introducer Form</h3>
                    {/* Navbar */}
                    <nav
                        className="text-black relative flex flex-wrap items-center justify-between py-2 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
                        navbar-main=""
                        navbar-scroll="false"
                    >
                        <div className="flex items-center justify-between w-full py-1 mx-auto flex-wrap-inherit">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div className="">
                                            <Link to="/introducer-list"

                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Introducer</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center px-4">
                                            <svg
                                                className="h-5 w-5 flex-shrink-0 text-gray-400 pt-1"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            <Link to="/introducer-form"

                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Introducer Form</span>
                                            </Link>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </nav>
                    {/* Navbar */}
                    {/* end Navbar */}
                    <div className="flex flex-wrap -mx-3">
                        <div className="flex-none w-full max-w-full px-3">
                            <div className="w-full max-w-full px-3 shrink-0 md:flex-0">
                                <div
                                    className="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                                    <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                                        <div
                                            className="w-full max-w-full px-3 mx-auto mt-4 sm:my-auto md:flex-none lg:w-7/12">
                                            <div className="flex justify-center items-center">
                                                <ul
                                                    className="flex flex-wrap p-1 list-none bg-gray-50 rounded-xl"
                                                    nav-pills=""
                                                    role="tablist"
                                                >
                                                    <li className="z-30 flex-auto text-center">
                                                        <a
                                                            className="z-30 flex items-center justify-center w-full px-0 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg bg-inherit text-slate-700"
                                                            nav-link=""
                                                            active=""
                                                            role="tab"
                                                            aria-selected="true"
                                                        >
                                                            <span className="font-semibold ml-2">
                                                                {(id) ? 'Edit Introducer' : 'Add Introducer'}
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <form onSubmit={handleSubmit}>
                                        <div className="flex-auto p-6">
                                            <div className="flex flex-wrap -mx-3">
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-1/2 md:flex-0">

                                                    {/* Personal Details */}
                                                    <div className="mb-4">
                                                        <label htmlFor="firstName"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">First
                                                            Name*</label>
                                                        <input type="text" id="firstName" name="firstName"
                                                               value={introducerData.firstName}
                                                               onChange={handleChange} required
                                                               className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-1/2 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="lastName"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">Last
                                                            Name*</label>
                                                        <input type="text" id="lastName" name="lastName"
                                                               value={introducerData.lastName}
                                                               onChange={handleChange} required
                                                               className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0"></div>

                                            <div className="flex flex-wrap -mx-3">
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="phoneNumber"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">Phone
                                                            Number*</label>
                                                        <input type="tel" id="phoneNumber" name="phoneNumber"
                                                               maxLength="14"
                                                               value={introducerData.phoneNumber}
                                                               onChange={handleChange} required
                                                               className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="clientSearch"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">Search
                                                            Available Client (NRIC/Passport)*</label>
                                                        <input type="text" id="clientSearch" name="clientSearch"
                                                               maxLength="14"
                                                               value={searchText}
                                                               onChange={handleChange}
                                                               className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
                                                        {clientSuggestions.length > 0 && (
                                                            <ul className="absolute z-10 max-h-48 w-full overflow-auto bg-white border border-gray-300 mt-1 rounded-md shadow-lg">
                                                                {clientSuggestions.map((suggestion) => (
                                                                    <li
                                                                        key={suggestion.nric}
                                                                        onClick={() => handleSelectClient(suggestion)} // Pass the entire client object
                                                                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                                    >
                                                                        {suggestion.nric} - {suggestion.firstName} {suggestion.lastName}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                        {introducerData.clients.length > 0 && (
                                                            <div>
                                                                <label className="font-bold">Selected Clients:</label>
                                                                <ul>
                                                                    {introducerData.clients.map((client) => (
                                                                        <li key={client.nric}>
                                                                            {client.nric} - {client.firstName} {client.lastName}
                                                                            <span> | </span>
                                                                            <button
                                                                                onClick={() => handleRemoveClient(client.nric)}>Remove
                                                                            </button>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex flex-row items-end justify-end w-full">
                                                <button
                                                    type="submit"
                                                    className="px-8 mt-4 py-2 mb-4 mr-4 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-green-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                >
                                                    <i className="fa fa-check" aria-hidden="true"/> Save
                                                </button>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </Layout>
    );
}

export default IntroducerFormPage;
