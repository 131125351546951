import React, { useState, useEffect } from 'react';
 // Importing styles

const Carousel = () => {
    // Array of slides (images, texts, etc.)
    const slides = [
        {
            id: 1,
            content: <img src="https://lh3.googleusercontent.com/p/AF1QipNJBs3NzJtwsODq0fleor7jlLaUw1pm1UFOIFFR=s1360-w1360-h1020" style={{ width: "100%" }} />,
        },
        {
            id: 2,
            content: <img src="https://lh3.googleusercontent.com/p/AF1QipP5JqJ1zmEBdGPti2HhXLuSg-dwMgq28vAhrBOc=s1360-w1360-h1020" alt="Slide 3" style={{ width: "100%" }} />,
        },
        {
            id: 2,
            content: <img src="https://scontent.fkul10-1.fna.fbcdn.net/v/t39.30808-6/441058916_18429858805040883_19027036263192022_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=ULXBIPhwouQQ7kNvgFVc-65&_nc_ht=scontent.fkul10-1.fna&oh=00_AYBFoPzRocg_CXqKtWX6--p8Y8A6NxP8unpKoo8DULmZDg&oe=66545B70" alt="Slide 3" style={{ width: "120%" }} />,
        },
        // Add more slides as needed
    ];
    

    // State variables
    const [currentSlide, setCurrentSlide] = useState(0);

    // Timer for automatically changing slides
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 6000); // Change slide every 3 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [slides.length]);

    // Handle indicator click
    const handleIndicatorClick = (index) => {
        setCurrentSlide(index);
    };

    return (
        <div className="carousel">
            {/* Render the current slide */}
            <div className="carousel-slide">
                {slides[currentSlide].content}
            </div>

            {/* Render the indicators */}
            <div className="carousel-indicators">
                {slides.map((slide, index) => (
                    <span
                        key={slide.id}
                        className={`indicator ${currentSlide === index ? 'active' : ''}`}
                        onClick={() => handleIndicatorClick(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default Carousel;
