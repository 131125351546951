import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
import './assets/css/soft-ui-dashboard-tailwind.css';
import Background from './assets/img/car/car2.jpg';
import Carousel from './Carousel';
import logo from './assets/font_logo.png';


import { signin, isAuth, authenticate } from "../action/authAction";


const LoginPage = () => {
    const [data, setData] = useState({
        email: '',
        password: '',
    });

    const {
        email,
        password,
    } = data;

    const [error, setError] = useState("");

    const navigate = useHistory();

    const handleChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const user = {
            email,
            password,
        };
        try {
            const { data: res } = await signin(user).then((res) => {
                if (res.status === 200) {
                    //show popup
                    alert('Login Successful!');
                    // Clear form
                    setData({
                        email: '',
                        password: '',
                    });
                    //save user token to cookie
                    //save user info to localStroage
                    //authenticate user
                    authenticate(res, () => {
                        if (isAuth() && isAuth().role === 'Sales Admin') {
                            navigate.push("/dashboard");
                        } else {
                            navigate.push("/");
                            alert('Access Denied');
                        }
                    });
                } else {
                    //show popup
                    alert(String(res.error));
                    console.log(res.error.data.message);
                }
            });

            console.log(res.message);
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) {
                console.log(error.response.data.message);
                setError(error.response.data.message);
            }
        }
    };





    return (
        <>

            {/* Main Content */}
            <main className="mt-0 transition-all duration-200 ease-soft-in-out">
                <section>
                    <div className="relative flex items-center p-0 overflow-hidden bg-center bg-cover bg-dark-gray min-h-75-screen">
                        <div className="container z-10">
                            <div className="flex flex-wrap mt-0 -mx-3">
                                <div
                                    className="flex flex-col w-full max-w-full px-3 mx-auto md:flex-0 shrink-0 md:w-6/12 lg:w-5/12 xl:w-4/12">
                                    <div
                                        className="relative flex flex-col min-w-0 mt-3 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                                        <div className="p-6 pb-0 mb-0 bg-transparent border-b-0 rounded-t-2xl">
                                        <img src={logo} alt="Logo" className="h-90 -mt-7" /> {/* Logo image */}
                        
                                            <h3 className="relative z-10 font-bold text-transparent bg-gradient-to-tl from-red-400 bg-clip-text -mt-10">Welcome
                                                back</h3>
                                            <p className="mb-0 text-white">Enter your email and password to sign in</p>
                                        </div>
                                        <div className="flex-auto p-6">
                                            <form role="form" onSubmit={handleSubmit}>
                                                <label
                                                    className="mb-2 ml-1 font-bold text-xs text-gray-200">Email</label>
                                                <div className="mb-4">
                                                    <input
                                                        type="email"
                                                        placeholder="Email"
                                                        name="email"
                                                        onChange={handleChange}
                                                        value={data.email}
                                                        required
                                                        className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:outline-none focus:transition-shadow"
                                                    />
                                                </div>
                                                <label
                                                    className="mb-2 ml-1 font-bold text-xs text-gray-200">Password</label>
                                                <div className="mb-4">
                                                    <input
                                                        type="password"
                                                        placeholder="Password"
                                                        name="password"
                                                        onChange={handleChange}
                                                        value={data.password}
                                                        required
                                                        className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:outline-none focus:transition-shadow"
                                                    />
                                                </div>
                                                {/*<div className="min-h-6 mb-0.5 block pl-12">
                                                    <input id="rememberMe" type="checkbox" checked={true}
                                                           className="mt-0.54 rounded-10 duration-250 ease-soft-in-out after:rounded-circle after:shadow-soft-2xl after:duration-250 checked:after:translate-x-5.25 h-5 relative float-left -ml-12 w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-slate-800/10 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:border-slate-800/95 checked:bg-slate-800/95 checked:bg-none checked:bg-right"/>
                                                    <label
                                                        className="mb-2 ml-1 font-normal cursor-pointer select-none text-sm text-slate-700"
                                                        htmlFor="rememberMe">Remember me</label>
                                                </div>*/}
                                                <div className="text-center">
                                                    <button type="submit"
                                                        className="inline-block w-full px-6 py-3 mt-6 mb-0 font-bold text-center text-white uppercase align-middle transition-all bg-transparent border-0 rounded-lg cursor-pointer shadow-soft-md bg-x-25 bg-150 leading-pro text-xs ease-soft-in tracking-tight-soft bg-gradient-to-tl from-red-400 hover:scale-102 hover:shadow-soft-xs active:opacity-85">
                                                        Sign In
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full max-w-full px-3 lg:flex-0 shrink-0 md:w-6/12">

                                    <div className="absolute top-0 w-3/5 h-full -mr-30 overflow-hidden -skew-x-10 -right-40 rounded-bl-xl md:block">
                                        <div className="absolute inset-x-0 top-0 z-0 h-full -ml-16 skew-x-10">
                                            {/* Add the Carousel component here */}
                                            <Carousel />
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <footer className="py-12 bg-dark-gray">
                <div className="container">
                    <div className="flex flex-wrap -mx-3">
                        <div className="flex-shrink-0 w-full max-w-full mx-auto mb-6 text-center bg-dark-gray lg:flex-0 lg:w-8/12">
                            <a href="https://dtccarlifestyle.com/" target="_blank"
                                className="mb-2 mr-4 text-slate-400 sm:mb-0 xl:mr-12"> Directory </a>
                            <a href="javascript:;" target="_blank"
                                className="mb-2 mr-4 text-slate-400 sm:mb-0 xl:mr-12"> DTC Website </a>
                            <a href="javascript:;" target="_blank"
                                className="mb-2 mr-4 text-slate-400 sm:mb-0 xl:mr-12"> DTC Motor Salesperson </a>
                            <a href="javascript:;" target="_blank"
                                className="mb-2 mr-4 text-slate-400 sm:mb-0 xl:mr-12"> DTC Motors Club </a>
                           
                        </div>
                        <div
                            className="flex-shrink-0 w-full max-w-full mx-auto mt-2 mb-6 text-center bg-dark-gray lg:flex-0 lg:w-8/12">
                            <a href="https://www.facebook.com/p/DTC-Motors-100071006893641/?locale=ms_MY" target="_blank" className="mr-6 text-slate-400">
                                <span className="text-lg fab fa-facebook"></span>
                            </a>
                            <a href="https://www.tiktok.com/@dtc_carlifestyle?is_from_webapp=1&sender_device=pc" target="_blank" className="mr-6 text-slate-400">
                                <span className="text-lg fab fa-tiktok"></span>
                            </a>
                            <a href="https://www.instagram.com/dtcmotors/?hl=en" target="_blank" className="mr-6 text-slate-400">
                                <span className="text-lg fab fa-instagram"></span>
                            </a>
                            <a href="https://www.youtube.com/@dtcmotors/featured" target="_blank" className="mr-6 text-slate-400">
                                <span className="text-lg fab fa-youtube"></span>
                            </a>


                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3">

                    </div>
                </div>
            </footer>
        </>
    );
};

export default LoginPage;
