import React, {useState, useEffect} from "react";
import {useHistory, Link} from "react-router-dom";

import '../assets/css/nucleo-icons.css';
import '../assets/css/nucleo-svg.css';
import '../assets/css/soft-ui-dashboard-tailwind.css';
import Layout from "../../component/layout";
import {getCookie} from "../../action/authAction";
import axios from "axios";
import {API} from "../../config/config";

const IntroducerListPage = () => {
    const token = getCookie('token');

    const [clients, setClients] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalClients, setTotalClients] = useState(0);
    const [searchType, setSearchType] = useState('client');
    const [searchQuery, setSearchQuery] = useState('');


    useEffect(() => {
        const fetchAllClient = async () => {
            try {
                const response = await axios.get(`${API}/client/table/all?page=${page}&limit=10&searchQuery=${searchQuery}&searchType=${searchType}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log(response.data.data);
                setClients(response.data.data);
                setTotalPages(response.data.totalPages);
                setTotalClients(response.data.total);
            } catch (error) {
                console.error('Error fetching clients in data:', error);
            }
        };

        fetchAllClient();
    }, [page, token, searchQuery]);

    const renderClients = () => {
        if (!clients || clients.length === 0) {
            return <tr>
                <td colSpan="5">No introducer found</td>
            </tr>;
        } else {
            return clients.map((client, index) => (<tr key={index} className="text-center">
                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                    <div className="flex px-6 py-1">
                        <p className="mb-0 text-xs font-semibold leading-tight">
                            {index + 1}
                        </p>
                    </div>
                </td>
                <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                    {client.introducer ? (
                        <span className="text-xs font-semibold leading-tight text-slate-400">
                            <h6 className="mb-0 text-sm leading-normal">{client.introducer.firstName} {client.introducer.lastName}</h6>  <p
                            className="mb-0 text-xs leading-tight text-slate-400">{client.introducer.phoneNumber}</p></span>) : (
                        <span className="text-xs font-semibold leading-tight text-slate-400">-</span>)}
                </td>
                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                    <div className="flex px-14 py-1 ">
                        <div className="flex flex-col justify-center">
                            <h6 className="mb-0 text-sm leading-normal">
                                {client.firstName} {client.lastName}
                            </h6>
                            <p className="mb-0 text-xs leading-tight  text-slate-400">
                                {client.phoneNumber}
                            </p>
                        </div>
                    </div>
                </td>
                <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                    {client.sales && client.sales.length > 0 ? (client.sales.map((sale, index) => (<div key={index}>
                        <Link to={`/salesperson-detail/${sale.salesperson._id}`}>
                            <h6
                                className="mb-0 text-sm leading-normal">{sale.salesperson.firstName} {sale.salesperson.lastName}</h6>
                        </Link>
                    </div>))) : (<span className="text-xs font-semibold leading-tight text-slate-400">-</span>)}
                </td>
            </tr>));
        }
    };

    const renderPagination = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(<a
                key={i}
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    setPage(i);
                }}
                className={`mx-1 px-4 py-2 border rounded-md ${page === i ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
            >
                {i}
            </a>);
        }
        return (
            <div className="flex justify-center mt-4">
                {pages}
            </div>
        );
    };

    return (<Layout>
        <main
            className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl bg-white transition-all duration-200">
            {/* Navbar */}
            <div className="w-full px-6 py-3 mx-auto">
                <h3 className="text-black font-bold">Introducer</h3>
                {/* Navbar */}
                <nav
                    className="text-black relative flex flex-wrap items-center justify-between py-2 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
                    navbar-main=""
                    navbar-scroll="false"
                >
                    <div className="flex items-center justify-between w-full py-1 mx-auto flex-wrap-inherit">
                        <nav className="flex" aria-label="Breadcrumb">
                            <ol role="list" className="flex items-center space-x-4">
                                <li>
                                    <div className="">
                                        <Link to="/dashboard"
                                              href="../dashboard.html"
                                              className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                        >
                                            <span className="">Dashboard</span>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center px-4">
                                        <svg
                                            className="h-5 w-5 flex-shrink-0 text-gray-400 pt-1"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <a className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700">
                                            <span className="">Introducer</span>
                                        </a>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </nav>
                {/* end Navbar */}
                <div className="w-full px-6 py-6 mx-auto">
                    {/* table 1 */}
                    <div className="flex flex-wrap -mx-3">
                        <Link to={`/introducer-form`}>
                            <button
                                type="button"
                                class="mb-4 ml-4 inline-flex items-start rounded-lg border border-transparent bg-green-500 px-3 py-1 text-xs font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="2.5"
                                    stroke="currentColor"
                                    class="w-6 h-6"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 6v12m6-6H6"
                                    />
                                </svg>

                                <div class="">
                                    <a
                                        class="ml-2 text-sm font-bold text-white hover:text-white"
                                    >
                                        <span class="">Add Introducer</span>
                                    </a>
                                </div>
                            </button>
                        </Link>

                        <div
                            className="relative flex flex-wrap items-stretch w-15 transition-all rounded-lg ease ml-auto mr-80 mb-2">
    <span
        className="text-sm ease leading-5.6 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none border border-r-0 border-transparent bg-transparent py-0 px-2.5 text-center font-normal text-slate-500 transition-all">
        <i className="fas fa-search"/>
    </span>
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="pl-10 text-xs focus:shadow-primary-outline ease w-1/100 leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 dark:bg-slate-850 dark:text-white bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none focus:transition-shadow"
                                placeholder="Search By Client Name"
                            />
                        </div>


                        <div className="flex-none w-11/12 max-w-full px-3">
                            <div
                                className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-md rounded-2xl bg-clip-border">
                                <div
                                    className="relative flex flex-row min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-md rounded-2xl bg-clip-border">
                                    <Link to="/introducer-list">
                                        <div
                                            className="p-6 pb-0 mb-3 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                                            <a>All Introducer</a>
                                        </div>
                                    </Link>
                                </div>
                                <div className="flex-auto px-0 pt-0 pb-2">
                                    <div className="p-0 overflow-x-auto">
                                        <table
                                            className="items-center w-11/12 mb-0 align-top border-gray-200 text-slate-500">
                                            <thead>
                                            <thead>
                                            <tr className="text-center">
                                                <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                    No
                                                </th>
                                                <th className="px-16 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                    Introducer
                                                </th>
                                                <th className="px-19 py-3 pl-5 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                    Client Name
                                                </th>
                                                <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                    Salesperson In Charge
                                                </th>
                                                <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                    {/* Add your header title here */}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {renderClients()}
                                            </tbody>
                                            </thead>
                                        </table>
                                        <div
                                            className="flex items-center justify-center border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                                            <div className="flex justify-center w-full">
                                                {renderPagination()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </Layout>);
}

export default IntroducerListPage;
