import fetch from "isomorphic-fetch";
import {API} from "../config/config";
import cookie from "js-cookie";

export const signup = (user) => {
    console.log(user);
    return fetch(`${API}/signup`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            return err;
        });
};

export const signin = (user) => {
    let payload;

    // Check if the input is FormData and convert it to JSON
    if (user instanceof FormData) {
        payload = {};
        for (let [key, value] of user.entries()) {
            payload[key] = value;
        }
    } else {
        // If it's not FormData, assume it's already a JSON object
        payload = user;
    }

    console.log(payload);

    return fetch(`${API}/signin`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            console.error("Error during signin:", err);
            return err;
        });
};


export const signout = (next) => {
    removeCookie("token");
    removeLocalStorage("user");
    next();

    return fetch(`${API}/signout`, {
        method: "GET",
    })
        .then((response) => {
            console.log("signout success");
        })
        .catch((err) => {
            console.log(err);
        });
};

// set the cookie
export const setCookie = (key, value) => {
    //if (process.browser) {
    cookie.set(key, value, {
        expires: 1,
    });
    //}
};

// remove the cookie
export const removeCookie = (key) => {
    //if (process.browser) {
    cookie.remove(key, {
        expires: 1,
    });
    //}
};

// get cookie
export const getCookie = (key) => {
    //if (process.browser) {
    return cookie.get(key);
    //}
};

//localStorage
export const setLocalStorage = (key, value) => {
    //if (process.browser) {
    localStorage.setItem(key, JSON.stringify(value));
    //}
};

// remove from localStrorage
export const removeLocalStorage = (key) => {
    //if (process.browser) {
    localStorage.removeItem(key);
    //}
};

// autheticate user by pass data to cookie and localStorage
export const authenticate = (data, next) => {
    setCookie("token", data.token);
    setLocalStorage("user", data.user);
    next();
};

export const isAuth = () => {
    //if (process.browser) {
    const cookieChecked = getCookie("token");
    if (cookieChecked) {
        if (localStorage.getItem("user")) {
            return JSON.parse(localStorage.getItem("user"));
        } else {
            return false;
        }
    }
    //}
};
