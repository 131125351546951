import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
import './assets/css/soft-ui-dashboard-tailwind.css';

import Layout from "../component/layout";
import { getCookie } from "../action/authAction";
import axios from "axios";
import { API } from "../config/config";

import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const UnitSoldPage = () => {
    const token = getCookie('token');

    const [totalUnitThisYear, setTotalUnitThisYear] = useState(0);
    const [totalUnitLastYear, setTotalUnitLastYear] = useState(0);
    const [totalDifference, setTotalDifference] = useState(0);

    const allBrands = ["Audi", "BMW", "Ferrari", "Honda", "Lamborghini", "Lexus", "McLaren", "Mercedes", "Nissan", "Porsche", "Rolls-Royce", "Tesla", "Toyota", "MINI", "Pagani", "Bugatti", "Koenigsegg", "Aston Martin", "Bentley", "Jaguar", "Land Rover", "Maserati", "Cadillac", "Lincoln", "Acura", "Genesis", "Infiniti", "Volkswagen", "Peugeot", "Ford", "BYD", "Winnebago",];


    const [lexusTotal, setLexusTotal] = useState(0);
    const [teslaTotal, setTeslaTotal] = useState(0);
    const [hondaTotal, setHondaTotal] = useState(0);
    const [rollsRoyceTotal, setRollsRoyceTotal] = useState(0);
    const [audiTotal, setAudiTotal] = useState(0);
    const [toyotaTotal, setToyotaTotal] = useState(0);
    const [bmwTotal, setBmwTotal] = useState(0);
    const [mercedesTotal, setMercedesTotal] = useState(0);
    const [porscheTotal, setPorscheTotal] = useState(0);
    const [mcLarenTotal, setMcLarenTotal] = useState(0);
    const [nissanTotal, setNissanTotal] = useState(0);
    const [lamborghiniTotal, setLamborghiniTotal] = useState(0);
    const [ferrariTotal, setFerrariTotal] = useState(0);
    const [miniTotal, setMiniTotal] = useState(0);
    const [paganiTotal, setPaganiTotal] = useState(0);
    const [bugattiTotal, setBugattiTotal] = useState(0);
    const [koenigseggTotal, setKoenigseggTotal] = useState(0);
    const [astonMartinTotal, setAstonMartinTotal] = useState(0);
    const [bentleyTotal, setBentleyTotal] = useState(0);
    const [jaguarTotal, setJaguarTotal] = useState(0);
    const [landRoverTotal, setLandRoverTotal] = useState(0);
    const [maseratiTotal, setMaseratiTotal] = useState(0);
    const [cadillacTotal, setCadillacTotal] = useState(0);
    const [lincolnTotal, setLincolnTotal] = useState(0);
    const [acuraTotal, setAcuraTotal] = useState(0);
    const [genesisTotal, setGenesisTotal] = useState(0);
    const [infinitiTotal, setInfinitiTotal] = useState(0);
    const [volkswagenTotal, setVolkswagenTotal] = useState(0);
    const [peugeotTotal, setPeugeotTotal] = useState(0);
    const [fordTotal, setFordTotal] = useState(0);
    const [bydTotal, setBydTotal] = useState(0);
    const [winnebagoTotal, setWinnebagoTotal] = useState(0);


    // Define a single color for each dataset
    const colorThisYear = 'rgba(75, 192, 192, 0.7)'; // Example color for this year
    const colorLastYear = 'rgba(153, 102, 255, 0.7)'; // Example color for last year

    const initialChartData = {
        labels: allBrands,
        datasets: [
            {
                label: 'Units Sold Last Year',
                data: allBrands.map(() => 0), // Initialize with 0 sales
                backgroundColor: colorLastYear, // Use a single color for last year
            },
            {
                label: 'Units Sold This Year',
                data: allBrands.map(() => 0), // Initialize with 0 sales
                backgroundColor: colorThisYear, // Use a single color for this year
            }
        ],
    };

    const [chartData, setChartData] = useState(initialChartData);

    useEffect(() => {
        const fetchSalesByBrand = async () => {
            try {
                // Assume your API now returns data with this year and last year's sales
                const response = await axios.get(`${API}/sales/chart/brand`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const { data: { currentYear, lastYear } } = response.data;

                currentYear.forEach(({ _id: brand, totalUnitsSold }) => {
                    switch (brand) {
                        case "Lexus":
                            setLexusTotal(totalUnitsSold);
                            break;
                        case "Tesla":
                            setTeslaTotal(totalUnitsSold);
                            break;
                        case "Honda":
                            setHondaTotal(totalUnitsSold);
                            break;
                        case "Rolls-Royce":
                            setRollsRoyceTotal(totalUnitsSold);
                            break;
                        case "Audi":
                            setAudiTotal(totalUnitsSold);
                            break;
                        case "Toyota":
                            setToyotaTotal(totalUnitsSold);
                            break;
                        case "BMW":
                            setBmwTotal(totalUnitsSold);
                            break;
                        case "Mercedes":
                            setMercedesTotal(totalUnitsSold);
                            break;
                        case "Porsche":
                            setPorscheTotal(totalUnitsSold);
                            break;
                        case "McLaren":
                            setMcLarenTotal(totalUnitsSold);
                            break;
                        case "Nissan":
                            setNissanTotal(totalUnitsSold);
                            break;
                        case "Lamborghini":
                            setLamborghiniTotal(totalUnitsSold);
                            break;
                        case "Ferrari":
                            setFerrariTotal(totalUnitsSold);
                            break;
                        case "MINI":
                            setMiniTotal(totalUnitsSold);
                            break;
                        case "Pagani":
                            setPaganiTotal(totalUnitsSold);
                            break;
                        case "Bugatti":
                            setBugattiTotal(totalUnitsSold);
                            break;
                        case "Koenigsegg":
                            setKoenigseggTotal(totalUnitsSold);
                            break;
                        case "Aston Martin":
                            setAstonMartinTotal(totalUnitsSold);
                            break;
                        case "Bentley":
                            setBentleyTotal(totalUnitsSold);
                            break;
                        case "Jaguar":
                            setJaguarTotal(totalUnitsSold);
                            break;
                        case "Land Rover":
                            setLandRoverTotal(totalUnitsSold);
                            break;
                        case "Maserati":
                            setMaseratiTotal(totalUnitsSold);
                            break;
                        case "Cadillac":
                            setCadillacTotal(totalUnitsSold);
                            break;
                        case "Lincoln":
                            setLincolnTotal(totalUnitsSold);
                            break;
                        case "Acura":
                            setAcuraTotal(totalUnitsSold);
                            break;
                        case "Genesis":
                            setGenesisTotal(totalUnitsSold);
                            break;
                        case "Infiniti":
                            setInfinitiTotal(totalUnitsSold);
                            break;
                        case "Volkswagen":
                            setVolkswagenTotal(totalUnitsSold);
                            break;
                        case "Peugeot":
                            setPeugeotTotal(totalUnitsSold);
                            break;
                        case "Ford":
                            setFordTotal(totalUnitsSold);
                            break;
                        case "BYD":
                            setBydTotal(totalUnitsSold);
                            break;
                        case "Winnebago":
                            setWinnebagoTotal(totalUnitsSold);
                            break;
                        default:
                            break;
                    }
                });

                let newDataThisYear = allBrands.map(brand => {
                    const sale = currentYear.find(s => s._id === brand);
                    return sale ? sale.totalUnitsSold : 0;
                });

                let newDataLastYear = allBrands.map(brand => {
                    const sale = lastYear.find(s => s._id === brand);
                    return sale ? sale.totalUnitsSold : 0;
                });

                setChartData(prevData => ({
                    ...prevData,
                    datasets: [
                        { ...prevData.datasets[0], data: newDataLastYear },
                        { ...prevData.datasets[1], data: newDataThisYear }
                    ]
                }));
            } catch (error) {
                console.error("Failed to fetch sales data by brand", error);
            }
        };

        fetchSalesByBrand();
    }, [token]);

    useEffect(() => {
        // This useEffect depends directly on `chartData`, so it will run after `chartData` is updated.
        const totalUnitsThisYear = chartData.datasets?.find(dataset => dataset.label === 'Units Sold This Year')?.data.reduce((acc, val) => acc + val, 0) || 0;
        const totalUnitsLastYear = chartData.datasets?.find(dataset => dataset.label === 'Units Sold Last Year')?.data.reduce((acc, val) => acc + val, 0) || 0;

        // Calculate the difference in total units sold between this year and last year
        const difference = totalUnitsThisYear - totalUnitsLastYear;


        setTotalDifference(difference);
        setTotalUnitThisYear(totalUnitsThisYear);
        setTotalUnitLastYear(totalUnitsLastYear);
    }, [chartData]); // Dependency array ensures this runs only when chartData updates.

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: true, // Enable the legend to distinguish between this year and last year
                position: 'top',
                labels: {
                    usePointStyle: true, // Optional: use point style for a more compact legend
                },
            },
            title: {
                display: true,
                text: 'Car Brand Sales Comparison: This Year vs Last Year',
            },
            tooltip: {
                callbacks: {
                    title: function (tooltipItems, data) {
                        // Check if tooltipItems array is not empty and data is defined
                        if (tooltipItems.length > 0 && data && data.labels) {
                            // Assuming the tooltipItems array is not empty, return the car brand for the title
                            return data.labels[tooltipItems[0].dataIndex];
                        }
                        return ''; // Return an empty string if conditions are not met
                    },
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        label += context.parsed.y;
                        return label;
                    },
                    footer: function (tooltipItems, data) {
                        // Optional: Add any additional information you want in the footer
                    }
                },
                mode: 'index', // Show all datasets at once in the tooltip
                intersect: false, // Show the tooltip even when not exactly hovering over a datapoint
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Car Brands',
                },
                grid: {
                    display: false, // Optional: Hide grid lines for x-axis for cleaner design
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Units Sold',
                },
                grid: {
                    drawBorder: false, // Optional: Hide the border line for y-axis for cleaner design
                },
            },
        },
    };

    return (
        <Layout>
            <main
                className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200">
                {/* Navbar */}
                <nav
                    className="relative flex flex-wrap items-center justify-between px-0 py-2 mx-6 transition-all shadow-none duration-250 ease-soft-in rounded-2xl lg:flex-nowrap lg:justify-start"
                    navbar-main=""
                    navbar-scroll="true"
                >
                    <div className="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
                        <nav>
                            {/* breadcrumb */}
                            <ol className="flex flex-wrap pt-1 mt-6 mr-12 bg-transparent rounded-lg sm:mr-16">
                                <li className="leading-normal text-sm">
                                    <div className="">
                                        <Link to="/dashboard"

                                            className="ml-4 text-sm font-medium text-gray-800 hover:text-gray-00"
                                        >
                                            <span className="">Dashboard</span>
                                        </Link>
                                    </div>
                                </li>
                                <li
                                    className="text-sm pl-2 capitalize leading-normal text-slate-700 before:float-left before:pr-2 before:text-gray-600 before:content-['/']"
                                    aria-current="page"
                                >
                                    Unit sold this year
                                </li>
                            </ol>
                            <h6 className="mb-0 font-bold capitalize pt-4">Unit Sold This Year</h6>
                        </nav>
                        <div
                            className="flex items-center mt-2 grow sm:mt-0 sm:mr-6 md:mr-0 lg:flex lg:basis-auto"></div>
                    </div>
                </nav>
                {/* end Navbar */}
                {/* cards */}
                <div className="w-full px-6 py-0 mx-auto">
                    {/* row 1 */}
                    <div className="flex flex-wrap -mx-3">
                        {/* cards row 3 */}
                        <div className="flex flex-wrap mt-3 -mx-7">
                            <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-full lg:flex-none">
                                <div
                                    className="border-black/12.5 shadow-soft-lg relative z-20 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                                    <div className="flex-auto p-4">
                                        <div
                                            className="py-4 pr-1 mb-4 bg-gradient-to-tl from-gray-900 to-slate-800 rounded-xl">
                                            <div>
                                                <Bar data={chartData} options={chartOptions} />
                                            </div>
                                        </div>
                                        <h6 className="mt-6 mb-0 ml-2">
                                            <span className="leading-normal text-2xl font-weight-bolder text-lime-500">
                                                {totalUnitThisYear}
                                            </span>
                                            <span className="leading-normal ml-2 text-xl font-weight-bolder text-black">
                                                Unit sold this year
                                            </span>
                                        </h6>
                                        <p className="ml-2 leading-normal text-sm">
                                            (<span className="font-bold">{totalDifference}</span>) than last year
                                        </p>
                                        <div className="w-full px-6 mx-auto max-w-screen-2xl rounded-xl">
                                            <div className="flex flex-wrap mt-0 -mx-3">
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://m.media-amazon.com/images/I/71tgImnGzKL.jpg"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Acura
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{acuraTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={30}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://i.pinimg.com/originals/a6/1f/36/a61f36fab622f8b33f207295b766ca1b.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Audi
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{audiTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={30}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://i.pinimg.com/564x/ef/34/98/ef3498ad828d179e9de593f9ddcfe525.jpg"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Aston Martin
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{astonMartinTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={30}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://1000logos.net/wp-content/uploads/2018/03/Symbol-Bentley.jpg"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Bentley
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{bentleyTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={30}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/BMW.svg/2048px-BMW.svg.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            BMW
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{bmwTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={30}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Bugatti_logo.svg/2560px-Bugatti_logo.svg.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Bugatti
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{bugattiTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={30}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://www.shutterstock.com/image-vector/west-sumatra-indonesia-january-16-600nw-2413428111.jpg"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            BYD
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{bydTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={30}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://i.ebayimg.com/images/g/j00AAOSwoF5cYIGv/s-l1600.jpg"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Cadillac
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{cadillacTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={30}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://static.wixstatic.com/media/f2bf43_2ea2ce97f3174573b7449a81ceb8e1a9~mv2.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Ferrari
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{ferrariTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Ford-Motor-Company-Logo.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Ford
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{fordTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://car-brand-names.com/wp-content/uploads/2020/07/Genesis-Logo-2008.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Genesis
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{genesisTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://cdn.iconscout.com/icon/free/png-256/free-honda-3215418-2673836.png?f=webp"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Honda
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{hondaTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={90}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                              
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://purepng.com/public/uploads/large/purepng.com-infiniti-car-logologocar-brand-logoscarsinfiniti-car-logo-1701527428178ogamk.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Infiniti
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{infinitiTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={90}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://1000logos.net/wp-content/uploads/2019/12/Jaguar-Logo.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Jaguar
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{jaguarTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={90}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://car-logos.b-cdn.net/wp-content/uploads/2023/03/koenigsegg-logo1-scaled.webp"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Koenigsegg
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{koenigseggTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={90}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://upload.wikimedia.org/wikipedia/en/thumb/d/df/Lamborghini_Logo.svg/1200px-Lamborghini_Logo.svg.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Lamborghini
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{lamborghiniTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://wallpapercave.com/wp/wp4045471.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Land Rover
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{landRoverTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://cdn.freelogovectors.net/wp-content/uploads/2023/04/lexus-logo-06-freelogovectors.net_.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Lexus
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{lexusTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 -ml-px flex h-1.5 w-3/5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://www.pngall.com/wp-content/uploads/13/Lincoln-Motor-Company-Logo-PNG-Picture.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Lincoln
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{lincolnTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://www.carlogos.org/logo/Pagani-logo-1992-1440x900.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Pagani
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{paganiTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://i.pinimg.com/originals/9f/fa/75/9ffa75049a13156075ea96d604786e62.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Peugeot
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{peugeotTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://seeklogo.com/images/P/porsche-logo-2F6AD17368-seeklogo.com.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Porsche
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{porscheTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://i.pinimg.com/originals/5e/5f/0f/5e5f0f05f9703fa3db0cc93b5d2c8578.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Maserati
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{maseratiTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://styles.redditmedia.com/t5_2se1p/styles/communityIcon_xb7h6qnavas61.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Mclaren
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{mcLarenTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://i.pinimg.com/originals/da/9c/a5/da9ca5610b6a94b59294e9cc37657cb1.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Mercedes
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{mercedesTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://logos-world.net/wp-content/uploads/2021/04/Mini-Logo-2001-2018.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            MINI
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{miniTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://www.freepnglogos.com/uploads/nissan-logo-2.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Nissan
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{nissanTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>

                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://logos-world.net/wp-content/uploads/2021/04/Rolls-Royce-Logo.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Rolls-Royce
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{rollsRoyceTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={30}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://www.svgrepo.com/show/342292/tesla.svg"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Tesla
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{teslaTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-9/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={90}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://m.media-amazon.com/images/I/71A-5+o5GUL.jpg"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Toyota
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{toyotaTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={30}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://upload.wikimedia.org/wikipedia/commons/2/23/Volkswagen_-_Logo.svg"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Volkwagen
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{volkswagenTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={30}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                                <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
                                                    <div className="flex mb-2">
                                                        <div
                                                            className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current bg-transparent text-neutral-900">
                                                            <img
                                                                src="https://assets-cdn.interactcp.com/lichtsinn/images/microsites/view/untitled-2.png,qmodified=0124202417210338.pagespeed.ce.Wpp4HqadYg.png"
                                                                className="h-8 w-16"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="mt-1 mb-0 font-semibold ml-4 leading-tight text-xs">
                                                            Winnebago
                                                        </p>
                                                    </div>
                                                    <h4 className="font-bold">{winnebagoTotal}</h4>
                                                    <div
                                                        className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
                                                        {/* <div
                    className="duration-600 ease-soft -mt-0.38 w-3/10 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
                    role="progressbar"
                    aria-valuenow={30}
                    aria-valuemin={0}
                    aria-valuemax={100}
                /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* cards row 4 */}
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default UnitSoldPage;
