import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
import './assets/css/soft-ui-dashboard-tailwind.css';

import carImage from './assets/img/car/car3.png'
import Layout from "../component/layout";
import { getCookie } from "../action/authAction";
import axios from "axios";
import { API } from "../config/config";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DashboardPage = () => {
    const token = getCookie('token');

    const [totalRevenue, setTotalRevenue] = useState(0);
    const [totalUnit, setTotalUnit] = useState(0);

    const [salesPersons, setSalesPersons] = useState([]);

    const [statusCounts, setStatusCounts] = useState({});

    const [stocksOut, setStocksOut] = useState([]);

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 6 }, (_, i) => currentYear - i).reverse();

    // State for storing the chart data
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });

    const [comparisonText, setComparisonText] = useState('');

    const [isIncrease, setIsIncrease] = useState(false);

    useEffect(() => {
        const fetchTotalRevenue = async () => {
            try {
                const response = await axios.get(`${API}/sales/revenue/total`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                console.log(response.data.data);
                setTotalRevenue(response.data.data);
            } catch (error) {
                console.error('Error fetching total revenue:', error);
                // Handle error
            }
        };

        const fetchTotalUnitSold = async () => {
            try {
                const response = await axios.get(`${API}/sales/unit/total`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                console.log(response.data.data);
                setTotalUnit(response.data.data);
            } catch (error) {
                console.error('Error fetching total unit:', error);
                // Handle error
            }
        };

        const fetchSalesPersons = async () => {
            try {
                const response = await axios.get(`${API}/salesperson/all`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                // Assuming response.data.data contains an array of salespersons
                setSalesPersons(response.data.data.sort((a, b) => b.totalRevenue - a.totalRevenue));
            } catch (error) {
                console.error("Failed to fetch salespersons", error);
            }
        };

        const fetchStatusCounts = async () => {
            try {
                const response = await axios.get(`${API}/sales/status/all`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setStatusCounts(response.data.data);
            } catch (error) {
                console.error("Failed to fetch sales status counts", error);
            }
        };

        const fetchStockOut = async () => {
            try {
                const response = await axios.get(`${API}/stock/table/out?limit=6`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setStocksOut(response.data.data);
            } catch (error) {
                console.error('Error fetching stock out data:', error);
            }
        };

        const fetchSalesOverviewData = async () => {
            try {
                const { data } = await axios.get(`${API}/sales/chart/overview`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log(data);

                // Transform the data to match the expected format for the chart
                const years = data.data.map(item => item.year);
                const totalUnitsSold = data.data.map(item => item.totalUnitsSold);
                const totalRevenue = data.data.map(item => item.totalRevenue);

                setChartData({
                    labels: years,
                    datasets: [
                        {
                            label: 'Total Units Sold',
                            data: totalRevenue,
                            backgroundColor: 'rgba(54, 162, 235, 0.5)',
                            // This assumes you want to show revenue in the tooltip
                            unitSoldData: totalUnitsSold
                        }
                    ]
                });

                if (data.data && data.data.length >= 2) {
                    const sortedData = [...data.data].sort((a, b) => a.year - b.year);
                    const latestYearData = sortedData[sortedData.length - 1];
                    const previousYearData = sortedData[sortedData.length - 2];

                    const difference = latestYearData.totalRevenue - previousYearData.totalRevenue;
                    let percentageDifference = (difference / previousYearData.totalRevenue) * 100;

                    // Check if the absolute percentage difference exceeds 100
                    if (Math.abs(percentageDifference) > 100) {
                        percentageDifference = 100 * Math.sign(percentageDifference);
                    }

                    const comparisonText = `${Math.abs(percentageDifference.toFixed(2))}% ${percentageDifference >= 0 ? 'more' : 'less'} in ${latestYearData.year} compare to last year`;

                    setComparisonText(comparisonText);

                    setIsIncrease(percentageDifference >= 0);
                }

            } catch (error) {
                console.error('Error fetching sales overview data:', error);
            }
        };

        fetchTotalRevenue();
        fetchTotalUnitSold()
        fetchSalesPersons();
        fetchStatusCounts();
        fetchStockOut();
        fetchSalesOverviewData();
    }, [token]);

    // Dynamically set the icon class based on isIncrease
    const arrowIconClass = `fa ${isIncrease ? 'fa-arrow-up text-lime-500' : 'fa-arrow-down text-red-500'}`;

    const renderStocksOut = () => {
        if (!stocksOut || stocksOut.length === 0) {
            return <tr>
                <td colSpan="5">No stocks found</td>
            </tr>;
        } else {
            return stocksOut.map((stock, index) => (
                <tr key={index} className="text-center">
                    <td className="p-3 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{index + 1}</td>
                    <td className="p-3 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{stock.brand} {stock.carName}</td>
                    <td className="p-3 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{stock.chassisNo}</td>
                    <td className="p-3 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{new Date(stock.stockInDate).toLocaleDateString()}</td>
                </tr>
            ));
        }
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: false,
                text: 'Sales Overview',
            },
            tooltip: {
                callbacks: {
                    // Customize the tooltip label to include revenue
                    label: function (context) {
                        const value = context.parsed.y;
                        const unitSold = context.dataset.unitSoldData[context.dataIndex];
                        return `RM${value} (Unit: ${unitSold})`;
                    },
                },
            },
        },
    };

    return (
        <Layout>
            <div className="m-0 font-sans antialiased font-normal text-base leading-default bg-white text-slate-500">
                <main
                    className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200">
                    {/*Navbar*/}
                    <div className="w-full px-6 py-3 mx-auto">
                        <h3 className="text-black font-bold">
                            Dashboard
                        </h3>
                        <nav
                            className="text-black relative flex flex-wrap items-center justify-between py-2 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
                            navbar-main
                            navbar-scroll="false"
                        >
                            <div
                                className="flex items-center justify-between w-full py-1 mx-auto flex-wrap-inherit"
                            >
                                <nav className="flex" aria-label="Breadcrumb">
                                    <ol role="list" className="flex items-center space-x-4">
                                        <li>
                                            <div className="">
                                                <Link to="/dashboard"

                                                    className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                                >
                                                    <span className="">Dashboard</span>
                                                </Link>
                                            </div>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </nav>
                        {/*end Navbar*/}

                    </div>

                    {/*cards*/}
                    <div className="w-full px-6 py-1 mx-auto">
                        {/*row 1*/}
                        <div className="flex flex-wrap -mx-3">
                            {/*card 1*/}
                            <div className="w-full max-w-full px-2 mb-10 sm:w-1/2 sm:flex-none xl:mb-20 xl:w-1/4">
                                <Link to="/revenue">
                                    <div
                                        className="relative flex flex-col min-w-0 break-words bg-white shadow-md rounded-2xl bg-clip-border">
                                        <div className="flex-auto p-4">
                                            <div className="flex flex-row -mx-3">
                                                <div className="flex-none w-2/3 max-w-full px-1 mb-20 h-24">
                                                    <div>
                                                        <div className="mt-1 font-sans font-semibold leading-normal text-lg mb-2">
                                                            <span>Revenue</span><br />
                                                            <span className="block">to date</span>
                                                        </div>

                                                        <h4 className="mb-0 font-bold">
                                                            RM {totalRevenue.toLocaleString()}
                                                        </h4>
                                                        {/* <h4 className="mb-0 font-bold ">
                                                            
                                                        </h4> */}
                                                    </div>
                                                </div>
                                                <div className="px-6 text-right basis-1/2">
                                                    <div
                                                        className="inline-block w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-red-500 to-gray-800">
                                                        <i className="ni leading-none ni-world text-lg relative top-3.5 text-white"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            {/*card 2*/}
                            <div className="w-full max-w-full px-2 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
                                <Link to="/unit-sold">
                                    <div
                                        className="relative flex flex-col min-w-0 break-words bg-white shadow-md rounded-2xl bg-clip-border">
                                        <div className="flex-auto p-4">
                                            <div className="flex flex-row -mx-3">
                                                <div className="flex-none w-2/3 max-w-full px-1 mb-20 h-24">

                                                    <div>
                                                        <div className="mt-1 font-sans font-semibold leading-normal text-lg mb-2">
                                                            <span>Units sold</span><br />
                                                            <span className="block">to date</span>
                                                        </div>

                                                        <h3 className="mb-2 font-bold ">
                                                            <a>{totalUnit.toLocaleString()}</a>
                                                        </h3>

                                                    </div>
                                                </div>
                                                <div className="px-1 text-right basis-1/3">
                                                    <div
                                                        className="inline-block w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-red-500 to-gray-800">
                                                        <i className="ni leading-none ni-world text-lg relative top-3.5 text-white"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            {/*card 3*/}

                            <div className="w-full max-w-full px-2 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
                                <Link to={`/salesperson-chart`}>
                                    <div
                                        className="relative flex flex-col min-w-0 break-words bg-white shadow-md rounded-2xl bg-clip-border">
                                        <div className="flex-auto p-4">
                                            <div className="flex flex-row -mx-3">
                                                <div className="flex-none max-w-full px-1 mb-20 h-24">
                                                    <div>
                                                    <div className="mt-1 font-sans font-semibold leading-normal text-lg mb-2">
                                                        <span>Salesperson</span><br />
                                                        <span className="block">Performance</span>
                                                    </div>
                                                        {salesPersons.filter(salesPerson => salesPerson.salesCount > 0).slice(0, 3).map((salesPerson, index) => (
                                                            <h5 key={salesPerson._id}
                                                                className="mb-0 font-bold text-base ">
                                                                <span
                                                                    className="leading-normal mr-2 text-xl font-weight-bolder text-lime-500">{index + 1}</span>
                                                                {salesPerson.firstName} {salesPerson.lastName}
                                                            </h5>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="px-3 text-right basis-1/3">
                                                    <div
                                                        className="inline-block w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-red-500 to-gray-800">
                                                        <i className="ni leading-none ni-paper-diploma text-lg relative top-3.5 text-white"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>


                            {/*card 4*/}
                            <div className="w-full max-w-full px-2 sm:w-1/2 sm:flex-none xl:w-1/4">
                                <div
                                    className="relative flex flex-col min-w-0 break-words bg-white shadow-md rounded-2xl bg-clip-border">
                                    <div className="flex-auto p-4">
                                        <div className="flex flex-row -mx-3">
                                            <div className="flex-none w-2/3 max-w-full px-3 mb-20 h-24">
                                                <div>
                                                    <div className="mt-1 font-sans font-semibold leading-normal text-lg mb-2">
                                                        <span>Current</span><br />
                                                        <span className="block">Status</span>
                                                    </div>
                                                    {Object.keys(statusCounts).map((status, index) => (
                                                        <h6 key={index} className="mb-0 font-bold">
                                                            <span
                                                                className="leading-normal mr-2 text-xl font-weight-bolder text-lime-500">
                                                                {statusCounts[status]}
                                                            </span>
                                                            {status}
                                                        </h6>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="px-3 text-right basis-1/3">
                                                <div
                                                    className="inline-block w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-red-500 to-gray-800">
                                                    <i className="ni leading-none ni-chart-bar-32 text-lg relative top-3.5 text-white"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-wrap mt-2 -mx-3">

                            <div className="w-full h-full max-w-full px-3 mt-0 lg:w-7/12 lg:flex-none">
                                <div
                                    className="border-black/12.5 shadow-md h-full relative z-20 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                                    <div
                                        className="border-black/12.5 mb-0 rounded-t-2xl border-b-0 border-solid bg-white p-6 pb-0">
                                        <p className="mt-1 font-sans font-semibold leading-normal text-lg mb-2">Sales Overview</p>
                                        <p className="leading-normal text-sm">
                                            <i className={arrowIconClass}></i>
                                            <span className="font-semibold">{comparisonText}</span>
                                        </p>
                                    </div>
                                    <div className="flex-auto p-4">
                                        <div style={{ width: '100%', height: '340px' }}>
                                            <Bar data={chartData} options={chartOptions} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-5/12 lg:flex-none">
                                <Link to="/stock-detail">
                                    <div
                                        className="border-black/12.5 shadow-md relative z-20 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">

                                        <div className="flex-auto p-4">
                                            <p className="mt-1 font-sans font-semibold leading-normal text-lg mb-2">Stocks</p>

                                            <div className="w-full px-0 mx-auto max-w-screen-2xl rounded-xl">
                                                <div className="flex flex-wrap mt-0 -mx-3">

                                                    <div className="flex-none w-1/2 max-w-full py-2 pl-0 pr-3 mt-0">
                                                        <div className="flex mb-2">
                                                    
                                                            <h3 className="font-bold ml-3">{stocksOut.length}</h3>

                                                            <p className="mt-3 ml-2 mb-0 font-semibold leading-tight text-xs">Stock
                                                                out</p>
                                                        </div>

                                                    </div>

                                                    <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">


                                                    </div>
                                                </div>
                                            </div>
                                            <table
                                                className="items-center w-full mb-3 align-top border-collapse dark:border-white/40 text-slate-500 border rounded-lg"
                                            >
                                                <thead>
                                                    <tr className="text-center">
                                                        <th
                                                            className="px-1 py-0 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-50"
                                                        >

                                                        </th>
                                                        <th
                                                            className="px-1 py-3 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70"
                                                        >
                                                            Car
                                                        </th>
                                                        <th
                                                            className="px-1 py-3 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70"
                                                        >
                                                            Chassis No.
                                                        </th>
                                                        <th
                                                            className="px-1 py-3 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70"
                                                        >
                                                            Date
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-xxs text-center font-bold">
                                                    {renderStocksOut()}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </Layout>
    );
}
export default DashboardPage;
