import React, {useState, useEffect} from "react";
import Layout from "../../component/layout";
import {Link, useParams} from "react-router-dom";
import {getCookie} from "../../action/authAction";
import {API} from "../../config/config";
import axios from "axios";

const SalesPersonDetail = () => {
    let {id} = useParams();
    const token = getCookie('token');

    const [salesPerson, setSalesPerson] = useState(null);
    const [salesInProgress, setSalesInProgress] = useState([]);
    const [salesCompleted, setSalesCompleted] = useState([]);
    const [pageInProgress, setPageInProgress] = useState(1);
    const [pageCompleted, setPageCompleted] = useState(1);
    const [totalPagesInProgress, setTotalPagesInProgress] = useState(0);
    const [totalPagesCompleted, setTotalPagesCompleted] = useState(0);

    useEffect(() => {
        const fetchSalesPersonDetails = async () => {
            try {
                const salesPersonResponse = await axios.get(`${API}/salesperson/${id}`, {
                    headers: {Authorization: `Bearer ${token}`},
                });
                setSalesPerson(salesPersonResponse.data.data);
            } catch (error) {
                console.error("Error fetching salesperson details", error);
            }
        };

        fetchSalesPersonDetails();
    }, [id, token]);

    useEffect(() => {
        const fetchSalesInProgress = async () => {
            try {
                const response = await axios.get(`${API}/sales/table/inprogress/${id}?page=${pageInProgress}&limit=10`, {
                    headers: {Authorization: `Bearer ${token}`},
                });
                setSalesInProgress(response.data.data);
                setTotalPagesInProgress(response.data.totalPages);
            } catch (error) {
                console.error("Error fetching sales in progress", error);
            }
        };

        fetchSalesInProgress();
    }, [id, token, pageInProgress]);

    useEffect(() => {
        const fetchSalesCompleted = async () => {
            try {
                const response = await axios.get(`${API}/sales/table/completed/${id}?page=${pageCompleted}&limit=10`, {
                    headers: {Authorization: `Bearer ${token}`},
                });
                setSalesCompleted(response.data.data);
                setTotalPagesCompleted(response.data.totalPages);
            } catch (error) {
                console.error("Error fetching completed sales", error);
            }
        };

        fetchSalesCompleted();
    }, [id, token, pageCompleted]);

    const renderInProgress = () => {
        if (!salesInProgress || salesInProgress.length === 0) {
            return (
                <tr>
                    <td colSpan="5" className="text-center">No data found</td>
                </tr>
            );
        } else {
            return salesInProgress.map((sales, index) => (
                <tr key={index} className="text-center">
                    <td className="p-1 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{index + 1}</td>
                    <td className="p-1 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{sales.client.firstName} {sales.client.lastName}</td>
                    <td className="p-1 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{sales.progressStatus}</td>
                    <td className="p-1 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{sales.stock.brand} {sales.stock.carName}</td>
                </tr>
            ));
        }
    };

    const renderPaginationInProgress = () => {
        if (!salesInProgress || salesInProgress.length === 0 || totalPagesInProgress <= 1) return null;

        const pages = [];
        for (let i = 1; i <= totalPagesInProgress; i++) {
            pages.push(
                <a
                    key={i}
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setPageInProgress(i);
                    }}
                    className={`mx-1 px-4 py-2 border rounded-md ${pageInProgress === i ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
                >
                    {i}
                </a>
            );
        }
        return <div className="flex justify-center">{pages}</div>;
    };


    const renderCompleted = () => {
        if (!salesCompleted || salesCompleted.length === 0) {
            return (
                <tr>
                    <td colSpan="5" className="text-center">No completed sales found</td>
                </tr>
            );
        } else {
            return salesCompleted.map((sales, index) => (
                <tr key={index}>
                    <td className="p-3 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{index + 1}</td>
                    <td className="p-1 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{sales.client.firstName} {sales.client.lastName}</td>
                    <td className="p-1 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{sales.stock.brand} {sales.stock.carName}</td>
                    <td className="p-1 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{new Date(sales.stock.stockOutDate).toLocaleDateString()}</td>
                    <td className="p-1 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">RM{sales.stock.value}</td>
                </tr>
            ));
        }
    };

    const renderPaginationCompleted = () => {
        if (!salesCompleted || salesCompleted.length === 0 || totalPagesCompleted <= 1) return null;

        const pages = [];
        for (let i = 1; i <= totalPagesCompleted; i++) {
            pages.push(
                <a
                    key={i}
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setPageCompleted(i);
                    }}
                    className={`mx-1 px-4 py-2 border rounded-md ${pageCompleted === i ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
                >
                    {i}
                </a>
            );
        }
        return <div className="flex justify-center">{pages}</div>;
    };

    if (!salesPerson) {
        return <Layout>Loading...</Layout>;
    }

    return (
        <Layout>
            <main
                className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl bg-white transition-all duration-200">
                <div className="w-full px-6 py-6 mx-auto">
                    <h3 className="text-black font-bold">{salesPerson.firstName}</h3>
                    <nav
                        className="text-black relative flex flex-wrap items-center justify-between py-2 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
                        navbar-main="" navbar-scroll="false">
                        <div className="flex items-center justify-between w-full py-1 mx-auto flex-wrap-inherit">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div className="">
                                            <Link to="/dashboard"
                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700">
                                                <span className="">Dashboard</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center px-4">
                                            <svg className="h-5 w-5 flex-shrink-0 text-gray-400 pt-1"
                                                 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd"
                                                      d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                      clipRule="evenodd"/>
                                            </svg>
                                            <Link to="/salesperson-chart"
                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700">
                                                <span className="">Salesperson performance</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center">
                                            <svg className="h-5 w-5 flex-shrink-0 text-gray-400 pt-1"
                                                 viewBox="0 0 20 20" fill="
currentColor" aria-hidden="true">
                                                <path fillRule="evenodd"
                                                      d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                      clipRule="evenodd"/>
                                            </svg>
                                            <a href="#"
                                               className="text-sm font-medium text-gray-500 hover:text-gray-700 pl-2">
                                                {salesPerson.firstName}
                                            </a>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </nav>
                    <div className="flex flex-wrap mt-6 -mx-3">
                        <div className="w-full max-w-full px-3 lg:w-1/3 lg:flex-none">
                            <div
                                className="relative h-full flex flex-col min-w-0 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                                <div
                                    className="px-4 mt-12 border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                                    <div className="text-center">
                                        <div className="flex justify-center">
                                            <div className="mx-6 text-center">
                                                {salesPerson.profileImage ? (
                                                    <img className="h-28 w-28 border-2 border-white border-solid"
                                                         src={salesPerson.profileImage} alt="profile image"/>
                                                ) : (
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '112px',
                                                        height: '112px',
                                                        borderRadius: '0%',
                                                        backgroundColor: '#f3f3f3',
                                                        color: '#ccc',
                                                        border: '2px solid #eee',
                                                        fontSize: '1rem'
                                                    }}>
                                                        <span>No Image</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <p className="text-sm font-bold text-black pt-4">
                                            {salesPerson.firstName} {salesPerson.lastName}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex-auto px-0 pb-2">
                                    <div className="py-2 pl-6 pr-3 text-left text-sm font-semibold text-gray-900">
                                        Salesperson Profile
                                    </div>
                                    <div className="px-6">
                                        <div>
                                            <label className="text-sm">Gender</label>
                                            <p className="text-xs text-black font-bold">{salesPerson.gender}</p>
                                        </div>
                                        <div>
                                            <label className="text-sm">Date of Birth </label>
                                            <p className="text-xs text-black font-bold">{new Date(salesPerson.dateOfBirth).toLocaleDateString()}</p>
                                        </div>
                                        <div>
                                            <label className="text-sm">Address </label>
                                            <p className="text-xs text-black font-bold">
                                                {`${salesPerson.address.street}, ${salesPerson.address.city}, ${salesPerson.address.state}, ${salesPerson.address.zipCode}`}
                                            </p>
                                        </div>
                                        <div>
                                            <label className="text-sm">Phone No. </label>
                                            <p className="text-xs text-black font-bold">{salesPerson.phoneNumber}</p>
                                        </div>
                                        <div>
                                            <label className="text-sm">Email </label>
                                            <p className="text-xs text-black font-bold">{salesPerson.email}</p>
                                        </div>
                                        <div>
                                            <label className="text-sm">Branch</label>
                                            <p className="text-xs text-black font-bold">{salesPerson.branch}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full max-w-full px-3 mt-0 lg:w-2/3 lg:flex-none">
                            <div
                                className="relative flex flex-col min-w-0 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                                <div
                                    className="p-4 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                                    <div className="flex flex-wrap -mx-3">
                                        <div className="flex items-center flex-none w-1/2 max-w-full px-3">
                                            <h5 className="mb-0 dark:text-white">Progress</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-auto px-0 pt-0 pb-2">
                                    <div className="p-0 overflow-x-auto">
                                        <table
                                            className="items-center w-full align-top border-collapse dark:border-white/40 text-slate-500">
                                            <thead>
                                            <tr className="border">
                                                <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                    No
                                                </th>
                                                <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                    Name
                                                </th>
                                                <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                    Status
                                                </th>
                                                <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                    Car
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {renderInProgress()}
                                            </tbody>
                                        </table>
                                        <div
                                            className="flex items-center justify-center border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                                            {renderPaginationInProgress()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="mt-4 border-black/12.5 dark:bg-slate-850 dark:shadow-dark-xl shadow-xl relative z-20 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                                <div
                                    className="border-black/12.5 mb-0 rounded-t-2xl border-b-0 border-solid p-6 pt-4 pb-0">
                                    <h5 className="capitalize dark:text-white">Sold</h5>
                                </div>
                                <div className="flex-auto px-0 pt-2 pb-2">
                                    <div className="p-0 overflow-x-auto">
                                        <table
                                            className="items-center w-full mb-0 align-top border-collapse dark:border-white/40 text-slate-500 border rounded-lg">
                                            <thead>
                                            <tr className="text-center">
                                                <th className="px-1 py-3 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70"></th>
                                                <th className="px-1 py-3 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                    Name
                                                </th>
                                                <th className="px-1 py-3 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                    Car
                                                </th>
                                                <th className="px-1 py-3 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                    Keys handover
                                                </th>
                                                <th className="px-1 py-3 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                    Value
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="text-xxs text-center font-bold">
                                            {renderCompleted()}
                                            </tbody>
                                        </table>
                                        <div
                                            className="flex items-center justify-center border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                                            {renderPaginationCompleted()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default SalesPersonDetail;
