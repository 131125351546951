import React, {useState, useEffect} from "react";
import Layout from "../../component/layout";
import {Link, useHistory, useParams} from "react-router-dom";
import {getCookie} from "../../action/authAction";
import {API} from "../../config/config";
import axios from "axios";

const StockFormPage = () => {
    const token = getCookie('token');
    const history = useHistory();

    const [stockData, setStockData] = useState({
        carName: "",
        plateNo: "",
        chassisNo: "",
        type: "",
        brand: "",
        value: 0,
        discountedValue: 0,
        stockInDate: "",
        stockOutDate: "",
        stockLocation : "",
    });

    const handleChange = (e) => {
        const {name, value} = e.target;

        setStockData({...stockData, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post(`${API}/stock/new`, stockData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            alert("Stock added successfully");
            history.replace("/stock-detail");
        } catch (error) {
            console.error("Error adding stock:", error);
            alert("Failed to add stock");
        }
    };

    return (
        <Layout>
            <main
                className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl bg-white transition-all duration-200">
                {/* cards */}
                <div className="w-full px-6 py-6 mx-auto">
                    <h3 className="text-black font-bold">Stock Form</h3>
                    {/* Navbar */}
                    <nav
                        className="text-black relative flex flex-wrap items-center justify-between py-2 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
                        navbar-main=""
                        navbar-scroll="false"
                    >
                        <div className="flex items-center justify-between w-full py-1 mx-auto flex-wrap-inherit">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div className="">
                                            <Link to="/stock-detail"

                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Stock</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center px-4">
                                            <svg
                                                className="h-5 w-5 flex-shrink-0 text-gray-400 pt-1"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            <Link to="/stock-form"

                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Stock Form</span>
                                            </Link>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </nav>
                    {/* Navbar */}
                    {/* end Navbar */}

                    <div className="flex flex-wrap -mx-3">
                        <div className="flex-none w-full max-w-full px-3">
                            <div className="w-full max-w-full px-3 shrink-0 md:flex-0">
                                <div
                                    className="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                                    <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                                        <div
                                            className="w-full max-w-full px-3 mx-auto mt-4 sm:my-auto md:flex-none lg:w-7/12">
                                            <div className="flex justify-center items-center">
                                                <ul
                                                    className="flex flex-wrap p-1 list-none bg-gray-50 rounded-xl"
                                                    nav-pills=""
                                                    role="tablist"
                                                >
                                                    <li className="z-30 flex-auto text-center">
                                                        <a
                                                            className="z-30 flex items-center justify-center w-full px-0 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg bg-inherit text-slate-700"
                                                            nav-link=""
                                                            active=""
                                                            role="tab"
                                                            aria-selected="true"
                                                        >
                                                            <span className="font-semibold ml-2">
                                                                Add Stock
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <form onSubmit={handleSubmit}>

                                        <div className="flex-auto p-6">

                                            <div className="flex flex-wrap -mx-3">
                                                <div
                                                    className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <label htmlFor="brand"
                                                           className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">Brand*</label>
                                                    <select
                                                        className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                        name="brand"
                                                        value={stockData.brand}
                                                        onChange={handleChange} required
                                                    >
                                                        <option value="">Select Brand</option>
                                                        <option value="Aston Martin">Aston Martin</option>
                                                        <option value="Bentley">Bentley</option>
                                                        <option value="BMW">BMW</option>
                                                        <option value="Bugatti">Bugatti</option>
                                                        <option value="Ferrari">Ferrari</option>
                                                        <option value="Lamborghini">Lamborghini</option>
                                                        <option value="Land Rover">Land Rover</option>
                                                        <option value="Maserati">Maserati</option>
                                                        <option value="Mercedes">Mercedes</option>
                                                        <option value="Porsche">Porsche</option>
                                                        <option value="Rolls-Royce">Rolls-Royce</option>
                                                        <option value="Tesla">Tesla</option>
                                                        <option value="Toyota">Toyota</option>

                                                    </select>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="carName"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">Car
                                                            Name*</label>
                                                        <input type="text" id="carName" name="carName"
                                                               value={stockData.carName}
                                                               onChange={handleChange} required
                                                               className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="chassisNo"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">Chassis
                                                            No*</label>
                                                        <input type="text" id="chassisNo" name="chassisNo"
                                                               value={stockData.chassisNo}
                                                               onChange={handleChange} required
                                                               className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex flex-wrap -mx-3">
                                                <div
                                                    className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <label htmlFor="type"
                                                           className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">Car
                                                        Type*</label>
                                                    <select
                                                        className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                        name="type"
                                                        value={stockData.type}
                                                        onChange={handleChange} required
                                                    >
                                                        <option value="">Select Car Type</option>
                                                        <option value="Hatchback">Hatchback</option>
                                                        <option value="Sedan">Sedan</option>
                                                        <option value="SUV">SUV</option>
                                                        <option value="MUV">MUV</option>
                                                        <option value="Coupe">Coupe</option>
                                                        <option value="Convertable">Convertable</option>
                                                        <option value="Pickup Truck">Pickup Truck</option>
                                                        <option value="Sports Car">Sports Car</option>
                                                    </select>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="value"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">Car
                                                            Value* (MYR)</label>
                                                        <input type="tel" id="value" name="value"
                                                               value={stockData.value}
                                                               onChange={handleChange} required
                                                               className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="plateNo"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">Plate
                                                            No</label>
                                                        <input type="text" id="plateNo" name="plateNo"
                                                               value={stockData.plateNo}
                                                               onChange={handleChange}
                                                               className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex flex-wrap -mx-3">
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <label htmlFor="stockLocation"
                                                           className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">Stock
                                                        Location*</label>
                                                    <select
                                                        className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                        name="stockLocation"
                                                        value={stockData.stockLocation}
                                                        onChange={handleChange} required
                                                    >
                                                        <option value="">Select Location</option>
                                                        <option value="Kuala Lumpur">Kuala Lumpur</option>
                                                        <option value="Kuantan">Kuantan</option>
                                                    </select>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="stockInDate"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            Stock In Date*
                                                        </label>
                                                        <input
                                                            type="date"
                                                            name="stockInDate"
                                                            value={stockData.stockInDate}
                                                            onChange={handleChange} required
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 mr-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="stockOutDate"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            Stock Out Date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            name="stockOutDate"
                                                            value={stockData.stockOutDate}
                                                            onChange={handleChange}
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 mr-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex flex-row items-end justify-end w-full">
                                                <button
                                                    type="submit"
                                                    className="px-8 mt-4 py-2 mb-4 mr-4 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-green-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                >
                                                    <i className="fa fa-check" aria-hidden="true"/> Save
                                                </button>
                                            </div>

                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
}
export default StockFormPage;
