import React, {useState, useEffect} from "react";
import {useHistory, Link, useParams} from "react-router-dom";
import axios from 'axios';
import {API} from "../../config/config";
import $ from 'jquery';

import '../assets/css/nucleo-icons.css';
import '../assets/css/nucleo-svg.css';
import '../assets/css/soft-ui-dashboard-tailwind.css';
import Layout from '../../component/layout';
import {getCookie} from "../../action/authAction";
import CarGallery from "./car-gallery";
import CarSpecification from "./car-specification";
import CarSales from "./car-sales";


const StockPicPage = () => {
    let {id} = useParams(); // This 'id' matches the ':id' in your route path

    // You can now use this 'id' to fetch stock details or images as needed
    console.log(id); // For demonstration

    // Assuming you want to populate these fields
    const [carName, setCarName] = useState("");
    const [plateNo, setPlateNo] = useState("");
    const [type, setType] = useState("");
    const [brand, setBrand] = useState("");
    const [fuelType, setFuelType] = useState("");
    const [color, setColor] = useState("");
    const [seating, setSeating] = useState("");
    const [mileage, setMileage] = useState("");
    const [yearMake, setYearMake] = useState("");
    const [spareKey, setSpareKey] = useState("");
    const [warranty, setWarranty] = useState("");
    const [chassisNo, setChassisNo] = useState("");

    const [activeTab, setActiveTab] = useState("1"); // Default to the first tab
    const [activeSecondTab, setActiveSecondTab] = useState("0");

    const token = getCookie('token');

    const fetchStockDetails = async () => {
        try {
            const response = await axios.get(`${API}/stock/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // Assuming the API response has a data field containing the stock details
            const stock = response.data.data;

            console.log(stock);

            // Update form fields
            setBrand(stock.brand || '');
            setCarName(stock.carName || '');
            setType(stock.type || '');
            setPlateNo(stock.plateNo || '');
            setFuelType(stock.specs.fuelType || '');
            setColor(stock.specs.color || '');
            setSeating(stock.specs.seatNo || '');
            setMileage(stock.specs.mileage || '');
            setYearMake(stock.specs.yearMake || '');
            setSpareKey(stock.specs.spareKey || '');
            setWarranty(stock.specs.warranty || '');
            setChassisNo(stock.chassisNo || '');


        } catch (error) {
            console.error("Error fetching stock details:", error);
        }
    };


    const saveChanges = async () => {
        try {
            const payload = {
                specs: {
                    fuelType, color, seatNo: Number(seating), mileage, yearMake, spareKey, warranty,
                }, brand, carName, plateNo, type, chassisNo,
            };

            await axios.patch(`${API}/stock/${id}`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            // Handle success here, e.g., showing a success message
            alert('Stock details updated successfully');

            fetchStockDetails();
        } catch (error) {
            // Handle error here, e.g., showing an error message
            console.error("Error saving changes:", error);
            alert('Failed to update stock details');
        }
    }

    useEffect(() => {
        fetchStockDetails();
    }, [id, token]); // This effect depends on the `id`, `token`

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    }

    const handleSecondTabClick = (tabIndex) => {
        setActiveSecondTab(tabIndex);
    }

    /**
     const activeTabClasses = ['border-l', 'border-t', 'border-r', 'rounded-t', 'text-blue-700', 'font-semibold', 'active'];
     const inactiveTabClasses = ['text-blue-500', 'hover:text-blue-800', 'font-semibold'];

     function toggleTabs(activeIndex) {
     $('.tab-buttons > li > button').each(function (k, v) {
     let current = $(v);
     if (current.hasClass('active')) {
     current.removeClass(activeTabClasses);
     current.addClass(inactiveTabClasses);
     current.parent().removeClass('-mb-px');
     } else {
     current.parent().addClass('-mb-px');
     }
     });
     $('.tab-buttons > li > button[data-tab-index=' + activeIndex + ']').each(function (k, v) {
     $(v).removeClass(inactiveTabClasses);
     $(v).addClass(activeTabClasses);
     });
     }

     $('.tab-item').on('click', function (e) {
     let sender = e.currentTarget;
     if (sender && !sender.classList.contains('disabled')) {
     let index = sender.attributes['data-tab-index'].value;
     if (index !== undefined) {
     toggleTabs(index);
     $('.tab-contents > li').each(function (k, v) {
     let currIndex = v.attributes['data-tab-content-index'].value;
     if (currIndex == index) {
     $(v).removeClass('hidden');
     } else {
     $(v).addClass('hidden');
     }
     });
     }
     return false;
     }
     });

     **/


    return (
        <Layout>
            <main
                className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200">
                {/* Navbar */}
                <div className="w-full px-6 py-6 mx-auto">
                    <h3 className="text-black font-bold">{brand} {carName}</h3>
                    <nav
                        className="text-black relative flex flex-wrap items-center justify-between py-2 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
                        navbar-main=""
                        navbar-scroll="false"
                    >
                        <div className="flex items-center justify-between w-full py-1 mx-auto flex-wrap-inherit">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div className="">
                                            <Link to="/dashboard"
                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Dashboard</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center px-4">
                                            <svg
                                                className="h-5 w-5 flex-shrink-0 text-gray-400 pt-1"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            <Link to="/stock-detail"
                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Stock</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center px-4">
                                            <svg
                                                className="h-5 w-5 flex-shrink-0 text-gray-400 pt-1"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            <a className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700">
                                                <span className="">Car's Gallery</span>
                                            </a>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </nav>
                    {/* end Navbar */}

                    <div class="container border rounded m-6 pb-6 tab-component bg-white w-full">
                        <ul class="flex border-b tab-buttons">
                            <li className="mr-1">
                                <button onClick={() => handleTabClick("0")}
                                        className={`tab-item bg-white inline-block py-2 px-4 text-blue-500 hover:text-blue-800 font-semibold ${activeTab === "0" ? 'border-l border-t border-r rounded-t text-blue-700' : ''}`}>Gallery
                                </button>
                            </li>
                            <li className="mr-1">
                                <button onClick={() => handleTabClick("1")}
                                        className={`tab-item bg-white inline-block py-2 px-4 text-blue-500 hover:text-blue-800 font-semibold ${activeTab === "1" ? 'border-l border-t border-r rounded-t text-blue-700' : ''}`}>Details
                                </button>
                            </li>
                            <li className="mr-1">
                                <button onClick={() => handleTabClick("2")}
                                        className={`tab-item bg-white inline-block py-2 px-4 text-blue-500 hover:text-blue-800 font-semibold ${activeTab === "2" ? 'border-l border-t border-r rounded-t text-blue-700' : ''}`}>Sales
                                </button>
                            </li>

                        </ul>
                        <ul class="flex tab-contents pb-6 pl-6 pr-6 py-6">
                            {activeTab === "0" && (
                                < CarGallery/>
                            )}
                            {activeTab === "1" && (
                                <li>
                                    <div className="flex justify-center items-center">
                                        <ul
                                            className="flex flex-wrap p-1 list-none bg-gray-50 rounded-xl"
                                            nav-pills=""
                                            role="tablist"
                                        >
                                            <li className="z-30 flex-auto text-center">
                                                <button onClick={() => handleSecondTabClick("0")}
                                                        className={`tab-item bg-transparent inline-block py-2 px-4 text-blue-500 hover:text-blue-800 font-semibold ${activeSecondTab === "0" ? 'border-l border-t border-r rounded-t text-blue-700' : ''}`}>1.
                                                    Car Overview
                                                </button>
                                            </li>
                                            <li className="z-30 flex-auto text-center">
                                                <button onClick={() => handleSecondTabClick("1")}
                                                        className={`tab-item bg-transparent inline-block py-2 px-4 text-blue-500 hover:text-blue-800 font-semibold ${activeSecondTab === "1" ? 'border-l border-t border-r rounded-t text-blue-700' : ''}`}>2.
                                                    Car Specification
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    {activeSecondTab === "0" && (
                                        <div>
                                            <div className="z-30 p-6 flex-auto">

                                                <div className="flex flex-wrap -mx-3">
                                                    <div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label
                                                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                            >
                                                                Brand
                                                            </label>
                                                            <select
                                                                className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                id="brand" // Changed the 'name' to 'id' for consistency with the label value={seating} // Set the value to the state variable onChange={(e)=> setSeating(e.target.value)} // Update the state on change
                                                                value={brand}
                                                                onChange={(e) => setBrand(e.target.value)}
                                                            >
                                                                <option value="">Select Brand</option>
                                                                <option value="Aston Martin">Aston Martin</option>
                                                                <option value="Bentley">Bentley</option>
                                                                <option value="BMW">BMW</option>
                                                                <option value="Bugatti">Bugatti</option>
                                                                <option value="Ferrari">Ferrari</option>
                                                                <option value="Lamborghini">Lamborghini</option>
                                                                <option value="Land Rover">Land Rover</option>
                                                                <option value="Maserati">Maserati</option>
                                                                <option value="Mercedes">Mercedes</option>
                                                                <option value="Porsche">Porsche</option>
                                                                <option value="Rolls-Royce">Rolls-Royce</option>
                                                                <option value="Tesla">Tesla</option>
                                                                <option value="Toyota">Toyota</option>
                                                            </select>
                                                        </div>

                                                    </div>

                                                    <div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label
                                                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                                Car Name
                                                            </label>
                                                            <input placeholder="Car Name" type="text" name="carName"
                                                                   className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                   value={carName}
                                                                   onChange={(e) => setCarName(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label
                                                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                            >
                                                                Type
                                                            </label>
                                                            <select
                                                                className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                id="type" // Changed the 'name' to 'id' for consistency with the label value={seating} // Set the value to the state variable onChange={(e)=> setSeating(e.target.value)} // Update the state on change
                                                                value={type}
                                                                onChange={(e) => setType(e.target.value)}
                                                            >
                                                                <option value="">Select Car Type</option>
                                                                <option value="Hatchback">Hatchback</option>
                                                                <option value="Sedan">Sedan</option>
                                                                <option value="SUV">SUV</option>
                                                                <option value="MUV">MUV</option>
                                                                <option value="Coupe">Coupe</option>
                                                                <option value="Convertable">Convertable</option>
                                                                <option value="Pickup Truck">Pickup Truck</option>
                                                                <option value="Sports Car">Sports Car</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label
                                                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                                Plate No
                                                            </label>
                                                            <input placeholder="Plate No" type="text" name="plateNo"
                                                                   className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                   value={plateNo}
                                                                   onChange={(e) => setPlateNo(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label
                                                                htmlFor="name"
                                                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                            >
                                                                Fuel Type
                                                            </label>
                                                            <select
                                                                className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                id="fuelType" // Changed the 'name' to 'id' for consistency with the label value={seating} // Set the value to the state variable onChange={(e)=> setSeating(e.target.value)} // Update the state on change
                                                                value={fuelType}
                                                                onChange={(e) => setFuelType(e.target.value)}
                                                            >
                                                                <option value="">Select Fuel Type</option>
                                                                <option value="Petrol">Petrol</option>
                                                                <option value="Diesel">Diesel</option>
                                                                <option value="Hydrogen">Hydrogen</option>
                                                                <option value="Electric Vehicle">Electric Vehicle
                                                                </option>
                                                            </select>
                                                        </div>

                                                    </div>


                                                    <div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label htmlFor="address"
                                                                   className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                                Car Color
                                                            </label>
                                                            <input placeholder="Color" type="text" name="address"
                                                                   className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                   value={color}
                                                                   onChange={(e) => setColor(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label htmlFor="seating"
                                                                   className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                                Seating
                                                            </label>
                                                            <select
                                                                className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                id="seating" // Changed the 'name' to 'id' for consistency with the label value={seating} // Set the value to the state variable onChange={(e)=> setSeating(e.target.value)} // Update the state on change
                                                                value={seating}
                                                                onChange={(e) => setSeating(e.target.value)}
                                                            >
                                                                <option value="">Select No. Of Seat</option>
                                                                <option value="2">2</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label htmlFor="parliament"
                                                                   className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                                Current Mileage
                                                            </label>
                                                            <input placeholder="Current Mileage" type="text"
                                                                   name="datepicker"
                                                                   className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                   value={mileage}
                                                                   onChange={(e) => setMileage(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>


                                                    <div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label htmlFor="datepicker"
                                                                   className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">

                                                                Register Year
                                                            </label>
                                                            <input placeholder="2019" type="text"
                                                                   name="datepicker"
                                                                   className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                   value={yearMake}
                                                                   onChange={(e) => setYearMake(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label htmlFor="datepicker"
                                                                   className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                                Chassis No
                                                            </label>
                                                            <input placeholder="Private" type="text"
                                                                   name="datepicker"
                                                                   className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                   value={chassisNo}
                                                                   onChange={(e) => setChassisNo(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label htmlFor="datepicker"
                                                                   className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                                Spare Key
                                                            </label>
                                                            <select
                                                                className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                id="sparekey" // Changed the 'name' to 'id' for consistency with the label value={spareKey} // Set the value to the state variable onChange={(e)=> setSpareKey(e.target.value)} // Update the state on change
                                                                value={spareKey}
                                                                onChange={(e) => setSpareKey(e.target.value)}
                                                            >
                                                                <option value="">Select Availability</option>
                                                                <option value="Available">Available</option>
                                                                <option value="Not Available">Not Available</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label htmlFor="datepicker"
                                                                   className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                                Manufacturer's Warranty
                                                            </label>
                                                            <select
                                                                className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                                id="warranty" // Changed the 'name' to 'id' for consistency with the label value={warranty} // Set the value to the state variable onChange={(e)=> setWarranty(e.target.value)} // Update the state on change
                                                                value={warranty}
                                                                onChange={(e) => setWarranty(e.target.value)}
                                                            >
                                                                <option value="">Select Availability</option>
                                                                <option value="Available">Available</option>
                                                                <option value="Not Available">Not Available</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-row items-end justify-end w-full">
                                                    {/*<button
                                                                type="button"
                                                                className="px-8 py-2 mb-4 mr-4 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                            >
                                                                <i className="fa fa-pen" aria-hidden="true"/> Edit
                                                            </button>*/}
                                                    <button
                                                        type="button"
                                                        onClick={saveChanges} // Here we attach the event handler
                                                        className="px-8 py-2 mb-4 mr-4 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-green-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                    >
                                                        <i className="fa fa-check" aria-hidden="true"/> Save
                                                    </button>
                                                    {/*<button
                                                                type="button"
                                                                className="px-8 py-2 mb-4 mr-4 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-orange-400 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                            >
                                                                <i className="fa fa-arrow-right"
                                                                   aria-hidden="true"/>{" "}
                                                                Next
                                                            </button>*/}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {activeSecondTab === "1" && (
                                        < CarSpecification/>
                                    )}
                                </li>
                            )}
                            {activeTab === "2" && (
                                < CarSales/>
                            )}
                        </ul>
                    </div>
                </div>
            </main>
        </Layout>);
}

export default StockPicPage;

