import React, {useState, useEffect} from "react";
import Layout from "../../component/layout";
import {Link, useHistory, useParams} from "react-router-dom";
import {getCookie} from "../../action/authAction";
import {API} from "../../config/config";
import axios from "axios";

import Select from 'react-select';

const RoadtaxInsuranceForm = () => {
    const token = getCookie('token');
    const history = useHistory();
    const {id} = useParams();

    const [data, setData] = useState({
        client: null,
        stock: null,
        roadtaxExpiry: '',
        insuranceExpiry: ''
    });

    const [clientSuggestions, setClientSuggestions] = useState([]);
    const [searchText, setSearchText] = useState(""); // To keep track of the search input
    const [stockOptions, setStockOptions] = useState([]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value});

        if (name === "clientSearch") {
            setSearchText(value);
            searchClients(value);
        }
    };

    const searchClients = async (searchText) => {
        if (!searchText.trim()) {
            setClientSuggestions([]);
            return;
        }

        try {
            const response = await axios.get(`${API}/sales/client/search`, {
                params: {searchText},
                headers: {Authorization: `Bearer ${token}`},
            });
            setClientSuggestions(response.data.clients);
        } catch (error) {
            console.error("Error searching clients:", error);
        }
    };

    const handleSelectClient = (client) => {
        setData(prevState => ({
            ...prevState,
            client: client
        }));
        setClientSuggestions([]);
        setSearchText("");
        fetchStocks(client._id); // Fetch stocks available for this client
    };

    const fetchStocks = async (clientId) => {
        try {
            const response = await axios.get(`${API}/sales/stock/search/available`, {
                params: {clientId},
                headers: {Authorization: `Bearer ${token}`},
            });
            console.log("Stocks fetched:", response.data.stocks);
            const availableStocks = response.data.stocks;
            if (availableStocks.length > 0) {
                const firstStock = availableStocks[0];
                console.log(firstStock);
                handleSelectStock(firstStock);
            }
            setStockOptions(availableStocks);
        } catch (error) {
            console.error("Error fetching stocks:", error);
            setStockOptions([]);
        }
    };

    const handleSelectStock = (stock) => {
        setData(prevState => ({
            ...prevState,
            stock: stock,
            roadtaxExpiry: '',
            insuranceExpiry: ''
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!data.stock || !data.roadtaxExpiry || !data.insuranceExpiry) {
            alert("Please fill all required fields.");
            return;
        }

        console.log(data.stock._id);

        try {
            const response = await axios.put(`${API}/stock/update/roadtax-insurance/${data.stock._id}`, {
                roadtaxExpiry: data.roadtaxExpiry,
                insuranceExpiry: data.insuranceExpiry
            }, {
                headers: {Authorization: `Bearer ${token}`},
            });

            alert("Roadtax and Insurance updated successfully!");
            history.push("/roadtax-insurance-list");
        } catch (error) {
            console.error("Error updating stock:", error);
            alert("Failed to update roadtax and insurance.");
        }
    };

    const selectStyles = {
        control: styles => ({...styles, backgroundColor: 'white'}),
        option: (styles, {isFocused}) => {
            return {
                ...styles,
                cursor: 'pointer',
                backgroundColor: isFocused ? 'lightgray' : 'white',
                color: 'black',
            };
        }
    };

    return (
        <Layout>

            <main
                className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl bg-white transition-all duration-200">
                {/* cards */}
                <div className="w-full px-6 py-6 mx-auto">
                    <h3 className="text-black font-bold">Roadtax/Insurance Form</h3>
                    {/* Navbar */}
                    <nav
                        className="text-black relative flex flex-wrap items-center justify-between py-2 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
                        navbar-main=""
                        navbar-scroll="false"
                    >
                        <div className="flex items-center justify-between w-full py-1 mx-auto flex-wrap-inherit">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div className="">
                                            <Link to="/roadtax-insurance-list"

                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Roadtax/Insurance</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center px-4">
                                            <svg
                                                className="h-5 w-5 flex-shrink-0 text-gray-400 pt-1"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            <Link to="/roadtax-insurance/form"

                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Roadtax/Insurance Form</span>
                                            </Link>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </nav>
                    {/* Navbar */}
                    {/* end Navbar */}
                    <div className="flex flex-wrap -mx-3">
                        <div className="flex-none w-full max-w-full px-3">
                            <div className="w-full max-w-full px-3 shrink-0 md:flex-0">
                                <div
                                    className="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                                    <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                                        <div
                                            className="w-full max-w-full px-3 mx-auto mt-4 sm:my-auto md:flex-none lg:w-7/12">
                                            <div className="flex justify-center items-center">
                                                <ul
                                                    className="flex flex-wrap p-1 list-none bg-gray-50 rounded-xl"
                                                    nav-pills=""
                                                    role="tablist"
                                                >
                                                    <li className="z-30 flex-auto text-center">
                                                        <a
                                                            className="z-30 flex items-center justify-center w-full px-0 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg bg-inherit text-slate-700"
                                                            nav-link=""
                                                            active=""
                                                            role="tab"
                                                            aria-selected="true"
                                                        >
                                                            <span className="font-semibold ml-2">
                                                                {(id) ? 'Edit Roadtax/Insurance' : 'Add Roadtax/Insurance'}
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <form onSubmit={handleSubmit}>
                                        <div className="flex-auto p-6">

                                            <div className="flex flex-wrap -mx-3">
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="clientSearch" className="font-bold text-xs">Search
                                                            Client (NRIC/Passport/Name)*</label>
                                                        <input type="text" id="clientSearch" name="clientSearch"
                                                               value={searchText} onChange={handleChange}
                                                               className="appearance-none rounded border p-2 text-grey-darker"/>
                                                        {clientSuggestions.length > 0 && (
                                                            <ul className="absolute z-10 max-h-48 w-full overflow-auto bg-white border border-gray-300 mt-1 rounded-md shadow-lg">
                                                                {clientSuggestions.map(suggestion => (
                                                                    <li key={suggestion._id}
                                                                        onClick={() => handleSelectClient(suggestion)}>
                                                                        {suggestion.nric} - {suggestion.firstName} {suggestion.lastName}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                        {data.client && (
                                                            <div>
                                                                <label className="font-bold">Selected Client:</label>
                                                                <ul>
                                                                    <li>
                                                                        {data.client.nric} - {data.client.firstName} {data.client.lastName}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="mb-4">
                                                    <label htmlFor="stock"
                                                           className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                        Select Stock*
                                                    </label>
                                                    {stockOptions.length > 0 ? (
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            isSearchable={true}
                                                            name="stock"
                                                            options={stockOptions.map(stock => ({
                                                                label: `${stock.plateNo} - ${stock.carName}`,
                                                                value: stock._id
                                                            }))}
                                                            value={stockOptions.length > 0 ? {
                                                                label: `${data.stock.plateNo} - ${data.stock.carName}`,
                                                                value: data.stock._id
                                                            } : null}
                                                            onChange={(selectedOption) => {
                                                                handleSelectStock(stockOptions.find(stock => stock._id === selectedOption.value));
                                                            }}
                                                            styles={selectStyles}
                                                        />
                                                    ) : <p>No available stock found.</p>}
                                                </div>

                                            </div>

                                            {/* Roadtax and Insurance Expiry Date Selection */}
                                            {data.stock && (
                                                <>
                                                    <div className="mb-4">
                                                        <label htmlFor="roadtaxExpiry" className="font-bold text-xs">Roadtax
                                                            Expiry Date:</label>
                                                        <input type="date" id="roadtaxExpiry" name="roadtaxExpiry"
                                                               value={data.roadtaxExpiry} onChange={handleChange}
                                                               className="appearance-none rounded border p-2 text-grey-darker"/>
                                                    </div>
                                                    <div className="mb-4">
                                                        <label htmlFor="insuranceExpiry" className="font-bold text-xs">Insurance
                                                            Expiry Date:</label>
                                                        <input type="date" id="insuranceExpiry" name="insuranceExpiry"
                                                               value={data.insuranceExpiry} onChange={handleChange}
                                                               className="appearance-none rounded border p-2 text-grey-darker"/>
                                                    </div>
                                                </>
                                            )}

                                            <div className="flex flex-row items-end justify-end w-full">
                                                <button
                                                    type="submit"
                                                    className="px-8 mt-4 py-2 mb-4 mr-4 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-green-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                >
                                                    <i className="fa fa-check" aria-hidden="true"/> Save
                                                </button>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </Layout>
    );
}

export default RoadtaxInsuranceForm;
