import React, {useState, useEffect} from "react";
import {useHistory, Link} from "react-router-dom";

import '../assets/css/nucleo-icons.css';
import '../assets/css/nucleo-svg.css';
import '../assets/css/soft-ui-dashboard-tailwind.css';
import Layout from "../../component/layout";
import {getCookie} from "../../action/authAction";
import axios from "axios";
import {API} from "../../config/config";

const ClientListPage = () => {
    const token = getCookie('token');

    const [clients, setClients] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalClients, setTotalClients] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchType, setSearchType] = useState('client');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedClientDetails, setSelectedClientDetails] = useState(null);

    const [currentModal, setCurrentModal] = useState('details');
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    useEffect(() => {
        const fetchAllClient = async () => {
            try {
                const response = await axios.get(`${API}/client/table/all?page=${page}&limit=10&searchQuery=${searchQuery}&searchType=${searchType}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log(response.data.data);
                setClients(response.data.data);
                setTotalPages(response.data.totalPages);
                setTotalClients(response.data.total);
            } catch (error) {
                console.error('Error fetching clients in data:', error);
            }
        };

        fetchAllClient();
    }, [page, searchQuery, token]);

    // Function to open modal and fetch client details
    const openModal = async (clientId, modalType = 'details') => {
        console.log(`client id : ${clientId}`);
        try {
            const response = await axios.get(`${API}/client/details/${clientId}`, {
                headers: {Authorization: `Bearer ${token}`},
            });

            console.log(response.data.data);
            setSelectedClientDetails(response.data.data);
            setIsModalOpen(true);
            setCurrentModal(modalType);
            console.log(isModalOpen);
        } catch (error) {
            console.error('Error fetching client details:', error);
        }
    };

    const renderClients = () => {
        if (!clients || clients.length === 0) {
            return <tr>
                <td colSpan="5">No client found</td>
            </tr>;
        } else {
            return clients.map((client, index) => (<tr key={index} className="text-center">
                <td className="p-2 align-middle bg-white border-b whitespace-nowrap shadow-transparent">
                    <div className="flex px-4 py-1">
                        <p className="mb-0 text-xs font-semibold leading-tight">
                            {index + 1}
                        </p>
                    </div>
                </td>
                <td className="p-2 align-middle bg-white border-b whitespace-nowrap shadow-transparent">
                    <div className="flex px-2 py-1">
                        <div className="flex flex-col justify-center">
                            <h6 className="mb-0 text-sm leading-normal">
                                {client.firstName} {client.lastName}
                            </h6>
                            <p className="mb-0 text-xs leading-tight text-slate-400">
                                {client.email}
                            </p>
                        </div>
                    </div>
                </td>
                <td className="p-2 text-center align-middle bg-white border-b whitespace-nowrap shadow-transparent">
                    {client.sales && client.sales.length > 0 ? (client.sales.map((sale, index) => (<div key={index}>
                                    <span
                                        className="text-xs font-semibold leading-tight text-slate-400">{sale.stock ? `${sale.stock.brand} ${sale.stock.carName}` : '-'}</span>
                    </div>))) : (
                        <span className="text-xs font-semibold leading-tight text-slate-400">-</span>)}
                </td>
                <td className="p-2 text-center align-middle bg-white border-b whitespace-nowrap shadow-transparent">
                        <span className="text-xs font-semibold leading-tight text-slate-400">
                          {client.phoneNumber}
                        </span>
                </td>
                <td className="p-2 align-middle bg-white border-b whitespace-nowrap shadow-transparent">
                    <button
                        onClick={() => openModal(client.id)}
                        className="modal-open bg-white border border-gray-300 hover:border-indigo-500 text-gray-500 hover:text-indigo-500 font-semibold py-1 px-4 rounded-full">
                        View
                    </button>
                </td>
            </tr>));
        }
    };

    const renderPagination = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <a
                    key={i}
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setPage(i);
                    }}
                    className={`mx-1 px-4 py-2 border rounded-md ${page === i ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
                >
                    {i}
                </a>
            );
        }
        return (
            <div className="flex justify-center mt-4">
                {pages}
            </div>
        );
    };

    // Function to close modal
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedClientDetails(null);
    };

    function detailsModal() {
        return (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0,0,0,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000
            }}>
                <div style={{
                    background: 'white',
                    padding: '20px',
                    borderRadius: '5px',
                    width: '50%', // Adjust width as necessary
                    maxWidth: '600px', // Adjust max-width as necessary
                    overflowY: 'auto' // Add scroll for content overflow
                }}>

                    {/* Dynamically populate modal content here */}
                    {selectedClientDetails && (
                        <>
                            <div className="relative mb-4">
                                {selectedClientDetails.profileImage ? (
                                    <img
                                        className="rounded-full w-30 h-30 pb-4 border border-gray-100 shadow-md"
                                        src={selectedClientDetails.profileImage}
                                        alt="user image"
                                    />
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '120px',
                                            height: '120px',
                                            borderRadius: '0%',
                                            backgroundColor: '#f3f3f3',
                                            color: '#ccc',
                                            border: '2px solid #eee',
                                        }}
                                    >
                                        {/* You can place text or icon here */}
                                        <span>No Image</span>
                                    </div>
                                )}
                            </div>
                            <p><strong>Full
                                Name:</strong> {selectedClientDetails.firstName} {selectedClientDetails.lastName}
                            </p>
                            <p><strong>NRIC/Passport:</strong> {selectedClientDetails.nric}
                            </p>
                            <p><strong>Date Of
                                Birth:</strong> {new Date(selectedClientDetails.dateOfBirth).toLocaleDateString()}
                            </p>
                            <p><strong>Anniversary
                                Date:</strong> {new Date(selectedClientDetails.anniversaryDate).toLocaleDateString()}
                            </p>
                            <p><strong>Gender:</strong> {selectedClientDetails.gender}</p>
                            <p><strong>Email:</strong> {selectedClientDetails.email}</p>
                            <p><strong>Phone Number:</strong> {selectedClientDetails.phoneNumber}</p>
                            <p>
                                <strong>Address:</strong> {`${selectedClientDetails.address.street}, ${selectedClientDetails.address.city}, ${selectedClientDetails.address.state}, ${selectedClientDetails.address.zipCode}`}
                            </p>


                            {/* Add more details as needed */}

                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '20px' // Give some space from the content below
                            }}>
                                {/* Close Button */}
                                <button onClick={closeModal} style={{
                                    alignSelf: 'flex-start',
                                    marginTop: '20px',
                                    background: 'indigo',
                                    color: 'white',
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                    border: 'none',
                                    cursor: 'pointer'
                                }}>Close
                                </button>


                                {/* Switch to Car Details Button */}
                                {selectedClientDetails.sales && selectedClientDetails.sales.some(sale => sale.stock) && (
                                    <button
                                        onClick={() => setCurrentModal('carDetails')}
                                        style={{
                                            alignSelf: 'flex-end',
                                            padding: '5px 10px',
                                            marginRight: '10px', // Space from the right edge
                                            marginTop: '10px', // Space from the top edge
                                            background: 'lightgrey',
                                            color: 'black',
                                            borderRadius: '5px',
                                            border: 'none',
                                            cursor: 'pointer'
                                        }}>
                                        Switch to Car Details
                                    </button>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }

    function detailsCarModal() {
        return (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0,0,0,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000
            }}>
                <div style={{
                    background: 'white',
                    padding: '20px',
                    borderRadius: '5px',
                    width: '50%', // Adjust width as necessary
                    maxWidth: '600px', // Adjust max-width as necessary
                    overflowY: 'auto' // Add scroll for content overflow
                }}>
                    {/* Dynamically populate modal content here */}
                    {selectedClientDetails && selectedClientDetails.sales && selectedClientDetails.sales.length > 0 && (
                        <>
                            {/* Tabs for each car */}
                            <div className="tabs">
                                {selectedClientDetails.sales.map((sale, index) => (
                                    <button
                                        key={index}
                                        style={{
                                            padding: '10px',
                                            margin: '5px',
                                            borderRadius: '5%',
                                            background: selectedTabIndex === index ? 'lightgray' : 'transparent',
                                            borderBottom: selectedTabIndex === index ? '2px solid blue' : 'none',
                                        }}
                                        onClick={() => setSelectedTabIndex(index)}
                                    >
                                        {sale.stock.carName}
                                    </button>
                                ))}
                            </div>
                            <div className="tab-content">
                                {selectedClientDetails.sales[selectedTabIndex] && selectedClientDetails.sales[selectedTabIndex].stock && selectedClientDetails.sales[selectedTabIndex].stock.gallery && selectedClientDetails.sales[selectedTabIndex].stock.gallery.overall ? (
                                    <img
                                        className="rounded-full w-30 h-30 my-4 border border-gray-100 shadow-md"
                                        src={selectedClientDetails.sales[selectedTabIndex].stock.gallery.overall}
                                        alt="user image"
                                    />
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '120px',
                                            height: '120px',
                                            borderRadius: '50%',
                                            backgroundColor: '#f3f3f3',
                                            color: '#ccc',
                                            border: '2px solid #eee',
                                        }}
                                    >
                                        {/* You can place text or icon here */}
                                        <span>No Image</span>
                                    </div>
                                )}
                                <p>
                                    <strong>Car:</strong> {selectedClientDetails.sales[selectedTabIndex].stock.brand} {selectedClientDetails.sales[selectedTabIndex].stock.carName}
                                </p>
                                <p><strong>Plate
                                    No:</strong> {selectedClientDetails.sales[selectedTabIndex].stock.plateNo}
                                </p>
                                <p><strong>Chassis
                                    No:</strong> {selectedClientDetails.sales[selectedTabIndex].stock.chassisNo}
                                </p>
                                <p><strong>Road Tax Expiry
                                    Date:</strong> {selectedClientDetails.sales[selectedTabIndex].stock.roadtaxExpiry ? new Date(selectedClientDetails.sales[selectedTabIndex].stock.roadtaxExpiry).toLocaleDateString() : 'No Data'}
                                </p>
                                <p><strong>Insurance Expiry
                                    Date:</strong> {selectedClientDetails.sales[selectedTabIndex].stock.insuranceExpiry ? new Date(selectedClientDetails.sales[selectedTabIndex].stock.insuranceExpiry).toLocaleDateString() : 'No Data'}
                                </p>
                                {/* Add more details as needed */}
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '20px' // Give some space from the content below
                            }}>
                                {/* Close Button */}
                                <button onClick={closeModal} style={{
                                    alignSelf: 'flex-start',
                                    marginTop: '20px',
                                    background: 'indigo',
                                    color: 'white',
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                    border: 'none',
                                    cursor: 'pointer'
                                }}>Close
                                </button>

                                {/* Switch to Client Details Button */}
                                <button
                                    onClick={() => setCurrentModal('details')}
                                    style={{
                                        alignSelf: 'flex-end',
                                        padding: '5px 10px',
                                        marginRight: '10px', // Space from the right edge
                                        marginTop: '10px', // Space from the top edge
                                        background: 'lightgrey',
                                        color: 'black',
                                        borderRadius: '5px',
                                        border: 'none',
                                        cursor: 'pointer'
                                    }}>
                                    Switch to Client Details
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }


    return (
        <Layout>

            {/* Modal component */}
            {isModalOpen && (currentModal === 'details' ? detailsModal() : detailsCarModal())}

            <main
                className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl bg-white transition-all duration-200">
                {/* Navbar */}
                <div className="w-full px-6 py-3 mx-auto">
                    <h3 className="text-black font-bold">Client Profile (All)</h3>
                    {/* Navbar */}
                    <nav
                        className="text-black relative flex flex-wrap items-center justify-between py-2 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
                        navbar-main=""
                        navbar-scroll="false"
                    >
                        <div className="flex items-center justify-between w-full py-1 mx-auto flex-wrap-inherit">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div className="">
                                            <Link to="/dashboard"
                                                  href="../dashboard.html"
                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Dashboard</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center px-4">
                                            <svg
                                                className="h-5 w-5 flex-shrink-0 text-gray-400 pt-1"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            <a className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700">
                                                <span className="">Client Profile</span>
                                            </a>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </nav>
                    {/* end Navbar */}
                    <div className="w-full px-6 py-6 mx-auto">
                        {/* table 1 */}
                        <div className="flex flex-wrap -mx-3">

                            <Link to={`/client-form`}>
                                <button
                                    type="button"
                                    class="mb-4 inline-flex rounded-lg border border-transparent bg-green-500 px-3 py-1 text-xs font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="2.5"
                                        stroke="currentColor"
                                        class="w-6 h-6"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M12 6v12m6-6H6"
                                        />
                                    </svg>

                                    <div class="">
                                        <a
                                            href="../announcement/announcement-form.html"
                                            class="ml-2 text-sm font-bold text-white hover:text-white"
                                        >
                                            <span class="">Add Client</span>
                                        </a>
                                    </div>
                                </button>
                            </Link>

                            <div
                                className="relative flex flex-wrap items-stretch w-15 transition-all rounded-lg ease ml-auto mr-10">
                                <span
                                    className="text-sm ease leading-5.6 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none border border-r-0 border-transparent bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                                  <i className="fas fa-search"/>
                                </span>
                                <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="pl-10 text-xs focus:shadow-primary-outline ease w-1/100 leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 dark:bg-slate-850 dark:text-white bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none focus:transition-shadow"
                                    placeholder="Search By Client Name"
                                />
                            </div>

                            <div className="flex-none w-full max-w-full px-3">
                                <div
                                    className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-md rounded-2xl bg-clip-border">
                                    <div
                                        className="relative flex flex-row min-w-0 mb-6 break-words bg-transparent border-0 border-transparent border-solid shadow-soft-md rounded-2xl bg-clip-border">
                                        <Link to="/client-list">
                                            <div
                                                className="p-6 pb-3 bg-blue-500 text-white font-bold border-b-0 border-b-solid rounded-2xl border-b-transparent">
                                                <a>All Client</a>
                                            </div>
                                        </Link>
                                        <Link to="/client-birthday-list">
                                            <div
                                                className="p-6 pb-0 mb-0 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                                                <a>Birthday</a>
                                            </div>
                                        </Link>
                                        <Link to="/client-anniversary-list">
                                            <div
                                                className="p-6 pb-0 mb-0 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                                                <a>Anniversary</a>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="flex-auto px-0 pt-0 pb-2">
                                        <div className="p-0 overflow-x-auto ">
                                            <table
                                                className="items-center w-full mb-0 align-top text-slate-500">
                                                <thead className="align-bottom">
                                                <tr>
                                                    <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-white border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                        No
                                                    </th>
                                                    <th className="px-6 py-3 pl-5 font-bold text-left uppercase align-middle bg-white border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                        Client Name
                                                    </th>
                                                    <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-white border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                        Car
                                                    </th>
                                                    <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-white border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                        Phone Number
                                                    </th>
                                                    <th className="px-6 py-3 font-semibold capitalize align-middle bg-white border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70"/>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {renderClients()}
                                                </tbody>
                                            </table>
                                            <div
                                                className="flex items-center justify-center border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                                                <div className="flex justify-center w-full">
                                                    {renderPagination()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default ClientListPage;
