import React, {useState, useEffect} from "react";
import Layout from "../../component/layout";
import {Link, useParams} from "react-router-dom";
import {getCookie} from "../../action/authAction";
import {API} from "../../config/config";
import axios from "axios";

const CarSales = () => {
    let {id} = useParams();
    console.log(id);
    const token = getCookie('token');

    const [value, setValue] = useState(0);
    const [discountedValue, setDiscountedValue] = useState(0);
    const [stockLocation, setStockLocation] = useState("");

    const fetchStockDetails = async () => {
        try {
            const response = await axios.get(`${API}/stock/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // Assuming the API response has a data field containing the stock details
            const stock = response.data.data;

            console.log(stock);

            setValue(stock.value || 0);
            setDiscountedValue(stock.discountedValue || 0);
            setStockLocation(stock.stockLocation || '');


        } catch (error) {
            console.error("Error fetching stock details:", error);
        }
    };

    useEffect(() => {
        fetchStockDetails();
    }, [id, token]); // This effect depends on the `id`, `token`

    const saveChanges = async () => {
        try {
            const payload = {
                value, discountedValue, stockLocation
            };

            await axios.patch(`${API}/stock/${id}`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            // Handle success here, e.g., showing a success message
            alert('Stock details updated successfully');

            fetchStockDetails();
        } catch (error) {
            // Handle error here, e.g., showing an error message
            console.error("Error saving changes:", error);
            alert('Failed to update stock details');
        }
    }

    return (
        <div className="z-30 p-6 flex-auto">
            <div className="flex flex-wrap -mx-3">
                <div className="flex flex-wrap -mx-3">
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label htmlFor="value"
                                   className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                Value* (MYR)
                            </label>
                            <input placeholder="Value" type="text" name="value"
                                   className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                   value={value}
                                   onChange={(e) => setValue(Number(e.target.value))}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label htmlFor="discountedValue"
                                   className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                Discounted Value (MYR)
                            </label>
                            <input placeholder="Discounted Value" type="text"
                                   name="discountedValue"
                                   className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                   value={discountedValue}
                                   onChange={(e) => setDiscountedValue(Number(e.target.value))}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label htmlFor="stockLocation"
                                   className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                Stock Location*
                            </label>
                            <select
                                className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                id="seating" // Changed the 'name' to 'id' for consistency with the label value={seating} // Set the value to the state variable onChange={(e)=> setSeating(e.target.value)} // Update the state on change
                                value={stockLocation}
                                onChange={(e) => setStockLocation(e.target.value)}
                            >
                                <option value="">Select Stock Location</option>
                                <option value="Kuala Lumpur">Kuala Lumpur</option>
                                <option value="Kuantan">Kuantan</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-row items-end justify-end w-full">
                <button
                    type="button"
                    onClick={saveChanges} // Here we attach the event handler
                    className="px-8 py-2 mb-4 mr-4 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-green-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                >
                    <i className="fa fa-check" aria-hidden="true"/> Save
                </button>
            </div>
        </div>


    );
}
export default CarSales;
