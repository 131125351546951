import React, {useState, useEffect} from "react";
import {useHistory, Link} from "react-router-dom";
import axios from 'axios';
import {API} from "../../config/config";

import '../assets/css/nucleo-icons.css';
import '../assets/css/nucleo-svg.css';
import '../assets/css/soft-ui-dashboard-tailwind.css';
import Layout from '../../component/layout';

import {getCookie} from "../../action/authAction";

const StockDetailsPage = () => {
    const [stocksIn, setStocksIn] = useState([]);
    const [stocksOut, setStocksOut] = useState([]);
    const [pageIn, setPageIn] = useState(1);
    const [pageOut, setPageOut] = useState(1);
    const [totalPagesIn, setTotalPagesIn] = useState(0);
    const [totalPagesOut, setTotalPagesOut] = useState(0);
    const [totalStockIn, setTotalStockIn] = useState(0);
    const [totalStockOut, setTotalStockOut] = useState(0);
    const [searchQueryIn, setSearchQueryIn] = useState('');
    const [searchQueryOut, setSearchQueryOut] = useState('');

    const token = getCookie('token');

    useEffect(() => {
        // Fetch Stock In
        const fetchStockIn = async () => {
            try {
                const response = await axios.get(`${API}/stock/table/all`, {
                    params: {
                        page: pageIn,
                        limit: 10,
                        search: searchQueryIn
                    },
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setStocksIn(response.data.data);
                setTotalPagesIn(response.data.totalPages);
                setTotalStockIn(response.data.total);
            } catch (error) {
                console.error('Error fetching stock in data:', error);
            }
        };

        // Fetch Stock Out
        const fetchStockOut = async () => {
            try {
                const response = await axios.get(`${API}/stock/table/out`, {
                    params: {
                        page: pageOut,
                        limit: 10,
                        search: searchQueryOut
                    },
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setStocksOut(response.data.data);
                setTotalPagesOut(response.data.totalPages);
                setTotalStockOut(response.data.total);
            } catch (error) {
                console.error('Error fetching stock out data:', error);
            }
        };

        fetchStockIn();
        fetchStockOut();
    }, [pageIn, pageOut, searchQueryIn, searchQueryOut, token]);

    const renderStocks = () => {
        if (!stocksIn || stocksIn.length === 0) {
            return <tr>
                <td colSpan="5">No stocks found</td>
            </tr>;
        } else {
            return stocksIn.map((stock, index) => (
                <tr key={index} className="text-center">
                    <td className="p-3 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{index + 1}</td>
                    <td className="p-1 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{stock.brand} {stock.carName}</td>
                    <td className="p-1 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{stock.chassisNo}</td>
                    <td className="p-1 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{new Date(stock.stockInDate).toLocaleDateString()}</td>
                    <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap"><Link
                        to={`/stock-pic/${stock._id}`}>Details</Link></td>
                </tr>
            ));
        }
    };

    const renderStocksOut = () => {
        if (!stocksOut || stocksOut.length === 0) {
            return <tr>
                <td colSpan="5">No stocks found</td>
            </tr>;
        } else {
            return stocksOut.map((stock, index) => (
                <tr key={index} className="text-center">
                    <td className="p-3 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{index + 1}</td>
                    <td className="p-1 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{stock.brand} {stock.carName}</td>
                    <td className="p-1 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{stock.chassisNo}</td>
                    <td className="p-1 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">{new Date(stock.stockOutDate).toLocaleDateString()}</td>
                    {/* <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap"><Link
                        to={`/stock-pic/${stock._id}`}>Details</Link></td> */}
                </tr>
            ));
        }
    };

    const renderPagination = () => {
        const pages = [];
        for (let i = 1; i <= totalPagesIn; i++) {
            pages.push(
                <a
                    key={i}
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setPageIn(i);
                    }}
                    className={`mx-1 px-4 py-2 border rounded-md ${pageIn === i ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
                >
                    {i}
                </a>
            );
        }
        return pages;
    };

    const renderPaginationOut = () => {
        const pages = [];
        for (let i = 1; i <= totalPagesOut; i++) {
            pages.push(
                <a
                    key={i}
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setPageOut(i);
                    }}
                    className={`mx-1 px-4 py-2 border rounded-md ${pageOut === i ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
                >
                    {i}
                </a>
            );
        }
        return pages;
    };

    return (
        <Layout>
            <main
                className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl bg-white transition-all duration-200">
                <div className="w-full px-16 py-3 mx-auto">
                </div>
                <h3 className="text-black px-4 font-bold">
                    Stock
                </h3>
                <nav
                    className="text-black relative flex flex-wrap items-center justify-between py-2 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
                    navbar-main="true"
                    navbar-scroll="false"
                >
                    <div
                        className="flex items-center justify-between w-full pb-6 mx-auto flex-wrap-inherit"
                    >
                        <nav className="flex" aria-label="Breadcrumb">
                            <ol role="list" className="flex items-center space-x-4">
                                <li>
                                    <div className="">
                                        <Link to="/dashboard"
                                              href="../dashboard.html"
                                              className="px-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                        >
                                            <span className="">Dashboard</span>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center px-4">
                                        <svg
                                            className="h-5 w-5 flex-shrink-0 text-gray-400 pt-1"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <Link to="/stock-detail"

                                              className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                        >
                                            <span className="">Stock</span>
                                        </Link>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </nav>
                <div className="flex flex-wrap pb-32 mt-0 -mx-6">
                    <div
                        className="mt-0 border-black/12.5 dark:bg-slate-850 dark:shadow-dark-xl shadow-xl lg:w-1/2 lg:flex-none relative z-20 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                        <div
                            className="border-black/12.5 mb-0 rounded-t-2xl border-b-0 border-solid p-6 pt-4 pb-0 flex items-center justify-between">
                            <div>
                                <h6 className="capitalize dark:text-white ml-1">Stock in</h6>
                                <h6 className="capitalize dark:text-white">
                                    <span
                                        className="leading-normal text-xl mx-1 font-weight-bolder text-lime-500">{totalStockIn}</span>
                                    Total Stock in
                                </h6>
                            </div>
                            <div className="flex flex-col items-end space-y-2">
                                <Link to={`/stock-form`}>
                                    <button
                                        type="button"
                                        className="inline-flex items-center rounded-lg border border-transparent bg-green-500 px-3 py-1 text-xs font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2.5"
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M12 6v12m6-6H6"
                                            />
                                        </svg>
                                        <span className="ml-2 text-sm font-bold text-white">Add Stock</span>
                                    </button>
                                </Link>
                                <div className="relative flex items-center mt-2">
                                    <span
                                        className="text-sm leading-5.6 absolute z-50 flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none border border-r-0 border-transparent bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                                        <i className="fas fa-search"></i>
                                    </span>
                                    <input
                                        type="text"
                                        className="pl-12 text-xs focus:shadow-primary-outline ease w-full leading-5.6 relative block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 dark:bg-slate-850 dark:text-white bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none focus:transition-shadow"
                                        placeholder="Search by Chassis No."
                                        value={searchQueryIn}
                                        onChange={(e) => setSearchQueryIn(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex-auto px-2 pt-2 pb-10">
                            <div className="p-0 overflow-x-auto">
                                <table
                                    className="items-center w-full mb-0 align-top border-collapse dark:border-white/40 text-slate-500 border rounded-lg">
                                    <thead>
                                    <tr className="text-center">
                                        <th className="px-1 py-3 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70"></th>
                                        <th className="px-1 py-3 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Car</th>
                                        <th className="px-1 py-3 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Chassis
                                            No.
                                        </th>
                                        <th className="px-1 py-3 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Date</th>
                                        <th className="px-1 py-3 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Details</th>
                                    </tr>
                                    </thead>
                                    <tbody className="text-xxs text-center font-bold">
                                    {renderStocks()}
                                    </tbody>
                                </table>
                            </div>
                            <div className="py-3 flex justify-center items-center">
                                {renderPagination()}
                            </div>
                        </div>
                    </div>

                    <div className="w-full max-w-full px-3 lg:w-1/2 lg:flex-none">
                        <div
                            className="relative h-full flex flex-col min-w-0 break-words lg:w-9/10 lg:flex-none bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                            <div className="border-black/12.5 mb-0 rounded-t-2xl border-b-0 border-solid p-6 pt-4 pb-0">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <h6 className="capitalize dark:text-white ml-1">Stock out</h6>
                                        <h6 className="capitalize dark:text-white">
                                            <span
                                                className="leading-normal text-xl font-weight-bolder mx-1 text-lime-500">{totalStockOut}</span>
                                            Total Stock Out
                                        </h6>
                                    </div>
                                    <div className="flex items-center">
                                        <div
                                            className="relative flex items-stretch w-full transition-all rounded-lg ease mr- mt-11">
                                            <span
                                                className="text-sm leading-5.6 absolute z-50 flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none border border-r-0 border-transparent bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                                                <i className="fas fa-search"></i>
                                            </span>
                                            <input
                                                type="text"
                                                className="pl-12 text-xs focus:shadow-primary-outline ease w-full leading-5.6 relative block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 dark:bg-slate-850 dark:text-white bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none focus:transition-shadow"
                                                placeholder="Search by Chassis No."
                                                value={searchQueryOut}
                                                onChange={(e) => setSearchQueryOut(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-auto px-2 pt-2 pb-2">
                                <div className="p-0 overflow-x-auto">
                                    <table
                                        className="items-center w-full mb-0 align-top border-collapse dark:border-white/40 text-slate-500 border rounded-lg"
                                    >
                                        <thead>
                                        <tr className="text-center">
                                            <th
                                                className="px-1 py-3 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70"
                                            >

                                            </th>
                                            <th
                                                className="px-1 py-3 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70"
                                            >
                                                Car
                                            </th>
                                            <th
                                                className="px-1 py-3 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70"
                                            >
                                                Chassis No.
                                            </th>
                                            <th
                                                className="px-1 py-3 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70"
                                            >
                                                Date
                                            </th>
                                            {/* <th
                                                    className="px-4 py-3 font-bold uppercase bg-transparent border-b border-collapse shadow-none bg-gray-200 text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70"
                                                >
                                                    Details
                                                </th> */}

                                        </tr>
                                        </thead>
                                        <tbody className="text-xxs text-center font-bold">
                                        {renderStocksOut()}
                                        </tbody>
                                    </table>
                                    <div className="py-3 flex justify-center items-center">
                                        {renderPaginationOut()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default StockDetailsPage;
