import React, {useState, useEffect} from "react";
import Layout from "../../component/layout";
import {Link, useParams} from "react-router-dom";
import {getCookie} from "../../action/authAction";
import {API} from "../../config/config";
import axios from "axios";
import {useHistory} from "react-router-dom";

const AnnouncementFormPage = () => {
    const token = getCookie("token");

    const history = useHistory();

    const {id} = useParams();

    // State for file, preview URL, and form data
    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [announcementData, setAnnouncementData] = useState({
        picture: "",
        title: "",
        content: "",
        tag: "",
        periodStart: "",
        periodEnd: "",
        activeStatus: true,
    });

    const fetchAnnouncementDetails = async () => {
        try {
            const response = await axios.get(`${API}/announcement/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const announcement = response.data.data;
            setAnnouncementData({
                ...announcement,
                periodStart: announcement.periodStart.split("T")[0], // Format date if necessary
                periodEnd: announcement.periodEnd.split("T")[0],
            });
            setPreviewUrl(announcement.picture);
        } catch (error) {
            console.error("Error fetching announcement details:", error);
        }
    };

    useEffect(() => {
        // If an ID is present, fetch the announcement details
        if (id) {
            fetchAnnouncementDetails();
        }
    }, [id, token]);

    // Handle file change and generate preview URL
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    // Handle form field changes
    const handleChange = (e) => {
        setAnnouncementData({...announcementData, [e.target.name]: e.target.value});
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare formData for file upload
        const formData = new FormData();
        formData.append("file", file);
        Object.keys(announcementData).forEach(key => {
            formData.append(key, announcementData[key]);
        });

        try {
            if (id) {
                await axios.put(`${API}/announcement/edit/${id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    }
                });
                alert("Announcement updated successfully");
            } else {
                await axios.post(`${API}/announcement/new`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                alert("Announcement added successfully");
            }
            history.replace("/announcement"); // Adjust the route as needed
        } catch (error) {
            console.error("Error adding announcement:", error);
            alert("Failed to add announcement");
        }
    };


    return (
        <Layout>
            <main
                className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl bg-white transition-all duration-200">
                {/* cards */}
                <div className="w-full px-6 py-6 mx-auto">
                    <h3 className="text-black font-bold">Announcements</h3>
                    {/* Navbar */}
                    <nav
                        className="text-black relative flex flex-wrap items-center justify-between py-2 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
                        navbar-main=""
                        navbar-scroll="false"
                    >
                        <div className="flex items-center justify-between w-full py-1 mx-auto flex-wrap-inherit">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div className="">
                                            <Link to="/announcement"

                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Announcement</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center px-4">
                                            <svg
                                                className="h-5 w-5 flex-shrink-0 text-gray-400 pt-1"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            <Link to="/announcement-form"

                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Announcement Form</span>
                                            </Link>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </nav>
                    {/* Navbar */}
                    {/* end Navbar */}
                    <div className="flex flex-wrap -mx-3">
                        <div className="flex-none w-full max-w-full px-3">
                            <div className="w-full max-w-full px-3 shrink-0 md:flex-0">
                                <div
                                    className="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                                    <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                                        <div
                                            className="w-full max-w-full px-3 mx-auto mt-4 sm:my-auto md:flex-none lg:w-7/12">
                                            <div className="flex justify-center items-center">
                                                <ul
                                                    className="flex flex-wrap p-1 list-none bg-gray-50 rounded-xl"
                                                    nav-pills=""
                                                    role="tablist"
                                                >
                                                    <li className="z-30 flex-auto text-center">
                                                        <a
                                                            className="z-30 flex items-center justify-center w-full px-0 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg bg-inherit text-slate-700"
                                                            nav-link=""
                                                            active=""
                                                            role="tab"
                                                            aria-selected="true"
                                                        >
                        <span className="font-semibold ml-2">
                          {(id) ? 'Edit Announcement' : 'Add Announcement'}
                        </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="flex-auto p-6">
                                            <div className="flex flex-wrap -mx-3">
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="title"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            Title
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="title"
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            value={announcementData.title}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap -mx-3">
                                                <div
                                                    className="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0"></div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="tag"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            Tagging
                                                        </label>
                                                        <select
                                                            className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            name="tag"
                                                            value={announcementData.tag}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="Announcement">Announcement</option>
                                                            <option value="Promotion">Promotion</option>
                                                            <option value="News">News</option>
                                                            <option value="Urgent">Urgent</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="periodStart"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            Period Start
                                                        </label>
                                                        <input
                                                            type="date"
                                                            name="periodStart"
                                                            value={announcementData.periodStart}
                                                            onChange={handleChange}
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 mr-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <label htmlFor="periodEnd"
                                                           className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                        Period End
                                                    </label>
                                                    <input
                                                        type="date"
                                                        name="periodEnd"
                                                        value={announcementData.periodEnd}
                                                        onChange={handleChange}
                                                        className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="content"
                                                       className="block mb-2 font-bold text-xs text-slate-700">
                                                    Content
                                                </label>
                                                <textarea
                                                    name="content"
                                                    value={announcementData.content}
                                                    onChange={handleChange}
                                                    rows="5"
                                                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                                                    required
                                                ></textarea>
                                            </div>
                                            <div className="flex flex-row w-full">
                                                {previewUrl ? (
                                                    <div
                                                        className="mt-4 p-2 border rounded-lg bg-white shadow-sm relative"
                                                        style={{maxWidth: "50%", margin: "auto"}}>
                                                        <img src={previewUrl} alt="Preview"
                                                             style={{width: "100%", height: "auto"}}/>
                                                        <label htmlFor="file-upload"
                                                               className="absolute top-2 right-2 cursor-pointer bg-blue-500 text-white p-1 rounded-md">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 className="h-4 w-4 inline mr-1" fill="none"
                                                                 viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                      d="M5 15l7-7 7 7"/>
                                                            </svg>
                                                            Change
                                                        </label>
                                                    </div>
                                                ) : (
                                                    <label
                                                        className="flex flex-col items-center justify-center w-full h-64 bg-gray-100 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-gray-200">
                                                        <div className="text-center">
                                                            <p className="mt-2 text-sm text-gray-600">
                                                            <span
                                                                className="font-semibold">Click to upload a picture</span>
                                                            </p>
                                                        </div>
                                                        <input type='file' className="hidden"
                                                               onChange={handleFileChange}
                                                               accept="image/*"/>
                                                    </label>
                                                )}

                                                {/* Always available input field for re-selecting/choosing file */}
                                                <input
                                                    id="file-upload"
                                                    name="file"
                                                    type="file"
                                                    onChange={handleFileChange}
                                                    accept="image/jpeg, image/png, image/jpg"
                                                    className="hidden"
                                                />
                                            </div>
                                            <div className="flex flex-row items-end justify-end w-full">
                                                {/*<button
                                                type="button"
                                                className="px-8 py-2 mb-4 mr-4 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                            >
                                                <i className="fa fa-pen" aria-hidden="true"/> Edit
                                            </button>*/}
                                                <button
                                                    type="submit"
                                                    className="px-8 mt-4 py-2 mb-4 mr-4 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-green-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                >
                                                    <i className="fa fa-check" aria-hidden="true"/> Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end cards */}
            </main>

        </Layout>
    );
}

export default AnnouncementFormPage;
