import React, {useState, useEffect} from "react";
import {useHistory, Link} from "react-router-dom";

import '../assets/css/nucleo-icons.css';
import '../assets/css/nucleo-svg.css';
import '../assets/css/soft-ui-dashboard-tailwind.css';
import Layout from "../../component/layout";
import {getCookie} from "../../action/authAction";
import axios from "axios";
import {API} from "../../config/config";

const ClientAnniversaryPage = () => {
    const token = getCookie('token');

    const [clients, setClients] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalClients, setTotalClients] = useState(0);

    // State to manage modal visibility and selected client details
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedClientDetails, setSelectedClientDetails] = useState(null);

    const [currentModal, setCurrentModal] = useState('details');
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const [selectedMonth, setSelectedMonth] = useState(null);

    useEffect(() => {
        const fetchAllClient = async () => {
            const query = selectedMonth ? `&month=${selectedMonth}` : '';
            try {
                const response = await axios.get(`${API}/client/table/anniversary?page=${page}&limit=10${query}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log(response.data.data);
                setClients(response.data.data);
                setTotalPages(response.data.totalPages);
                setTotalClients(response.data.total);
            } catch (error) {
                console.error('Error fetching clients in data:', error);
            }
        };

        fetchAllClient();
    }, [page, token, selectedMonth]);

    // Function to open modal and fetch client details
    const openModal = async (clientId, modalType = 'details') => {
        console.log(`client id : ${clientId}`);
        try {
            const response = await axios.get(`${API}/client/details/${clientId}`, {
                headers: {Authorization: `Bearer ${token}`},
            });

            console.log(response.data.data);
            setSelectedClientDetails(response.data.data);
            setIsModalOpen(true);
            setCurrentModal(modalType);
            console.log(isModalOpen);
        } catch (error) {
            console.error('Error fetching client details:', error);
        }
    };

    const renderClients = () => {
        if (!clients || clients.length === 0) {
            return <tr>
                <td colSpan="5">No client found</td>
            </tr>;
        } else {
            return clients.map((client, index) => (<tr key={index} className="text-center">
                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                    <div className="flex px-4 py-1">
                        <p className="mb-0 text-xs font-semibold leading-tight">
                            {index + 1}
                        </p>
                    </div>
                </td>
                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                    <div className="flex px-2 py-1">
                        <div className="flex flex-col justify-center">
                            <h6 className="mb-0 text-sm leading-normal">
                                {client.firstName} {client.lastName}
                            </h6>
                            <p className="mb-0 text-xs leading-tight text-slate-400">
                                {client.email}
                            </p>
                        </div>
                    </div>
                </td>
                <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                    <span
                        className="text-xs font-semibold leading-tight text-slate-400">{new Date(client.anniversaryDate).toLocaleDateString()}</span>
                </td>
                <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                        <span className="text-xs font-semibold leading-tight text-slate-400">
                          {client.phoneNumber}
                        </span>
                </td>
                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                    <button
                        onClick={() => openModal(client.id)}
                        className="modal-open bg-transparent border border-gray-300 hover:border-indigo-500 text-gray-500 hover:text-indigo-500 font-semibold py-1 px-4 rounded-full">
                        View
                    </button>
                </td>
            </tr>));
        }
    };

    const renderPagination = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(<a
                key={i}
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    setPage(i);
                }}
                className={`mx-1 px-4 py-2 border rounded-md ${page === i ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
            >
                {i}
            </a>);
        }
        return pages;
    };

    // Function to close modal
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedClientDetails(null);
    };

    function detailsModal() {
        return (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0,0,0,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000
            }}>
                <div style={{
                    background: 'white',
                    padding: '20px',
                    borderRadius: '5px',
                    width: '50%', // Adjust width as necessary
                    maxWidth: '600px', // Adjust max-width as necessary
                    overflowY: 'auto' // Add scroll for content overflow
                }}>

                    {/* Dynamically populate modal content here */}
                    {selectedClientDetails && (
                        <>
                            <div className="relative">
                                {selectedClientDetails.profileImage ? (
                                    <img
                                        className="rounded-full w-30 h-30 border border-gray-100 shadow-sm"
                                        src={selectedClientDetails.profileImage}
                                        alt="user image"
                                    />
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '120px',
                                            height: '120px',
                                            borderRadius: '50%',
                                            backgroundColor: '#f3f3f3',
                                            color: '#ccc',
                                            border: '2px solid #eee',
                                        }}
                                    >
                                        {/* You can place text or icon here */}
                                        <span>No Image</span>
                                    </div>
                                )}
                            </div>
                            <p><strong>Full
                                Name:</strong> {selectedClientDetails.firstName} {selectedClientDetails.lastName}
                            </p>
                            <p><strong>NRIC/Passport:</strong> {selectedClientDetails.nric}
                            </p>
                            <p><strong>Date Of
                                Birth:</strong> {new Date(selectedClientDetails.dateOfBirth).toLocaleDateString()}
                            </p>
                            <p><strong>Anniversary
                                Date:</strong> {new Date(selectedClientDetails.anniversaryDate).toLocaleDateString()}
                            </p>
                            <p><strong>Gender:</strong> {selectedClientDetails.gender}</p>
                            <p><strong>Email:</strong> {selectedClientDetails.email}</p>
                            <p><strong>Phone Number:</strong> {selectedClientDetails.phoneNumber}</p>
                            <p>
                                <strong>Address:</strong> {`${selectedClientDetails.address.street}, ${selectedClientDetails.address.city}, ${selectedClientDetails.address.state}, ${selectedClientDetails.address.zipCode}`}
                            </p>


                            {/* Add more details as needed */}

                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '20px' // Give some space from the content below
                            }}>
                                {/* Close Button */}
                                <button onClick={closeModal} style={{
                                    alignSelf: 'flex-start',
                                    marginTop: '20px',
                                    background: 'indigo',
                                    color: 'white',
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                    border: 'none',
                                    cursor: 'pointer'
                                }}>Close
                                </button>


                                {/* Switch to Car Details Button */}
                                {selectedClientDetails.sales && selectedClientDetails.sales.some(sale => sale.stock) && (
                                    <button
                                        onClick={() => setCurrentModal('carDetails')}
                                        style={{
                                            alignSelf: 'flex-end',
                                            padding: '5px 10px',
                                            marginRight: '10px', // Space from the right edge
                                            marginTop: '10px', // Space from the top edge
                                            background: 'lightgrey',
                                            color: 'black',
                                            borderRadius: '5px',
                                            border: 'none',
                                            cursor: 'pointer'
                                        }}>
                                        Switch to Car Details
                                    </button>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }

    function detailsCarModal() {
        return (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0,0,0,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000
            }}>
                <div style={{
                    background: 'white',
                    padding: '20px',
                    borderRadius: '5px',
                    width: '50%', // Adjust width as necessary
                    maxWidth: '600px', // Adjust max-width as necessary
                    overflowY: 'auto' // Add scroll for content overflow
                }}>
                    {/* Dynamically populate modal content here */}
                    {selectedClientDetails && selectedClientDetails.sales && selectedClientDetails.sales.length > 0 && (
                        <>
                            {/* Tabs for each car */}
                            <div className="tabs">
                                {selectedClientDetails.sales.map((sale, index) => (
                                    <button
                                        key={index}
                                        style={{
                                            padding: '10px',
                                            margin: '5px',
                                            background: selectedTabIndex === index ? 'lightgray' : 'transparent',
                                            borderBottom: selectedTabIndex === index ? '2px solid blue' : 'none',
                                        }}
                                        onClick={() => setSelectedTabIndex(index)}
                                    >
                                        {sale.stock.carName}
                                    </button>
                                ))}
                            </div>
                            <div className="tab-content">
                                {selectedClientDetails.sales[selectedTabIndex] && selectedClientDetails.sales[selectedTabIndex].stock && selectedClientDetails.sales[selectedTabIndex].stock.gallery && selectedClientDetails.sales[selectedTabIndex].stock.gallery.overall ? (
                                    <img
                                        className="rounded-full w-30 h-30 border border-gray-100 shadow-sm"
                                        src={selectedClientDetails.sales[selectedTabIndex].stock.gallery.overall}
                                        alt="user image"
                                    />
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '120px',
                                            height: '120px',
                                            borderRadius: '50%',
                                            backgroundColor: '#f3f3f3',
                                            color: '#ccc',
                                            border: '2px solid #eee',
                                        }}
                                    >
                                        {/* You can place text or icon here */}
                                        <span>No Image</span>
                                    </div>
                                )}
                                <p><strong>Car:</strong> {selectedClientDetails.sales[selectedTabIndex].stock.carName}
                                </p>
                                <p><strong>Plate
                                    No:</strong> {selectedClientDetails.sales[selectedTabIndex].stock.plateNo}
                                </p>
                                <p><strong>Chassis
                                    No:</strong> {selectedClientDetails.sales[selectedTabIndex].stock.chassisNo}
                                </p>
                                <p><strong>Road Tax Expiry
                                    Date:</strong> {selectedClientDetails.sales[selectedTabIndex].stock.roadtaxExpiry ? new Date(selectedClientDetails.sales[selectedTabIndex].stock.roadtaxExpiry).toLocaleDateString() : 'No Data'}
                                </p>
                                <p><strong>Insurance Expiry
                                    Date:</strong> {selectedClientDetails.sales[selectedTabIndex].stock.insuranceExpiry ? new Date(selectedClientDetails.sales[selectedTabIndex].stock.insuranceExpiry).toLocaleDateString() : 'No Data'}
                                </p>
                                {/* Add more details as needed */}
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '20px' // Give some space from the content below
                            }}>
                                {/* Close Button */}
                                <button onClick={closeModal} style={{
                                    alignSelf: 'flex-start',
                                    marginTop: '20px',
                                    background: 'indigo',
                                    color: 'white',
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                    border: 'none',
                                    cursor: 'pointer'
                                }}>Close
                                </button>

                                {/* Switch to Client Details Button */}
                                <button
                                    onClick={() => setCurrentModal('details')}
                                    style={{
                                        alignSelf: 'flex-end',
                                        padding: '5px 10px',
                                        marginRight: '10px', // Space from the right edge
                                        marginTop: '10px', // Space from the top edge
                                        background: 'lightgrey',
                                        color: 'black',
                                        borderRadius: '5px',
                                        border: 'none',
                                        cursor: 'pointer'
                                    }}>
                                    Switch to Client Details
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }

    const handleMonthSelection = (month) => {
        setSelectedMonth(month);
    };


    return (
        <Layout>

            {/* Modal component */}
            {isModalOpen && (currentModal === 'details' ? detailsModal() : detailsCarModal())}

            <main
                className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl bg-white transition-all duration-200">
                {/* Navbar */}
                <div className="w-full px-6 py-3 mx-auto">
                    <h3 className="text-black font-bold">Client Profile (Anniversary)</h3>
                    {/* Navbar */}
                    <nav
                        className="text-black relative flex flex-wrap items-center justify-between py-2 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
                        navbar-main=""
                        navbar-scroll="false"
                    >
                        <div className="flex items-center justify-between w-full py-1 mx-auto flex-wrap-inherit">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div className="">
                                            <Link to="/dashboard"
                                                  href="../dashboard.html"
                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Dashboard</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center px-4">
                                            <svg
                                                className="h-5 w-5 flex-shrink-0 text-gray-400 pt-1"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            <a className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700">
                                                <span className="">Client Profile</span>
                                            </a>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </nav>
                    {/* end Navbar */}
                    <button
                        type="button"
                        onClick={() => handleMonthSelection(null)}
                        className={`inline-block px-8 py-2 mx-1 mb-2 font-bold text-center uppercase align-middle transition-all border border-solid rounded-lg shadow-none cursor-pointer leading-pro ease-soft-in text-xs hover:scale-102 active:shadow-soft-xs tracking-tight-soft ${selectedMonth === null ? 'bg-red-500 text-white hover:bg-transparent hover:text-black' : 'bg-transparent text-black hover:bg-red-500 hover:text-white'}`}
                    >
                        All
                    </button>
                    <button
                        type="button"
                        onClick={() => handleMonthSelection(1)}
                        className={`inline-block px-8 py-2 mb-2 mx-1 font-bold text-center uppercase align-middle transition-all border border-solid rounded-lg shadow-none cursor-pointer leading-pro ease-soft-in text-xs hover:scale-102 active:shadow-soft-xs tracking-tight-soft ${selectedMonth === 1 ? 'bg-red-500 text-white hover:bg-transparent hover:text-black' : 'bg-transparent text-black hover:bg-red-500 hover:text-white'}`}
                    >
                        January
                    </button>
                    <button
                        type="button"
                        onClick={() => handleMonthSelection(2)}
                        className={`inline-block px-8 py-2 mb-2 mx-1 font-bold text-center uppercase align-middle transition-all border border-solid rounded-lg shadow-none cursor-pointer leading-pro ease-soft-in text-xs hover:scale-102 active:shadow-soft-xs tracking-tight-soft ${selectedMonth === 2 ? 'bg-red-500 text-white hover:bg-transparent hover:text-black' : 'bg-transparent text-black hover:bg-red-500 hover:text-white'}`}
                    >
                        February
                    </button>
                    <button
                        type="button"
                        onClick={() => handleMonthSelection(3)}
                        className={`inline-block px-8 py-2 mb-2 mx-1 font-bold text-center uppercase align-middle transition-all border border-solid rounded-lg shadow-none cursor-pointer leading-pro ease-soft-in text-xs hover:scale-102 active:shadow-soft-xs tracking-tight-soft ${selectedMonth === 3 ? 'bg-red-500 text-white hover:bg-transparent hover:text-black' : 'bg-transparent text-black hover:bg-red-500 hover:text-white'}`}
                    >
                        March
                    </button>
                    <button
                        type="button"
                        onClick={() => handleMonthSelection(4)}
                        className={`inline-block px-8 py-2 mb-2 mx-1 font-bold text-center uppercase align-middle transition-all border border-solid rounded-lg shadow-none cursor-pointer leading-pro ease-soft-in text-xs hover:scale-102 active:shadow-soft-xs tracking-tight-soft ${selectedMonth === 4 ? 'bg-red-500 text-white hover:bg-transparent hover:text-black' : 'bg-transparent text-black hover:bg-red-500 hover:text-white'}`}
                    >
                        April
                    </button>
                    <button
                        type="button"
                        onClick={() => handleMonthSelection(5)}
                        className={`inline-block px-8 py-2 mb-2 mx-1 font-bold text-center uppercase align-middle transition-all border border-solid rounded-lg shadow-none cursor-pointer leading-pro ease-soft-in text-xs hover:scale-102 active:shadow-soft-xs tracking-tight-soft ${selectedMonth === 5 ? 'bg-red-500 text-white hover:bg-transparent hover:text-black' : 'bg-transparent text-black hover:bg-red-500 hover:text-white'}`}
                    >
                        May
                    </button>
                    <button
                        type="button"
                        onClick={() => handleMonthSelection(6)}
                        className={`inline-block px-8 py-2 mb-2 mx-1 font-bold text-center uppercase align-middle transition-all border border-solid rounded-lg shadow-none cursor-pointer leading-pro ease-soft-in text-xs hover:scale-102 active:shadow-soft-xs tracking-tight-soft ${selectedMonth === 6 ? 'bg-red-500 text-white hover:bg-transparent hover:text-black' : 'bg-transparent text-black hover:bg-red-500 hover:text-white'}`}
                    >
                        June
                    </button>
                    <button
                        type="button"
                        onClick={() => handleMonthSelection(7)}
                        className={`inline-block px-8 py-2 mb-2 mx-1 font-bold text-center uppercase align-middle transition-all border border-solid rounded-lg shadow-none cursor-pointer leading-pro ease-soft-in text-xs hover:scale-102 active:shadow-soft-xs tracking-tight-soft ${selectedMonth === 7 ? 'bg-red-500 text-white hover:bg-transparent hover:text-black' : 'bg-transparent text-black hover:bg-red-500 hover:text-white'}`}
                    >
                        July
                    </button>
                    <button
                        type="button"
                        onClick={() => handleMonthSelection(8)}
                        className={`inline-block px-8 py-2 mb-2 mx-1 font-bold text-center uppercase align-middle transition-all border border-solid rounded-lg shadow-none cursor-pointer leading-pro ease-soft-in text-xs hover:scale-102 active:shadow-soft-xs tracking-tight-soft ${selectedMonth === 8 ? 'bg-red-500 text-white hover:bg-transparent hover:text-black' : 'bg-transparent text-black hover:bg-red-500 hover:text-white'}`}
                    >
                        August
                    </button>
                    <button
                        type="button"
                        onClick={() => handleMonthSelection(9)}
                        className={`inline-block px-8 py-2 mb-2 mx-1 font-bold text-center uppercase align-middle transition-all border border-solid rounded-lg shadow-none cursor-pointer leading-pro ease-soft-in text-xs hover:scale-102 active:shadow-soft-xs tracking-tight-soft ${selectedMonth === 9 ? 'bg-red-500 text-white hover:bg-transparent hover:text-black' : 'bg-transparent text-black hover:bg-red-500 hover:text-white'}`}
                    >
                        September
                    </button>
                    <button
                        type="button"
                        onClick={() => handleMonthSelection(10)}
                        className={`inline-block px-8 py-2 mb-2 mx-1 font-bold text-center uppercase align-middle transition-all border border-solid rounded-lg shadow-none cursor-pointer leading-pro ease-soft-in text-xs hover:scale-102 active:shadow-soft-xs tracking-tight-soft ${selectedMonth === 10 ? 'bg-red-500 text-white hover:bg-transparent hover:text-black' : 'bg-transparent text-black hover:bg-red-500 hover:text-white'}`}
                    >
                        October
                    </button>
                    <button
                        type="button"
                        onClick={() => handleMonthSelection(11)}
                        className={`inline-block px-8 py-2 mb-2 mx-1 font-bold text-center uppercase align-middle transition-all border border-solid rounded-lg shadow-none cursor-pointer leading-pro ease-soft-in text-xs hover:scale-102 active:shadow-soft-xs tracking-tight-soft ${selectedMonth === 11 ? 'bg-red-500 text-white hover:bg-transparent hover:text-black' : 'bg-transparent text-black hover:bg-red-500 hover:text-white'}`}
                    >
                        November
                    </button>
                    <button
                        type="button"
                        onClick={() => handleMonthSelection(12)}
                        className={`inline-block px-8 py-2 mb-2 mx-1 font-bold text-center uppercase align-middle transition-all border border-solid rounded-lg shadow-none cursor-pointer leading-pro ease-soft-in text-xs hover:scale-102 active:shadow-soft-xs tracking-tight-soft ${selectedMonth === 12 ? 'bg-red-500 text-white hover:bg-transparent hover:text-black' : 'bg-transparent text-black hover:bg-red-500 hover:text-white'}`}
                    >
                        December
                    </button>
                    <div className="w-full px-6 py-6 mx-auto">
                        {/* table 1 */}
                        <div className="flex flex-wrap -mx-3">
                            <div className="flex-none w-full max-w-full px-3">
                                <div
                                    className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-md rounded-2xl bg-clip-border">
                                    <div
                                        className="relative flex flex-row min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-md rounded-2xl bg-clip-border">
                                        <Link to="/client-list">
                                            <div
                                                className="p-6 pb-0 mb-3 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                                                <a>All Client</a>
                                            </div>
                                        </Link>
                                        <Link to="/client-birthday-list">
                                            <div
                                                className="p-6 pb-0 mb-0 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                                                <a>Birthday</a>
                                            </div>
                                        </Link>
                                        <Link to="/client-anniversary-list">
                                            <div
                                                className="p-6 pb-3 bg-blue-500 text-white font-bold border-b-0 border-b-solid rounded-2xl border-b-transparent">
                                                <a>Anniversary</a>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="flex-auto px-0 pt-0 pb-2">
                                        <div className="p-0 overflow-x-auto">
                                            <table
                                                className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                                                <thead className="align-bottom">
                                                <tr>
                                                    <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-white border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                        No
                                                    </th>
                                                    <th className="px-6 py-3 pl-5 font-bold text-left uppercase align-middle bg-white border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                        Client Name
                                                    </th>
                                                    <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-white border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                        Anniversary Date
                                                    </th>
                                                    <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-white border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                                        Phone Number
                                                    </th>
                                                    <th className="px-6 py-3 font-semibold capitalize align-middle bg-white border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70"/>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {renderClients()}
                                                </tbody>
                                            </table>
                                            <div
                                                className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                                                {renderPagination()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default ClientAnniversaryPage;
