import React, {useState, useEffect} from "react";
import {useHistory, Link, useParams} from "react-router-dom";
import axios from 'axios';
import {API} from "../../config/config";

import '../assets/css/nucleo-icons.css';
import '../assets/css/nucleo-svg.css';
import '../assets/css/soft-ui-dashboard-tailwind.css';
import Layout from '../../component/layout';
import {getCookie} from "../../action/authAction";


const CarSpecification = () => {
    let {id} = useParams(); // This 'id' matches the ':id' in your route path

    // You can now use this 'id' to fetch stock details or images as needed
    console.log(id); // For demonstration

    const token = getCookie('token');

    const [transmissionType, setTransmissionType] = useState("");
    const [totalGear, setTotalGear] = useState(0);
    const [driveTrain, setDriveTrain] = useState("");
    const [doorNo, setDoorNo] = useState(0);
    const [chairNo, setChairNo] = useState(0);
    const [installation, setInstallation] = useState("");
    const [engineModel, setEngineModel] = useState("");
    const [engineType, setEngineType] = useState("");
    const [engineCC, setEngineCC] = useState(0);
    const [engineBore, setEngineBore] = useState(0);
    const [engineStroke, setEngineStroke] = useState(0);
    const [engineHP, setEngineHP] = useState(0);
    const [engineTorque, setEngineTorque] = useState(0);
    const [compressionRatio, setCompressionRatio] = useState("");
    const [engineCylinder, setEngineCylinder] = useState(0);
    const [topSpeed, setTopSpeed] = useState(0);
    const [speed0to100, setSpeed0to100] = useState(0);
    const [length, setLength] = useState(0);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [wheelbase, setWheelbase] = useState(0);
    const [weight, setWeight] = useState(0);
    const [boots, setBoots] = useState(0);
    const [fuelTank, setFuelTank] = useState(0);
    const [frontSuspension, setFrontSuspension] = useState("");
    const [rearSuspension, setRearSuspension] = useState("");
    const [frontBrake, setFrontBrake] = useState("");
    const [rearBrake, setRearBrake] = useState("");
    const [frontTireSize, setFrontTireSize] = useState("");
    const [rearTireSize, setRearTireSize] = useState("");

    const fetchStockDetails = async () => {
        try {
            const response = await axios.get(`${API}/stock/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // Assuming the API response has a data field containing the stock details
            const stock = response.data.data;

            console.log(stock);

            // Update form fields
            setTransmissionType(stock.specs.transmissionType || '');
            setTotalGear(stock.specs.totalGear || 0);
            setDriveTrain(stock.specs.drivetrain || ""); // Assuming 'drivetrain' corresponds to 'Wheel Drive'
            setDoorNo(stock.specs.doorNo || 0);
            setChairNo(stock.specs.chairNo || 0);
            setInstallation(stock.specs.installation || '');
            setEngineModel(stock.specs.engineModel || '');
            setEngineType(stock.specs.engineType || '');
            setEngineCC(stock.specs.engineCC || 0);
            setEngineBore(stock.specs.engineBore || 0);
            setEngineStroke(stock.specs.engineStroke || 0);
            setEngineHP(stock.specs.engineHP || 0);
            setEngineTorque(stock.specs.engineTorque || 0);
            setCompressionRatio(stock.specs.engineCompressionRatio || "");
            setEngineCylinder(stock.specs.engineCylinder || 0);
            setTopSpeed(stock.specs.topSpeed || 0);
            setSpeed0to100(stock.specs.speed0To100 || 0);
            setLength(stock.specs.length || 0);
            setWidth(stock.specs.width || 0);
            setHeight(stock.specs.height || 0);
            setWheelbase(stock.specs.wheelbase || 0);
            setWeight(stock.specs.weight || 0);
            setBoots(stock.specs.boots || 0);
            setFuelTank(stock.specs.fuelTank || 0);
            setFrontSuspension(stock.specs.frontSuspension || '');
            setRearSuspension(stock.specs.rearSuspension || '');
            setFrontBrake(stock.specs.frontBrake || "");
            setRearBrake(stock.specs.rearBrake || "");
            setFrontTireSize(stock.specs.frontTireSize || '');
            setRearTireSize(stock.specs.rearTireSize || '');


        } catch (error) {
            console.error("Error fetching stock details:", error);
        }
    };

    useEffect(() => {
        fetchStockDetails();
    }, [id, token]); // This effect depends on the `id`, `token`

    const saveChanges = async () => {
        try {
            const payload = {
                specs: {
                    transmissionType,
                    totalGear: Number(totalGear),
                    drivetrain: driveTrain,
                    doorNo: Number(doorNo),
                    chairNo: Number(chairNo),
                    installation,
                    engineModel,
                    engineType,
                    engineCC: Number(engineCC),
                    engineBore: Number(engineBore),
                    engineStroke: Number(engineStroke),
                    engineHP: Number(engineHP),
                    engineTorque: Number(engineTorque),
                    engineCompressionRatio: compressionRatio,
                    engineCylinder: Number(engineCylinder),
                    topSpeed: Number(topSpeed),
                    speed0to100: Number(speed0to100),
                    length: Number(length),
                    width: Number(width),
                    height: Number(height),
                    wheelbase: Number(wheelbase),
                    weight: Number(weight),
                    boots: Number(boots),
                    fuelTank: Number(fuelTank),
                    frontSuspension,
                    rearSuspension,
                    frontBrake,
                    rearBrake,
                    frontTireSize,
                    rearTireSize,
                },
            };

            await axios.patch(`${API}/stock/${id}`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            // Handle success here, e.g., showing a success message
            alert('Stock details updated successfully');

            fetchStockDetails();
        } catch (error) {
            // Handle error here, e.g., showing an error message
            console.error("Error saving changes:", error);
            alert('Failed to update stock details');
        }
    };

    return (<div>
        <div className="flex flex-wrap -mx-3">
            <div className="flex-auto p-6">
                <div className="flex justify-start items-start">
                    <ul
                        className="flex flex-wrap p-1 list-none bg-blue-100 rounded-xl"
                        nav-pills=""
                        role="tablist"
                    >
                        <li className="z-30 flex-auto text-center">
                            <a
                                className="z-30 flex  w-full px-3 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg bg-inherit text-slate-700"
                                nav-link=""
                                active=""
                                role="tab"
                                aria-selected="true"
                            >
                                <span className="ml-2">Transmission</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-wrap -mx-3">
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="transmission"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Transmission Type
                            </label>
                            <select
                                className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                id="transmissionType" // Changed the 'name' to 'id' for consistency with the label
                                value={transmissionType} // Set the value to the state variable
                                onChange={e => setTransmissionType(e.target.value)} // Update the state on change
                            >
                                <option value="">Select Transmission Type</option>
                                <option value="Automatic">Automatic</option>
                                <option value="Manual">Manual</option>
                            </select>
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="totalgear"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Total Gear
                            </label>
                            <input
                                placeholder={7}
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={totalGear}
                                onChange={e => setTotalGear(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="drivetrain"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Drivetrain
                            </label>
                            <select
                                className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                id="drivetrain" // Changed the 'name' to 'id' for consistency with the label
                                value={driveTrain} // Set the value to the state variable
                                onChange={e => setDriveTrain(e.target.value)} // Update the state on change
                            >
                                <option value="">Select Drivetrain</option>
                                <option value="FWD">FWD</option>
                                <option value="RWD">RWD</option>
                                <option value="AWD">AWD</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="flex justify-start items-start">
                    <ul
                        className="flex flex-wrap p-1 list-none bg-blue-100 rounded-xl"
                        nav-pills=""
                        role="tablist"
                    >
                        <li className="z-30 flex-auto text-center">
                            <a
                                className="z-30 flex w-full px-3 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg bg-inherit text-slate-700"
                                nav-link=""
                                active=""
                                role="tab"
                                aria-selected="true"
                            >
                                <span className="ml-2">General</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-wrap -mx-3">
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="door"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Door
                            </label>
                            <input
                                placeholder={5}
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={doorNo}
                                onChange={e => setDoorNo(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="chairs"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Number of chairs
                            </label>
                            <input
                                placeholder={5}
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={chairNo}
                                onChange={e => setChairNo(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="installation"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Installation
                            </label>
                            <select
                                className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                id="installation" // Changed the 'name' to 'id' for consistency with the label
                                value={installation} // Set the value to the state variable
                                onChange={e => setInstallation(e.target.value)} // Update the state on change
                            >
                                <option value="">Select Installation</option>
                                <option value="Official Import">Official Import</option>
                                <option value="Local Assembly">Local Assembly</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="flex justify-start items-start">
                    <ul
                        className="flex flex-wrap p-1 list-none bg-blue-100 rounded-xl"
                        nav-pills=""
                        role="tablist"
                    >
                        <li className="z-30 flex-auto text-center">
                            <a
                                className="z-30 flex w-full px-3 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg bg-inherit text-slate-700"
                                nav-link=""
                                active=""
                                role="tab"
                                aria-selected="true"
                            >
                                <span className="ml-2">Engine Specification</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-wrap -mx-3">
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="engine"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                CC Engine
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={engineCC}
                                onChange={e => setEngineCC(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="gerek"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Dredge (mm)
                            </label>
                            <input
                                placeholder={83}
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={engineBore}
                                onChange={e => setEngineBore(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="stroke"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Stroke (mm)
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={engineStroke}
                                onChange={e => setEngineStroke(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="Compression Ratio"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Compression Ratio
                            </label>
                            <input
                                placeholder="10.5"
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={compressionRatio}
                                onChange={e => setCompressionRatio(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="Maximum Power (Hp)"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Maximum Power (Hp)
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={engineHP}
                                onChange={e => setEngineHP(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="Maximum Torque (Nm)"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Torque (Nm)
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={engineTorque}
                                onChange={e => setEngineTorque(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="enginemodel"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Engine Model
                            </label>
                            <input
                                placeholder="M260"
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={engineModel}
                                onChange={e => setEngineModel(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="enginetype"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Type of Engine
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={engineType}
                                onChange={e => setEngineType(e.target.value)}
                            />
                        </div>
                    </div>
                    {/*<div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label
                                                                htmlFor="Direct Injection System"
                                                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                            >
                                                                Direct Injection System
                                                            </label>
                                                            <input
                                                                placeholder="Direct Injection"
                                                                type="text"
                                                                name="datepicker"
                                                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            />
                                                        </div>
                                                    </div>*/}
                    {/*<div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label
                                                                htmlFor="Aspiration"
                                                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                            >
                                                                Aspiration
                                                            </label>
                                                            <input
                                                                placeholder="Turbo Intercooled"
                                                                type="text"
                                                                name="datepicker"
                                                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            />
                                                        </div>
                                                    </div>*/}
                    {/*<div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label
                                                                htmlFor="fueltype"
                                                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                            >
                                                                Type of Fuel
                                                            </label>
                                                            <input
                                                                placeholder="Petrol - Unleaded (ULP)"
                                                                type="text"
                                                                name="datepicker"
                                                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            />
                                                        </div>
                                                    </div>*/}
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="Horsepower"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Horsepower
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={engineHP}
                                onChange={e => setEngineHP(e.target.value)}
                            />
                        </div>
                    </div>
                    {/*<div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label
                                                                htmlFor="RPM Power From"
                                                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                            >
                                                                RPM Power From
                                                            </label>
                                                            <input
                                                                placeholder={5500}
                                                                type="text"
                                                                name="datepicker"
                                                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label
                                                                htmlFor="Torque RPM To"
                                                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                            >
                                                                Torque RPM To
                                                            </label>
                                                            <input
                                                                placeholder={1800}
                                                                type="text"
                                                                name="datepicker"
                                                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            />
                                                        </div>
                                                    </div>
                                                    */}
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="datepicker"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Cylinder
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={engineCylinder}
                                onChange={e => setEngineCylinder(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-end justify-end w-full">
                    <button
                        type="button"
                        onClick={saveChanges}
                        className="px-8 py-2 mb-4 mr-4 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-green-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                    >
                        <i className="fa fa-check" aria-hidden="true"/> Save
                    </button>
                </div>
                <div className="flex justify-start items-start">
                    <ul
                        className="flex flex-wrap p-1 list-none bg-blue-100 rounded-xl"
                        nav-pills=""
                        role="tablist"
                    >
                        <li className="z-30 flex-auto text-center">
                            <a
                                className="z-30 flex w-full px-3 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg bg-inherit text-slate-700"
                                nav-link=""
                                active=""
                                role="tab"
                                aria-selected="true"
                            >
                          <span className="ml-2">
                            Performance &amp; Economy
                          </span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-wrap -mx-3">
                    {/*<div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label
                                                                htmlFor="engine"
                                                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                            >
                                                                Fuel Consumption (L/100km)
                                                            </label>
                                                            <input
                                                                placeholder="6.2"
                                                                type="text"
                                                                name="datepicker"
                                                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            />
                                                        </div>
                                                    </div>*/}
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="gerek"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                0-100 km/j
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={speed0to100}
                                onChange={e => setSpeed0to100(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="stroke"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Top Speed (km/h)
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={topSpeed}
                                onChange={e => setTopSpeed(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex justify-start items-start">
                    <ul
                        className="flex flex-wrap p-1 list-none bg-blue-100 rounded-xl"
                        nav-pills=""
                        role="tablist"
                    >
                        <li className="z-30 flex-auto text-center">
                            <a
                                className="z-30 flex w-full px-3 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg bg-inherit text-slate-700"
                                nav-link=""
                                active=""
                                role="tab"
                                aria-selected="true"
                            >
                          <span className="ml-2">
                            Dimensions &amp; Weight
                          </span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-wrap -mx-3">
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="Length"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Length (mm)
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={length}
                                onChange={e => setLength(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="Width"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Width (mm)
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={width}
                                onChange={e => setWidth(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="Height"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Height (mm)
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={width}
                                onChange={e => setWidth(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="Wheelbase"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Wheelbase (mm)
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={wheelbase}
                                onChange={e => setWheelbase(e.target.value)}
                            />
                        </div>
                    </div>
                    {/*<div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                       <div className="mb-4">
                                                            <label
                                                                htmlFor="frontthread"
                                                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                            >
                                                                Front Thread
                                                            </label>
                                                            <input
                                                                placeholder={1567}
                                                                type="text"
                                                                name="datepicker"
                                                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            />
                                                        </div>
                                                    </div>*/}
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="Weight"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Weight (kg)
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={weight}
                                onChange={e => setWeight(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="Boots"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Boots (litres)
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={boots}
                                onChange={e => setBoots(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="FuelTank"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Fuel Tank (liter)
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={fuelTank}
                                onChange={e => setFuelTank(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex justify-start items-start">
                    <ul
                        className="flex flex-wrap p-1 list-none bg-blue-100 rounded-xl"
                        nav-pills=""
                        role="tablist"
                    >
                        <li className="z-30 flex-auto text-center">
                            <a
                                className="z-30 flex w-full px-3 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg bg-inherit text-slate-700"
                                nav-link=""
                                active=""
                                role="tab"
                                aria-selected="true"
                            >
                                <span className="ml-2">Brake</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-wrap -mx-3">
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="Length"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Front Brake
                            </label>
                            <select
                                className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                id="front-brake" // Changed the 'name' to 'id' for consistency with the label
                                value={frontBrake} // Set the value to the state variable
                                onChange={e => setFrontBrake(e.target.value)} // Update the state on change
                            >
                                <option value="">Select Front Brake</option>
                                <option value="Ventilated Disk Brake">Ventilated Disk Brake</option>
                                <option value="Drum Brake">Drum Brake</option>
                                <option value="Ventilated Disk Brake with ABS">Ventilated Disk Brake with ABS</option>
                                <option value="Drum Brake with ABS">Drum Brake with ABS</option>
                            </select>
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="Length"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Rear Brake
                            </label>
                            <select
                                className="form-control focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                id="rear-brake" // Changed the 'name' to 'id' for consistency with the label
                                value={rearBrake} // Set the value to the state variable
                                onChange={e => setRearBrake(e.target.value)} // Update the state on change
                            >
                                <option value="">Select Rear Brake</option>
                                <option value="Ventilated Disk Brake">Ventilated Disk Brake</option>
                                <option value="Drum Brake">Drum Brake</option>
                                <option value="Ventilated Disk Brake With ABS">Ventilated Disk Brake with ABS</option>
                                <option value="Drum Brake With ABS">Drum Brake with ABS</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="flex justify-start items-start">
                    <ul
                        className="flex flex-wrap p-1 list-none bg-blue-100 rounded-xl"
                        nav-pills=""
                        role="tablist"
                    >
                        <li className="z-30 flex-auto text-center">
                            <a
                                className="z-30 flex w-full px-3 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg bg-inherit text-slate-700"
                                nav-link=""
                                active=""
                                role="tab"
                                aria-selected="true"
                            >
                                <span className="ml-2">Suspension</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-wrap -mx-3">
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="Length"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Front Suspension
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={frontSuspension}
                                onChange={e => setFrontSuspension(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="Length"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Rear Suspension
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={rearSuspension}
                                onChange={e => setRearSuspension(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                {/*<div className="flex justify-start items-start">
                                                    <ul
                                                        className="flex flex-wrap p-1 list-none bg-blue-100 rounded-xl"
                                                        nav-pills=""
                                                        role="tablist"
                                                    >
                                                        <li className="z-30 flex-auto text-center">
                                                            <a
                                                                className="z-30 flex w-full px-3 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg bg-inherit text-slate-700"
                                                                nav-link=""
                                                                active=""
                                                                role="tab"
                                                                aria-selected="true"
                                                            >
                                                                <span className="ml-2">Steering wheel</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="flex flex-wrap -mx-3">
                                                    <div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label
                                                                htmlFor="Length"
                                                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                            >
                                                                Front Suspension
                                                            </label>
                                                            <input
                                                                placeholder={11}
                                                                type="text"
                                                                name="datepicker"
                                                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label
                                                                htmlFor="Length"
                                                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                            >
                                                                Steering Type
                                                            </label>
                                                            <input
                                                                placeholder="Rack And Pinion"
                                                                type="text"
                                                                name="datepicker"
                                                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                */}
                <div className="flex justify-start items-start">
                    <ul
                        className="flex flex-wrap p-1 list-none bg-blue-100 rounded-xl"
                        nav-pills=""
                        role="tablist"
                    >
                        <li className="z-30 flex-auto text-center">
                            <a
                                className="z-30 flex w-full px-3 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg bg-inherit text-slate-700"
                                nav-link=""
                                active=""
                                role="tab"
                                aria-selected="true"
                            >
                                <span className="ml-2">Tires &amp; Wheels</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-wrap -mx-3">
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="Length"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Front Tire
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={frontTireSize}
                                onChange={e => setFrontTireSize(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                        <div className="mb-4">
                            <label
                                htmlFor="Length"
                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                            >
                                Rear tyre
                            </label>
                            <input
                                type="text"
                                name="datepicker"
                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                value={rearTireSize}
                                onChange={e => setRearTireSize(e.target.value)}
                            />
                        </div>
                    </div>
                    {/*<div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label
                                                                htmlFor="Length"
                                                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                            >
                                                                Front Rim (inches)
                                                            </label>
                                                            <input
                                                                placeholder={18}
                                                                type="text"
                                                                name="datepicker"
                                                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            />
                                                        </div>
                                                    </div>
                                                    */}
                    {/*<div
                                                        className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                        <div className="mb-4">
                                                            <label
                                                                htmlFor="Length"
                                                                className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                                                            >
                                                                Rear Rim (inches)
                                                            </label>
                                                            <input
                                                                placeholder={18}
                                                                type="text"
                                                                name="datepicker"
                                                                className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            />
                                                        </div>
                                                    </div>*/}
                    <div className="flex flex-row items-end justify-end w-full">
                        {/*<button
                                                            type="button"
                                                            className="px-8 py-2 mb-4 mr-4 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                        >
                                                            <i className="fa fa-pen" aria-hidden="true"/> Edit
                                                        </button>*/}
                        <button
                            type="button"
                            onClick={saveChanges}
                            className="px-8 py-2 mb-4 mr-4 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-green-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                        >
                            <i className="fa fa-check" aria-hidden="true"/> Save
                        </button>
                        {/*<button
                                                            type="button"
                                                            className="px-8 py-2 mb-4 mr-4 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-orange-400 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                        >
                                                            <i className="fa fa-arrow-right"
                                                               aria-hidden="true"/>{" "}
                                                            Next
                                                        </button>*/}
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default CarSpecification;
