import React, {useState, useEffect} from "react";
import Layout from "../../component/layout";
import {Link, useParams} from "react-router-dom";
import {getCookie} from "../../action/authAction";
import {API} from "../../config/config";
import axios from "axios";
import documentLibrary from "./document-library";

const DocumentLibraryPage = () => {
    const token = getCookie("token");

    const [documents, setDocuments] = useState([]);

    const fetchDocuments = async () => {
        try {
            const response = await axios.get(`${API}/document/all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setDocuments(response.data.data);
        } catch (error) {
            console.error("Error fetching documents:", error);
        }
    };

    useEffect(() => {
        fetchDocuments();
    }, [token]);

    const handleDownload = (fileUrl) => {
        window.open(fileUrl, '_blank');
    };

    const renderDocumentLibrary = () => {
        return documents.map((document, index) => (
            <div key={index} className="w-full max-w-full px-3 mb-6 lg:w-1/3">
                <div
                    className="relative flex flex-col min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                    <div className="p-4">
                        <div className="mb-6">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-16 h-16 text-blue-500"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M7.502 6h7.128A3.375 3.375 0 0118 9.375v9.375a3 3 0 003-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 00-.673-.05A3 3 0 0015 1.5h-1.5a3 3 0 00-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6zM13.5 3A1.5 1.5 0 0012 4.5h4.5A1.5 1.5 0 0015 3h-1.5z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V9.375zm9.586 4.594a.75.75 0 00-1.172-.938l-2.476 3.096-.908-.907a.75.75 0 00-1.06 1.06l1.5 1.5a.75.75 0 001.116-.062l3-3.75z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                        <div className="relative h-19">
                            <div
                                className="absolute inset-x-0 bottom-0 h-19 text-sm font-bold text-black">
                                {document.name}
                            </div>
                        </div>
                        <div className="px-3 -mx-3">
                            <div className="pb-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-5 h-5"
                                >
                                    <path
                                        d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z"/>
                                    <path
                                        fillRule="evenodd"
                                        d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <span className="text-xs">{new Date(document.updatedAt).toLocaleDateString()}</span>
                            </div>
                            <hr className="h-px mt-0 bg-gray-600"/>
                            <div className="flex justify-center">
                                <button
                                    className="btn inline-block w-full py-3 font-bold leading-normal text-center text-gray-600 align-middle transition-all rounded-sm cursor-pointer text-sm ease-in border tracking-tight-rem bg-x-25"
                                    onClick={() => handleDownload(document.fileUrl)}>
                                    <span className="pr-4 text-sm">Download</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));
    };


    return (
        <Layout>
            <main
                className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl bg-white transition-all duration-200">
                {/* cards */}
                <div className="w-full px-6 py-6 mx-auto">
                    <h3 className="text-black font-bold">Document Library</h3>
                    {/* Navbar */}
                    <nav
                        className="text-black relative flex flex-wrap items-center justify-between py-2 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
                        navbar-main=""
                        navbar-scroll="false"
                    >
                        <div className="flex items-center justify-between w-full py-1 mx-auto flex-wrap-inherit">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div className="">
                                        <Link to="/dashboard"
                                                href="../dashboard.html"
                                                className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Dashboard</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center px-4">
                                            <svg
                                                className="h-5 w-5 flex-shrink-0 text-gray-400 pt-1"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            <a className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700">
                                                <span className="">Document Library</span>
                                            </a>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </nav>
                    {/* end Navbar */}
                    {/* row 1 */}
                    <div className="w-full max-w-full px-3 mb-6 lg:w-1/4 rounded-2xl">
                        <div className="flex items-center md:ml-auto md:pr-4">
                            <div
                                className="relative flex flex-wrap items-stretch w-full transition-all rounded-lg ease">
         
                               
                            </div>
                        </div>
                        <div className="flex-wrap grid grid-rows-2 grid-flow-col">
                            <div className="flex flex-wrap -mx-3 py-4">
                                {/* card1 */}
                                {renderDocumentLibrary()}
                            </div>
                        </div>
                    </div>
                    {/* row 1 */}
                </div>
                {/* end cards */}
            </main>

        </Layout>
    );
}

export default DocumentLibraryPage;
