import React, {useState, useEffect} from "react";
import Layout from "../../component/layout";
import {Link, useParams} from "react-router-dom";
import {getCookie} from "../../action/authAction";
import {API} from "../../config/config";
import axios from "axios";
import {useHistory} from "react-router-dom";
import Select from 'react-select';

const LeadForm = () => {
    const token = getCookie("token");
    const history = useHistory();
    const {id} = useParams();

    // State for form data
    const [leadData, setLeadData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "+60",
        gender: "",
        salesperson: [],
        note: ""
    });

    const [salespersons, setSalespersons] = useState([]);
    const [selectedSalespersons, setSelectedSalespersons] = useState([]);
    const [filteredSalespersons, setFilteredSalespersons] = useState([]);


    useEffect(() => {
        const fetchInitialData = async () => {
            await fetchSalespersons();
            if (id) {
                await fetchLeadDetails();
            }
        };
        fetchInitialData();
    }, [id, token]);

    const fetchLeadDetails = async () => {
        try {
            const response = await axios.get(`${API}/leads/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const lead = response.data.data;
            setLeadData({
                ...lead,
                salesperson: lead.salesperson.map(sp => ({value: sp._id, label: `${sp.firstName} ${sp.lastName}`}))
            });
            setSelectedSalespersons(lead.salesperson.map(sp => ({
                value: sp._id,
                label: `${sp.firstName} ${sp.lastName}`
            })));
        } catch (error) {
            console.error("Error fetching lead details:", error);
        }
    };

    const fetchSalespersons = async () => {
        try {
            const response = await axios.get(`${API}/salesperson/all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const allSalespersons = response.data.data.map(sp => ({
                value: sp._id,
                label: `${sp.firstName} ${sp.lastName}`
            }));
            setSalespersons([{value: 'all', label: 'Select All'}, ...allSalespersons]);
            setFilteredSalespersons([{value: 'all', label: 'Select All'}, ...allSalespersons]);
        } catch (error) {
            console.error("Error fetching salespersons:", error);
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;

        if (name === "phoneNumber") {
            if (!value.startsWith("+60")) {
                setLeadData({
                    ...leadData,
                    phoneNumber: "+60" + value.replace(/^\+60/, "")
                });
            } else {
                setLeadData({
                    ...leadData,
                    phoneNumber: value
                });
            }
        } else {
            setLeadData({...leadData, [name]: value});
        }
    };

    const handleSalespersonChange = (selectedOptions) => {
        if (selectedOptions.some(option => option.value === 'all')) {
            setSelectedSalespersons(salespersons.filter(sp => sp.value !== 'all'));
            setLeadData({
                ...leadData,
                salesperson: salespersons.filter(sp => sp.value !== 'all').map(sp => sp.value),
            });
        } else {
            setSelectedSalespersons(selectedOptions);
            setLeadData({
                ...leadData,
                salesperson: selectedOptions.map((option) => option.value),
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Extract only the value (ObjectId) from selectedSalespersons
        const salespersonIds = selectedSalespersons.map(sp => sp.value);

        try {
            if (id) {
                await axios.put(`${API}/leads/edit/${id}`, {...leadData, salesperson: salespersonIds}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                alert("Lead updated successfully");
            } else {
                await axios.post(`${API}/leads/new`, {...leadData, salesperson: salespersonIds}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                alert("Lead added successfully");
            }
            history.replace("/lead-list"); // Adjust the route as needed
        } catch (error) {
            console.error("Error adding lead:", error);
            alert("Failed to add lead");
        }
    };

    const handleSalespersonSearch = (query) => {
        if (!query) {
            setFilteredSalespersons(salespersons);
        } else {
            const filtered = salespersons.filter(sp =>
                sp.label.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredSalespersons([{value: 'all', label: 'Select All'}, ...filtered]);
        }
    };


    return (
        <Layout>
            <main
                className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl bg-white transition-all duration-200">
                <div className="w-full px-6 py-6 mx-auto">
                    <h3 className="text-black font-bold">Leads</h3>
                    <nav
                        className="text-black relative flex flex-wrap items-center justify-between py-2 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
                        navbar-main="" navbar-scroll="false">
                        <div className="flex items-center justify-between w-full py-1 mx-auto flex-wrap-inherit">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div className="">
                                            <Link to="/leads"
                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700">
                                                <span className="">Leads</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center px-4">
                                            <svg className="h-5 w-5 flex-shrink-0 text-gray-400 pt-1"
                                                 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd"
                                                      d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                      clipRule="evenodd"/>
                                            </svg>
                                            <Link to="/lead-form"
                                                  className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700">
                                                <span className="">{id ? "Edit Lead" : "Add Lead"}</span>
                                            </Link>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </nav>
                    <div className="flex flex-wrap -mx-3">
                        <div className="flex-none w-full max-w-full px-3">
                            <div className="w-full max-w-full px-3 shrink-0 md:flex-0">
                                <div
                                    className="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                                    <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                                        <div
                                            className="w-full max-w-full px-3 mx-auto mt-4 sm:my-auto md:flex-none lg:w-7/12">
                                            <div className="flex justify-center items-center">
                                                <ul className="flex flex-wrap p-1 list-none bg-gray-50 rounded-xl"
                                                    nav-pills="" role="tablist">
                                                    <li className="z-30 flex-auto text-center">
                                                        <a className="z-30 flex items-center justify-center w-full px-0 py-1 mb-0 transition-all ease-in-out border-0 rounded-lg bg-inherit text-slate-700"
                                                           nav-link="" active="" role="tab" aria-selected="true">
                                                        <span className="font-semibold ml-2">
                                                            {id ? "Edit Lead" : "Add Lead"}
                                                        </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="flex-auto p-6">
                                            <div className="flex flex-wrap -mx-3">
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="firstName"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            First Name*
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="firstName"
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            value={leadData.firstName}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="lastName"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            Last Name*
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="lastName"
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            value={leadData.lastName}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap -mx-3">
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="phoneNumber"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            Phone Number*
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="phoneNumber"
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            value={leadData.phoneNumber}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="gender"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            Gender
                                                        </label>
                                                        <select
                                                            name="gender"
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            value={leadData.gender}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Select Gender</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap -mx-3">
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-full md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="note"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            Note
                                                        </label>
                                                        <textarea
                                                            name="note"
                                                            className="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                                                            value={leadData.note}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap -mx-3">
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-4/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="salesperson"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            Salesperson
                                                        </label>
                                                        <Select
                                                            isMulti
                                                            value={selectedSalespersons}
                                                            onChange={handleSalespersonChange}
                                                            options={filteredSalespersons}
                                                            onInputChange={handleSalespersonSearch}
                                                            placeholder="Search for salespersons"
                                                            filterOption={() => true}  // Disable built-in filtering
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full max-w-full px-3 shrink-0 md:w-8/12 md:flex-0">
                                                    <div className="mb-4">
                                                        <label htmlFor="selectedSalespersons"
                                                               className="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80">
                                                            Selected Salespersons
                                                        </label>
                                                        <div className="flex flex-wrap">
                                                            {selectedSalespersons.map(sp => (
                                                                <div key={sp.value}
                                                                     className="bg-gray-100 px-2 py-1 m-1 rounded">
                                                                    {sp.label}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-row items-end justify-end w-full">
                                                <button
                                                    type="submit"
                                                    className="px-8 mt-4 py-2 mb-4 mr-4 font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-green-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                                                >
                                                    <i className="fa fa-check" aria-hidden="true"/> Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default LeadForm;
