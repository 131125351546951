import React, { useState, useEffect } from "react";
import Layout from "../../component/layout";
import { Link, useParams } from "react-router-dom";
import { getCookie } from "../../action/authAction";
import { API } from "../../config/config";
import axios from "axios";

import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

// Register the chart components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SalesPersonChart = () => {
    const token = getCookie('token');

    const [salesPersons, setSalesPersons] = useState([]);
    const [showAll, setShowAll] = useState(false);

    const [salesDataThisYear, setSalesDataThisYear] = useState(Array(12).fill(0)); // Placeholder for sales data
    const [salesDataLastYear, setSalesDataLastYear] = useState(Array(12).fill(0)); // Placeholder for sales data

    useEffect(() => {
        const fetchSalesPersons = async () => {
            try {
                const response = await axios.get(`${API}/salesperson/all`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                // Assuming response.data.data contains an array of salespersons
                setSalesPersons(response.data.data.sort((a, b) => b.totalRevenue - a.totalRevenue));
            } catch (error) {
                console.error("Failed to fetch salespersons", error);
            }
        };

        fetchSalesPersons();
    }, [token]);

    useEffect(() => {
        // Replace URL with your API endpoint for fetching sales data
        const fetchSalesData = async () => {
            try {
                const responseThisYear = await axios.get(`${API}/sales/chart/this-year`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const responseLastYear = await axios.get(`${API}/sales/chart/last-year`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log(responseThisYear.data.data);

                setSalesDataThisYear(responseThisYear.data.data); // Assuming the response format matches
                setSalesDataLastYear(responseLastYear.data.data); // Assuming the response format matches
            } catch (error) {
                console.error("Failed to fetch sales data", error);
            }
        };

        fetchSalesData();
    }, [token]);

    const displayedSalesPersons = showAll ? salesPersons : salesPersons.slice(0, 3);

    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
            {
                label: 'Sales This Year',
                data: salesDataThisYear,
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
            {
                label: 'Sales Last Year',
                data: salesDataLastYear,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Sales Unit to Date Comparison',
            },
        },
    };

    return (
        <Layout>
            <main
                className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl bg-white transition-all duration-200">
                {/* cards */}
                <div className="w-full px-3 py-6 mx-auto">
                    <h3 className="text-black font-bold">Salesperson performance</h3>
                    {/* Navbar */}
                    <nav
                        className="text-black relative flex flex-wrap items-center justify-between py-2 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
                        navbar-main=""
                        navbar-scroll="false"
                    >
                        <div className="flex items-center justify-between w-full py-1 mx-auto flex-wrap-inherit">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div className="">
                                            <Link to="/dashboard"
                                                href="../dashboard.html"
                                                className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Dashboard</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center ">
                                            <svg
                                                className="h-5 w-5 flex-shrink-0 text-gray-400 pt-1"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            <Link to="/salesperson-chart"
                                                href="index.html"
                                                className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                            >
                                                <span className="">Salesperson performance</span>
                                            </Link>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </nav>
                    {/* end Navbar */}
                    {/* cards row 2 */}
                    <div className="w-full px-3 py-6 mx-auto">
                        <h3 className="text-black font-bold">Salesperson Performance</h3>
                        <button
                            type="button"
                            class="mr-4 mb-4 inline-flex items-center rounded-lg border border-transparent bg-green-500 px-3 py-1 text-xs font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="2.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M12 6v12m6-6H6"
                                />
                            </svg>

                            <Link to={`/salesperson-form`}>
                                <div class="">
                                    <a class="ml-4 text-sm font-bold text-white hover:text-white">
                                        <span class="">Add Salesperson</span>
                                    </a>
                                </div>
                            </Link>

                        </button>
                        <div className="flex flex-wrap">
                            {displayedSalesPersons.map((person, index) => (
                                <Link to={`/salesperson-detail/${person._id}`}>
                                    <div className="flex flex-wrap mt-6 -mx-2 ml-4">
                                        <div className="max-w-full w-80 h-72 lg:flex-none">
                                            <div
                                                className="relative h-full flex min-w-full break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                                                <div
                                                    className="mt-12 border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                                                    <div className="text-center">
                                                        <div className="flex justify-left">
                                                            <div className="ml-6 pl-0">
                                                                {person.profileImage ? (
                                                                    <img
                                                                        className="h-28 w-28 border-2 border-white border-solid ml-2"
                                                                        src={person.profileImage}
                                                                        alt="profile image"
                                                                    />
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            width: '96px',
                                                                            height: '112px',
                                                                            borderRadius: '0',
                                                                            backgroundColor: '#f3f3f3',
                                                                            color: '#ccc',
                                                                            border: '2px solid #eee',
                                                                            fontSize: '1rem',
                                                                        }}
                                                                    >
                                                                        <span>No Image</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <h2
                                                            className="text-xl text-left ml-6 font-bold text-black pt-6"
                                                            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                        >
                                                            <a href="salesperson/salesperson-detail.html">{person.firstName} {person.lastName}</a>
                                                        </h2>
                                                        <div className="mt-2 ml-6 pr-1 text-left text-lg font-normal text-gray-900">
                                                            {person.branch}
                                                        </div>
                                                        <div
                                                            className="absolute py-2 my-3 -top-7 h-13 w-13 text-center border-4 border-white font-bold rounded-circle bg-green-500 z-2">
                                                            <a>{index + 1}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-auto pr-8 pb-1 pt-5">
                                                    <div className="pl-0 pt-0 flex justify-end items-end">
                                                        <div>
                                                            <label className="text-lg font-bold">Sales Unit</label>
                                                            <h1 className="text-7 text-black font-bold">{person.salesCount.toLocaleString()}</h1>
                                                            <p className="text-5 text-black font-bold pl-0 pt-1">RM{person.totalRevenue.toLocaleString()}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={() => setShowAll(!showAll)}
                                className="mr-4 mb-4 inline-flex items-center rounded-lg border border-transparent bg-blue-500 px-3 py-1 text-xs font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                {showAll ? 'Show Less' : 'Show More'}
                            </button>
                        </div>
                        <div className="flex justify-center mt-8">
                            <div className="w-full max-w-4xl">
                                <Bar data={data} options={options} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
};

export default SalesPersonChart;
