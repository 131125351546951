import React from 'react';
import Sidebar from './sidebar'; // Import your Sidebar component

import '../build/assets/css/nucleo-icons.css';
import '../build/assets/css/nucleo-svg.css';
import '../build/assets/css/soft-ui-dashboard-tailwind.css';

const Layout = ({ children }) => {
    return (
        <>
            <Sidebar />
            <main className="flex-1 overflow-y-auto bg-white h-max">
                    {children}
            </main>
        </>
    );
};

export default Layout;
