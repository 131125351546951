import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Protected from "./component/private/Protected";

import LoginPage from "./build/index";
import DashboardPage from "./build/dashboard";
import StockDetailsPage from "./build/stock/stock-detail";
import StockPicPage from "./build/stock/stock-pic";
import SalespersonDetail from "./build/salesperson/salesperson-detail";
import SalesPersonChart from "./build/salesperson/salesperson-chart";
import ClientListPage from "./build/client/client-list";
import ClientBirthdayPage from "./build/client/client-birthday";
import ClientAnniversaryPage from "./build/client/client-anniversary";
import IntroducerListPage from "./build/introducer/introducer-list";
import RoadTaxInsuranceListPage from "./build/roadtax-insurance/roadtax-insurance-list";
import RevenuePage from "./build/revenue";
import UnitSoldPage from "./build/unit-sold";
import AnnouncementPage from "./build/announcement/announcement";
import AnnouncementFormPage from "./build/announcement/announcement-form";
import DocumentLibraryPage from "./build/document/document-library";
import SalesPersonFormPage from "./build/salesperson/salesperson-form";
import StockFormPage from "./build/stock/stock-form";
import ClientFormPage from "./build/client/client-form";
import IntroducerFormPage from "./build/introducer/introducer-form";
import RoadtaxInsuranceForm from "./build/roadtax-insurance/roadtax-insurance-form";
import LeadsListPage from "./build/leads/leads-list";
import LeadForm from "./build/leads/lead-form";
import Profile from "./build/profile/profile";


function App() {
    return (
        <div className="App">
            <Router basename={`/`}>
                {/*<Header />*/}
                <Protected/>
                <Switch>
                    <Route path="/" component={LoginPage} exact/>
                    <Route path="/dashboard" component={DashboardPage} exact/>
                    <Route path="/stock-detail" component={StockDetailsPage} exact/>
                    <Route path="/stock-pic/:id" component={StockPicPage} exact/>
                    <Route path={`/stock-form`} component={StockFormPage} exact/>
                    <Route path={`/salesperson-chart`} component={SalesPersonChart} exact/>
                    <Route path="/salesperson-detail/:id" component={SalespersonDetail} exact/>
                    <Route path={'/salesperson-form/:id?'} component={SalesPersonFormPage} exact/>
                    <Route path={`/client-list`} component={ClientListPage} exact/>
                    <Route path={`/client-birthday-list`} component={ClientBirthdayPage} exact/>
                    <Route path={`/client-anniversary-list`} component={ClientAnniversaryPage} exact/>
                    <Route path={`/client-form/:id?`} component={ClientFormPage} exact/>
                    <Route path={`/introducer-list`} component={IntroducerListPage} exact/>
                    <Route path={`/introducer-form/:id?`} component={IntroducerFormPage} exact/>
                    <Route path={`/roadtax-insurance-list`} component={RoadTaxInsuranceListPage} exact/>
                    <Route path={`/roadtax-insurance/form/:id?`} component={RoadtaxInsuranceForm} exact/>
                    <Route path={`/revenue`} component={RevenuePage} exact/>
                    <Route path={`/unit-sold`} component={UnitSoldPage} exact/>
                    <Route path={`/announcement`} component={AnnouncementPage} exact/>
                    <Route path={`/announcement/form/:id?`} component={AnnouncementFormPage}
                           exact/> {/*?id make it optional*/}
                    <Route path={`/document-library`} component={DocumentLibraryPage} exact/>
                    <Route path={`/lead-list`} component={LeadsListPage} exact/>
                    <Route path={`/lead/form/:id?`} component={LeadForm} exact/>
                    <Route path={`/profile`} component={Profile} exact/>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
