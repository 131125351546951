import React from 'react';
import {Link, useLocation, useHistory} from 'react-router-dom';
import '../build/assets/css/nucleo-icons.css';
import '../build/assets/css/nucleo-svg.css';
import '../build/assets/css/soft-ui-dashboard-tailwind.css';
import carImage from '../build/assets/img/car/car3.png';
import {removeCookie} from "../action/authAction";

const Sidebar = () => {
    const location = useLocation();
    const history = useHistory();

    const isActive = (path) => {
        const currentPath = window.location.pathname;
        return currentPath === path || (path === '/stock-pic/' && currentPath.startsWith('/stock-pic/')) || (path === '/salesperson-detail/' && currentPath.startsWith('/salesperson-detail/'));
    };


    const handleLogout = () => {
        removeCookie('token'); // Remove token or handle other cleanup
        history.push('/'); // Redirect to login or any other page
    };

    return (
        <aside
            className="max-w-62.5 ease-nav-brand z-990 fixed inset-y-0 my-4 ml-4 block w-full -translate-x-full flex-wrap items-center justify-between overflow-y-auto rounded-2xl border-0  p-0 antialiased shadow-soft-2xl  transition-transform duration-200 xl:left-0 xl:translate-x-0 bg-dark-gray">
            <div className="h-19.5">
                <Link to="/dashboard" className="block px-8 py-6 m-0 text-sm whitespace-nowrap text-slate-700">
                    <img src={carImage}
                         className="inline h-13 w-13 max-w-full transition-all duration-200 ease-nav-brand "
                         alt="main_logo"/>
                    <span className="ml-1 font-semibold transition-all duration-200 ease-nav-brand text-white">DTC Motors</span>
                </Link>
            </div>

            <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent"/>

            <div className="items-center block w-auto max-h-screen overflow-auto h-full grow basis-full">
                <ul className="flex flex-col pl-0 mb-0">
                    <li className="mt-0.5 w-full">
                        <Link to="/dashboard"
                              className={`py-2.7 shadow-soft-xl text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg bg-dark-gray px-4 font-semibold text-white transition-colors ${isActive('/dashboard', '/unit-sold') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''} ${isActive('/revenue') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''} ${isActive('/unit-sold') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''}`}>

                            <div
                                className="shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-dark-gray bg-center stroke-0 text-center xl:p-2.5">
                                <svg fill="black" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white"
                                     className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M9 4.5v15m6-15v15m-10.875 0h15.75c.621 0 1.125-.504 1.125-1.125V5.625c0-.621-.504-1.125-1.125-1.125H4.125C3.504 4.5 3 5.004 3 5.625v12.75c0 .621.504 1.125 1.125 1.125Z"/>
                                </svg>
                            </div>
                            <span
                                className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft text-white">Dashboard</span>
                        </Link>
                    </li>

                    <li className="mt-0.5 w-full">
                        <Link to="/announcement"
                              className={`py-2.7 shadow-soft-xl text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg bg-dark-gray px-4 font-semibold text-white transition-colors ${isActive(`/announcement`) ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''} ${isActive('/announcement/form') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''}`}>
                            <div
                                className="shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-dark-gray bg-center fill-current stroke-0 text-center xl:p-2.5">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     stroke-width="1.5" stroke="white" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"/>
                                </svg>


                            </div>
                            <span
                                className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft text-white">Announcement</span>
                        </Link>
                    </li>

                    <li className="mt-0.5 w-full">
                        <Link to='/salesperson-chart'
                              className={`py-2.7 shadow-soft-xl text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg bg-dark-gray px-4 font-semibold text-white transition-colors ${isActive(`/salesperson-chart`) ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''} ${isActive('/salesperson-detail/') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''} ${isActive('/salesperson-form') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''}`}>
                            <div
                                className="shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-dark-gray bg-center fill-current stroke-0 text-center xl:p-2.5">
                                <svg fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="white" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"/>
                                </svg>

                            </div>
                            <span
                                className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft text-white">Salesperson</span>
                        </Link>
                    </li>

                    <li className="mt-0.5 w-full">
                        <Link to="/lead-list"
                              className={`py-2.7 shadow-soft-xl text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg bg-dark-gray px-4 font-semibold text-white transition-colors ${isActive('/lead-list') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''} ${isActive('/lead-list/form') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''}`}>
                            <div
                                className="shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-dark-gray bg-center stroke-0 text-center xl:p-2.5">
                                <svg fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="white" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"/>
                                </svg>
                            </div>
                            <span
                                className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft text-white">Leads</span>
                        </Link>
                    </li>


                    <li className="mt-0.5 w-full">
                        <Link to='/document-library'
                              className={`py-2.7 shadow-soft-xl text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg bg-dark-gray px-4 font-semibold text-white transition-colors ${isActive(`/document-library`) ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''} ${isActive('/document-library') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''}`}>
                            <div
                                className="shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-dark-gray bg-center stroke-0 text-center xl:p-2.5">
                                <svg fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="white" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"/>
                                </svg>

                            </div>
                            <span className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft text-white">Document Library</span>
                        </Link>
                    </li>
                    <li className="mt-0.5 w-full">
                        <Link to='/stock-detail'
                              className={`py-2.7 shadow-soft-xl text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg bg-dark-gray px-4 font-semibold text-white transition-colors ${isActive('/stock-detail') || isActive('/stock-form') || isActive('/stock-pic/') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''}`}>
                            <div
                                className="shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-dark-gray bg-center fill-current stroke-0 text-center xl:p-2.5">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="white" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605"/>
                                </svg>
                            </div>
                            <span
                                className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft text-white">Stock</span>
                        </Link>
                    </li>

                    <li className="mt-0.5 w-full">
                        <Link to="/introducer-list"
                              className={`py-2.7 shadow-soft-xl text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg bg-dark-gray px-4 font-semibold text-white transition-colors ${isActive('/introducer-list') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''} ${isActive('/introducer-form') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''}`}>
                            <div
                                className="shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-dark-gray bg-center stroke-0 text-center xl:p-2.5">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     stroke-width="1.5" stroke="white" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z"/>
                                </svg>

                            </div>
                            <span
                                className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft text-white">Introducer</span>
                        </Link>
                    </li>

                    <li className="mt-0.5 w-full">
                        <Link to="/roadtax-insurance-list"
                              className={`py-2.7 shadow-soft-xl text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg bg-dark-gray px-4 font-semibold text-white transition-colors ${isActive('/roadtax-insurance-list') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''} ${isActive('/roadtax-insurance/form') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''}`}>
                            <div
                                className="shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-dark-gray bg-center stroke-0 text-center xl:p-2.5">
                                <svg fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="white" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"/>
                                </svg>
                            </div>
                            <span className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft text-white">Roadtax And Insurance</span>
                        </Link>
                    </li>

                    {/*<li className="mt-0.5 w-full">*/}
                    {/*    <a className="py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap px-4 transition-colors"*/}
                    {/*       href="../dtcpartners/partner-form.html">*/}
                    {/*        <div*/}
                    {/*            className="shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5">*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"*/}
                    {/*                 stroke-width="1.5" stroke="currentColor" class="w-6 h-6">*/}
                    {/*                <path stroke-linecap="round" stroke-linejoin="round"*/}
                    {/*                      d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"/>*/}
                    {/*            </svg>*/}


                    {/*        </div>*/}
                    {/*        <span*/}
                    {/*            className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft">DTC Partners</span>*/}
                    {/*    </a>*/}
                    {/*</li>*/}

                    <li className="mt-0.5 w-full">
                        <Link to="/client-list"
                              className={`py-2.7 shadow-soft-xl text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg bg-dark-gray px-4 font-semibold text-white transition-colors ${isActive('/client-list') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''} ${isActive('/client-birthday-list') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''} ${isActive('/client-anniversary-list') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''} ${isActive('/client-form') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''}`}>
                            <div
                                className="shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-dark-gray bg-center stroke-0 text-center xl:p-2.5">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     stroke-width="1.5" stroke="white" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"/>
                                </svg>


                            </div>
                            <span
                                className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft text-white">Client Profile</span>
                        </Link>
                    </li>

                    <li className="w-full mt-4">
                        <h6 className="pl-6 ml-2 font-bold leading-tight uppercase text-xs opacity-60 text-red">Account
                            Pages</h6>
                    </li>

                    <li className="mt-0.5 w-full">
                        <Link to="/profile"
                              className={`py-2.7 shadow-soft-xl text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg bg-dark-gray px-4 font-semibold text-white transition-colors ${isActive('/profile') ? 'bg-gradient-to-tl from-red-500 to-gray-800 text-white' : ''}`}>
                            <div
                                className="shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-dark-gray bg-center stroke-0 text-center xl:p-2.5">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     stroke-width="1.5" stroke="white" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                                </svg>


                            </div>
                            <span
                                className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft text-white">Profile</span>
                        </Link>
                    </li>


                    <li className="mt-0.5 w-full">
                        <button onClick={handleLogout}
                                className="py-2.7 text-sm ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 transition-colors">
                            <div
                                className="shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-dark-gray bg-center stroke-0 text-center xl:p-2.5">
                                <svg fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="white" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25"/>
                                </svg>

                            </div>
                            <span
                                className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft text-white">Logout</span>
                        </button>
                    </li>
                </ul>
            </div>
        </aside>
    );
};

export default Sidebar;


